import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';

function DailyDeliverySheet() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  //below declaration of variable 'previousCenterId' to not show same centerid if it is 
  //same in next loop also only show once.
  let previousCenterIdTable = null;
  let previousCenterIdPrint = null;

  //below variables to show total at last of page in table
  let totalcowqtytable= 0;
  let totalcowamttable= 0;
  let totalbuffqtytable= 0;
  let totalbuffamttable= 0;
  let totalcash_recdtable= 0;
  let totalbank_recdtable= 0;

  //below variables to show total at last of page in print
  let totalcowqtyprint= 0;
  let totalcowamtprint= 0;
  let totalbuffqtyprint= 0;
  let totalbuffamtprint= 0;
  let totalcash_recdprint= 0;
  let totalbank_recdprint= 0;


  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      bookdate: '',
    });

    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          if(CenterMasterData.length <= 0) {
            setCenterMasterData(response.data.CenterMasterData);
            setFormData({
              ...formData,
              centerno: UserMasterDataGlobal?.[0]?.centerid,
              bookdate: getCurrentDate(),
            })
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const getCenterNameById = (centerid) => {
      const center = CenterMasterData.find(center => center.centerid === centerid);
      return center ? center.centername : '';
    };

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        bookdate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      temporaryvalues_table();
      
  }

  async function temporaryvalues_table(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(formData.bookdate !== '') {
      }else{
        validationerrors.bookdate = "Please select date from datepicker.";
      } 

      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {
      
        var data = {
          // centerno: defaultcenter || (UserMasterDataGlobal?.[0]?.centerid || ""),
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          bookdate: formData.bookdate,
        };
        // alert(data.routecode + " at step1");
       
        setFormData({
          ...formData,
          bookdate: data.bookdate,
        });

        try {
          var response  = "";
          if(toshowcustomermasterdata !== "notshow") {
            response = await axios.post(apireq05042024+'Reports/DailySummaryReport', data);

            setCustomerMasterData([]);  
            if (response.status === 200) {   
     
              if(response.data !== "No data found for the given date.") {                
                setCustomerMasterData(response.data);  
              }                                 
            } 

          }
        } catch {
        
        } 
      }  
  }

  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      
      const userIdapireq = UserMasterDataGlobal[0]?.userid;
      const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;
      
      const apirequest = apireq05042024+`DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;

      axios.get(apirequest)
        .then((response) => {
          try {
            
            setFormData({
              ...formData,
              bookdate: response.data[0].text5,
            });
            
          }
          catch {

          }          

        })

        .catch((error) => console.error('Error fetching route data:', error));
    }
  }, [UserMasterDataGlobal]);

  async function printContent (event) {
    event.preventDefault();

    temporaryvalues_table();
    
    const printWindow = window.open('', '_blank');

    const bookDate = formData.bookdate;

    // Split the date into year, month, and day
    const parts = bookDate.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    // Create a new date object with the parts
    const date = new Date(year, month - 1, day);

    // Get the day, month, and year
    const formattedDate = `${day}/${month}/${year}`;

    printWindow.document.write(`
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
    <div class="table-container col-md-12">
      <h1 style="color: red; font-size: 30px; font-weight: bold; margin-top: 5px; text-align: center;">Daily Summary Report</h1>
      <h2 style="color: red; font-size: 20px; font-weight: bold; margin-top: 20px; text-align: center;">Date: ${formattedDate}</h2>
      
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle">Center Name</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: center">Line no</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Cow Qty</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Cow Amt</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Buff Qty</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Buff Amt</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Cash Recd</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Bank Recd</th>
          </tr>
          </thead>
          <tbody>
          ${CustomerMasterData.map((c, index) => {
            const currentCenterId = c.centerid;
            const centerName = currentCenterId !== previousCenterIdPrint ? getCenterNameById(c.centerid) : '';
        
            // Update the previous center id
            previousCenterIdPrint = currentCenterId;
            
            totalcowqtyprint= Number(totalcowqtyprint) + Number(c.total_cowqty);
            totalcowamtprint= Number(totalcowamtprint) + Number(c.total_cowamt);
            totalbuffqtyprint= Number(totalbuffqtyprint) + Number(c.total_buffqty);
            totalbuffamtprint= Number(totalbuffamtprint) + Number(c.total_buffamt);
            totalcash_recdprint= Number(totalcash_recdprint) + Number(c.total_cash_recd);
            totalbank_recdprint= Number(totalbank_recdprint ) + Number(c.total_bank_recd);

            return `
            <tr key=${c.customer_mp_id}>
              <td>${centerName}</td>
              <td style="text-align: center;">${c.routename}</td>
              <td style="text-align: right;">${Number(c.total_cowqty).toFixed(2)}</td>
              <td style="text-align: right;">${Number(c.total_cowamt).toFixed(2)}</td> 
              <td style="text-align: right;">${Number(c.total_buffqty).toFixed(2)}</td>
              <td style="text-align: right;">${Number(c.total_buffamt).toFixed(2)}</td> 
              <td style="text-align: right;">${Number(c.total_cash_recd).toFixed(2)}</td>
              <td style="text-align: right;">${Number(c.total_bank_recd).toFixed(2)}</td> 
            </tr>             

            
            `;
          }).join('')}        
          
            <tr>
              <td style="text-align: center"></td>
              <td style="text-align: center; font-weight: bold">All center's Total</td>
              <td style="text-align: right; font-weight: bold">${Number(totalcowqtyprint).toFixed(2)}</td>
              <td style="text-align: right; font-weight: bold">${Number(totalcowamtprint).toFixed(2)}</td> 
              <td style="text-align: right; font-weight: bold">${Number(totalbuffqtyprint).toFixed(2)}</td>
              <td style="text-align: right; font-weight: bold">${Number(totalbuffamtprint).toFixed(2)}</td> 
              <td style="text-align: right; font-weight: bold">${Number(totalcash_recdprint).toFixed(2)}</td>
              <td style="text-align: right; font-weight: bold">${Number(totalbank_recdprint).toFixed(2)}</td> 
            </tr>

        </tbody>
      </table>
    </div>
  `);
  

    printWindow.document.close();
  };
  

    return(
            
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default', fontSize: 20 }} className="text-center">Daily Summary Report</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

             <div className="row mb-2">
            {/*  <div className="col-md-1">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="text" className="form-control" name="centerno" id="id-centerno" value={formData.centerno} onChange={handleInputChange}
                onBlur={centernoonblur} required 
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                }/>

                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
              </div> 

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="centerid" className="form-label">Select Center</label>

                <select
                  className="form-select"
                  name="centerid" // <-- should match `formData.uom1` property
                  // value={formData.centerid}
                  onChange={handleInputChange}
                  
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
              
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

         
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>
              </div>

                <input type="hidden" className="form-control" name="routecode" value={defaultroutename} onChange={handleInputChange} 
                onBlur={routecodeonblur} required />
                

              <div className="col-md-1">
                <label htmlFor="id-routename" className="form-label">Select Line</label>
                <select
                  className="form-select"
                  id="id-routename" 
                  name="routename"
                  // value={formData.routename}
                  onChange={handleInputChange}
                >

                {defaultroutename && (
                  <option value={defaultroutename}>
                    {defaultroutename}
                  </option>
                )}

                {RouteMasterData.map((r) => (
                  defaultroutename !== r.routename ?
                  <option key={r.routecode} value={`${r.routecode}-${r.routename}`} >
                    {r.routename}
                  </option>
                    : null
                ))}
                </select>
                {errors.routecode && <span style={{color: 'red', fontSize: '10px'}}>{errors.routecode}</span>}
              </div> */}

              <div className="col-md-2">
                <label htmlFor="id-bookdate" className="form-label">Till Date</label>
                <input type="date" className="form-control" name="bookdate" id="id-bookdate" value={formData.bookdate} onChange={handleInputChange} required/>
                {errors.bookdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.bookdate}</span>}
              </div>

              {/* <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="customer_status" className="form-label">Status</label>
                <select className='form-select' name='customer_status' onChange={handleInputChange}>
                    <option value='ALL'>ALL</option>
                    <option value='ACTIVE'>ACTIVE</option>
                    <option value='DEACTIVE'>DEACTIVE</option>
                </select>
              </div> */}

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
                {/* <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} >View</button> */}
              </div>

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={printContent}>Print</button>
              </div>
               
            </div> 
          </form>
      </div>


      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
      
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'center'}}>Center Name</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Line no</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Cow Qty</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '9%', textAlign: 'center'}}>Cow Amt</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '9%', textAlign: 'center'}}>Buff Qty</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '9%', textAlign: 'center'}}>Buff Amt</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '9%', textAlign: 'center'}}>Cash Recd</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '9%', textAlign: 'center'}}>Bank Recd</th>
                </tr>
              </thead>
              <tbody>
              
              {CustomerMasterData.map((c, index) => {
                const currentCenterId = c.centerid;
                const centerName = currentCenterId !== previousCenterIdTable ? getCenterNameById(c.centerid) : '';
                
              
                // Update the previous center id
                previousCenterIdTable = currentCenterId;

                totalcowqtytable= Number(totalcowqtytable) + Number(c.total_cowqty);
                totalcowamttable= Number(totalcowamttable) + Number(c.total_cowamt);
                totalbuffqtytable= Number(totalbuffqtytable) + Number(c.total_buffqty);
                totalbuffamttable= Number(totalbuffamttable) + Number(c.total_buffamt);
                totalcash_recdtable= Number(totalcash_recdtable) + Number(c.total_cash_recd);
                totalbank_recdtable= Number(totalbank_recdtable) + Number(c.total_bank_recd);
                
                return (                  
                  <tr key={c.customer_mp_id}>
                    <td style={{textAlign: 'center'}}>{centerName}</td>
                    <td style={{textAlign: 'center'}}>{c.routename}</td>
                    <td style={{textAlign: 'center'}}>{Number(c.total_cowqty).toFixed(2)}</td>
                    <td style={{textAlign: 'center'}}>{Number(c.total_cowamt).toFixed(2)}</td> 
                    <td style={{textAlign: 'center'}}>{Number(c.total_buffqty).toFixed(2)}</td>
                    <td style={{textAlign: 'center'}}>{Number(c.total_buffamt).toFixed(2)}</td> 
                    <td style={{textAlign: 'center'}}>{Number(c.total_cash_recd).toFixed(2)}</td>
                    <td style={{textAlign: 'center'}}>{Number(c.total_bank_recd).toFixed(2)}</td> 
                  </tr>


                );
                
              })}
                
                <tr>
                    <td style={{textAlign: 'center'}}></td>
                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>All center's Total</td>
                    <td style={{textAlign: 'center'}}>{Number(totalcowqtytable).toFixed(2)}</td>
                    <td style={{textAlign: 'center'}}>{Number(totalcowamttable).toFixed(2)}</td> 
                    <td style={{textAlign: 'center'}}>{Number(totalbuffqtytable).toFixed(2)}</td>
                    <td style={{textAlign: 'center'}}>{Number(totalbuffamttable).toFixed(2)}</td> 
                    <td style={{textAlign: 'center'}}>{Number(totalcash_recdtable).toFixed(2)}</td>
                    <td style={{textAlign: 'center'}}>{Number(totalbank_recdtable).toFixed(2)}</td> 
                </tr>
                 

              </tbody>
            </table>

            

          </div>








          {/* <div className="row mb-3">

             <div className='col-md-3 offset-md-2' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: 'rgb(41 113 203)', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total Customers = {TotalCustomerCount}</span>
            </div>
            <div className='col-md-3 offset-md-1' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: 'rgb(255, 12, 12)', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total outstanding = {Number(TotalOutStanding).toFixed(2)}</span>
            </div>
          </div> */}

        </div>
      </div>

    </div>

    );

}

export default DailyDeliverySheet;