import React, { useEffect, useState } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';
import FormRange from 'react-bootstrap/esm/FormRange';

function ProductMaster() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const healthcheck05042024 = process.env.REACT_APP_HEALTH_CHECK_PASSWORD;

  const admin1_health_checkup = process.env.REACT_APP_HEALTH_CHECK_username1;
  const admin2_health_checkup = process.env.REACT_APP_HEALTH_CHECK_username2;

  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      if(usernameglobal !== admin1_health_checkup && usernameglobal !== admin2_health_checkup) {
        navigate('/LoginPage');
      }
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  } 

  const MySwal = withReactContent(Swal);
  const [ProductMasterData, setProductMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(apireq05042024+'ProductMaster?orderby=DESC&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)

      .then((response) => {
        // Access the response data directly using response.data
        if(ProductMasterData.length <= 0){
          setProductMasterData(response.data.ProductMasterData);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);
  
  
  function navigatetologin() {
    localStorage.removeItem('usertimestamp');
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  }
 
  async function HealthCheck(e) {
    e.preventDefault();

    const data = {
        CompanyId : UserMasterDataGlobal?.[0]?.CompanyId
    }

    const response = await axios.post(apireq05042024+'HealthCheck/ABC1', data);
    if (response.status === 200) {
        if(response.data.message == "Success"){
          MySwal.fire({
            title: 'Health CheckUp Finished.',
            text: response.data.affectedRows + " Rows Affected",
            icon: 'success'
          });
        }
    }
  }

  async function HealthCheck2(e) {
    e.preventDefault();

    const data = {
        CompanyId : UserMasterDataGlobal?.[0]?.CompanyId
    }

    const response = await axios.post(apireq05042024+'HealthCheck/ABC2', data);
    if (response.status === 200) {
        if(response.data.message == "Success"){
          MySwal.fire({
            title: 'Health CheckUp Finished.',
            text: response.data.affectedRows + " Rows Affected",
            icon: 'success'
          });
        }
    }
  }

  return (

    <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default',fontSize: 20 }} className="text-center"> Health Check</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>

        <div className='col-md-1'>
            <button type="submit" className="btn btn-primary w-100" onClick={HealthCheck}>Health Check1</button>
        </div>
        <br />
        <div className='col-md-1'>
            <button type="submit" className="btn btn-primary w-100" onClick={HealthCheck2}>Health Check2</button>
        </div>

    </div>
  );
  
  
}

export default ProductMaster;
