import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function RouteMaster() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }
  
      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds
  
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);

    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;

        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
      const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
      localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
      navigate('/loginpage');
    } 
    
    //Below code is for making these page only accessible  to authorized users i.e., admin and manager.
    useEffect(() => {
      if(UserMasterDataGlobal.length>0){
      if (
          UserMasterDataGlobal?.[0].usertype !== "ADMIN" &&
          UserMasterDataGlobal?.[0].usertype !== "DAIRY OWNER"
      ) {
          navigate("/");
      }
      }
    }, [UserMasterDataGlobal]);

    const [formData, setFormData] = useState({
        routesrno: '',
        centerid: '',
        routename: '',
        operationtype: 'Save',
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        var validationerrors = {};

        if(formData.get('centerid').trim() !== '') {
        }else{
          validationerrors.centerid = "Please enter a valid centerid.";
        }  
        
        if(formData.get('routename').trim() !== '') {
        }else{
          validationerrors.routename = "Please enter a valid route name.";
        }  

        seterrors(validationerrors);
        if(Object.keys(validationerrors).length == 0) {
            // alert('no validation message');
            // return;
            var data = {
              CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
              routesrno: formData.get('routesrno'),
              centerid: formData.get('centerid'),
              routename: formData.get('routename'),
              operationtype: formData.get('operationtype'),
            }

            if(data.operationtype == 'Save'){
        
                const response = await axios.post(apireq05042024+'RouteMaster/insert', data);
        
                if (response.status === 200) {

                    if(!isNaN(+response.data)){
                        MySwal.fire({
                          html: <b>Record Saved Sucessfully!</b>,
                          icon: 'success'
                        })

                        const newDataItem = {
                          ...data,
                          routename: data.routename,
                          centerid: data.centerid,  
                          routesrno: response.data,  
                        };
                        setRouteMasterData((prevData) => {
                          return [newDataItem, ...prevData];
                        });
                        
                        emptyinputs(data.centerid);
                    } else if (response.data==="r_present"){
                      
                        MySwal.fire({ 
                        //   title: <strong>Record Saved Sucessfully!</strong>,
                          html: <b>  This route name already present. can't save this record...</b>,
                          icon: 'warning'
                        })
                      
                    }
                }

            }
            else {

                const response = await axios.post(apireq05042024+'RouteMaster/update', data);
        
                if (response.status === 200) {
                
                    if(response.data.message == "Sucesss"){
                        
                        MySwal.fire({
                            //   title: <strong>Record Saved Sucessfully!</strong>,
                            html: <b>Record updated Sucessfully!</b>,
                            icon: 'success'
                        })
                        data.operationtype="Save";
                        setRouteMasterData((prevData) => {
                          const updatedData = [...prevData];
                          
                          const index = updatedData.findIndex((record) => {
                            console.log("record.routesrno:", record.routesrno);
                            console.log("data.routesrno:", data.routesrno);
                            return record.routesrno == data.routesrno;
                          });
                        
                          if (index !== -1) {
                            updatedData[index] = { ...updatedData[index], ...data };
                          }           
                          
                          return updatedData;
                        });
                        // console.log(CustomerMasterData);
                        emptyinputs();
                    } else if (response.data==="ccwname_present"){
                      
                      MySwal.fire({ 
                      //   title: <strong>Record Saved Sucessfully!</strong>,
                        html: <b>  This Route name already present. can't save this record...</b>,
                        icon: 'warning'
                      })
                    }
                }
            }
        }
    }

    function navigatetologin() {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
        navigate('/loginpage');
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if(name == "centername") {
          setFormData({
            ...formData,
            centerid: value,
          })
        }
    };

    const [errors, seterrors] = useState({});
    const [RouteMasterData, setRouteMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`RouteMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
            // console.log(response.data);
          if(RouteMasterData.length <= 0){
            setRouteMasterData(response.data.RouteMasterData);
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    function confirmDelete(event, routesrno, routename, centerid) {
        event.preventDefault();
  
        MySwal.fire({
            title: 'Are you sure you want to delete the record?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                  // window.location.href = $(this).attr('href');
                  deleteData(routesrno, routename, centerid);
                }
            }
        })
    }
  
    async function deleteData(routesrno, routename, centerid) {

      const response = await axios.delete(apireq05042024+`RouteMaster/delete/${routesrno}/${UserMasterDataGlobal?.[0]?.CompanyId}/${centerid}/${routename}`);

      if (response.status === 200) {
        // The record was deleted successfully.
        // console.log('Record deleted successfully!');
          if(response.data==="Sucessfully deleted") {
              MySwal.fire({
                //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>Record deleted Sucessfully!</b>,
                icon: 'success'
              })
              setRouteMasterData((prevData) => prevData.filter((record) => record.routename !== routename));
          } else if(response.data == "found") {
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>This route is aligned to a customer; so can't delete this route</b>,
                icon: 'warning'
            })        
          } 
    } else {
          MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>Record not deleted! Please try once again or call the developer</b>,
              icon: 'error'
          })
      }
    }
    
    function getdatabyrecordarray(record) {
        
        setFormData({
            routesrno: record.routesrno,
            centerid: record.centerid,
            routename: record.routename,
            operationtype: 'Edit',
        });
        setdefaultcenter(record.centerid);
        // following code is to go to top of the page after clicking on edit button.
        window.scrollTo(0, 0)
    }

    function emptyinputs(centerid=''){
        setFormData({
            centerid: centerid,
            routename: '',
            operationtype: 'Save',
        });
    }

    const [defaultcenter, setdefaultcenter] = useState();

    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          // Access the response data directly using response.data
          if(!defaultcenter){
            setCenterMasterData(response.data.CenterMasterData);
            setdefaultcenter(response.data.CenterMasterData?.[0]?.centerid);
            setFormData({
              ...formData,
              centerid: response.data.CenterMasterData?.[0]?.centerid,
            })
            // console.log(response.data.CenterMasterData?.[0]?.centerid);
    
            if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
              setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
              setFormData({
                ...formData,
                centerid: UserMasterDataGlobal?.[0]?.centerid,
              })
            } 
          }

        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const getCenterNameById = (centerid) => {
        const center = CenterMasterData.find(center => center.centerid === centerid);
        return center ? center.centername : '';
    };
    
    const centeridonblur = (e) => {
      e.preventDefault();
      var { name, value } = e.target;

      // setFormData({
      //   ...formData,
      //   centername: value,
      // })
      setdefaultcenter(value);
    }

    return (
        
    <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', fontSize: 20, cursor: 'default' }} className="text-center"> Line Master</h1>
        <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
        </nav>

    <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        <div className="col-md-6 offset-md-3">
            <form onSubmit={handleSubmit} method="POST" id="form1">
                <div className="row mb-3">

                    <input type="hidden" className="form-control" name="routesrno" value={formData.routesrno}  onChange={handleInputChange}
                    placeholder='Type here' required />
  
                    <div className="offset-md-1 col-md-3">
                        <label htmlFor="centerid" className="form-label">Center No</label>
                        <input type="text" className="form-control" name="centerid" value={formData.centerid}  onChange={handleInputChange}
                        onBlur={centeridonblur} placeholder='Type here' required />
                        {errors.centerid && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerid}</span>}
                    </div>

                    <div className="col-md-4">
                        
                        <label htmlFor="centername" className="form-label">Center Name</label>
                        <select
                        className="form-select"
                        name="centername"
                        // value={formData.centername}
                        onChange={handleInputChange}
                        disabled={
                            UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                            UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                        }
                        >

                        {defaultcenter && (
                        <option key={defaultcenter} value={defaultcenter}>
                            {getCenterNameById(defaultcenter)}
                        </option>
                        )}

                        {/* dynamically fetched options */}
                        {CenterMasterData.map((cp) => (
                            defaultcenter !== cp.centerid ?
                                <option key={cp.centerid} value={cp.centerid} >
                                {cp.centername}
                                </option>
                            : null
                        ))}
                        </select>         

                    </div>

                    <div className="col-md-4">
                        <label htmlFor="routename" className="form-label">Line No</label>
                        <input type="text" className="form-control" name="routename" value={formData.routename}  onChange={handleInputChange}
                        placeholder='Type here' required />
                        {errors.routename && <span style={{color: 'red', fontSize: '10px'}}>{errors.routename}</span>}
                    </div>

                    <input type="hidden" className="form-control" name="operationtype" value={formData.operationtype}  onChange={handleInputChange}
                    required />
                </div>
                
                <div className="row mb-3">
                    <div className="offset-md-10 col-md-4">
                        <button type="submit" className="btn btn-primary" style={{width: 60}}>{formData.operationtype == 'Save' ? 'Save' : 'Edit'}</button>
                    </div>
                </div>

            </form>
        </div>
    </div>


    <div className="row mt-4">
    <div className="col-md-12 table-responsive-xl">
    <table className="table table-striped">
        <thead>
        <tr>
            <th style={{backgroundColor:'#009879', color:"white"}}>Center No</th>
            <th style={{backgroundColor:'#009879', color:"white"}}>Center Name</th>
            <th style={{backgroundColor:'#009879', color:"white", textAlign: 'center'}}>Line No</th>
            <th style={{backgroundColor:'#009879', color:"white", textAlign: 'center'}}>Action</th>
        </tr>
        </thead>
        <tbody>
          {RouteMasterData.map((c, index) => (
          <tr key={c.routesrno}>
              <td>{c.centerid}</td>
              <td>{getCenterNameById(c.centerid)}</td>      
              <td style={{textAlign: 'center'}}>{c.routename}</td>
            <td style={{textAlign: 'center'}}>
            <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-sm">Edit</button>
            {/* <Link to={generatePath(routes.customerDetails, { customerid1: c.customer_mp_id })}  className="btn btn-success btn-sm">edit</Link> */}

            &nbsp;<button onClick={(event) => confirmDelete(event, c.routesrno, c.routename, c.centerid)} className="btn btn-danger btn-sm ml-1">
            Delete
            </button>
            </td>
        </tr>
        ))}
        </tbody>
    </table>
    </div>
    
    </div>
    </div>

    );

}

export default RouteMaster;
