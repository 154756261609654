import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, BrowserRouter as Router, useLocation  } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './styles/Receipt.css';
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Table } from 'react-bootstrap'; // Import Bootstrap components

function Receipt() {
  
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  } 

  //Below code is for making these page only accessible  to authorized users i.e., admin and manager.
  useEffect(() => {
    if(UserMasterDataGlobal.length>0){
        if (
            UserMasterDataGlobal?.[0].usertype !== "ADMIN" &&
            UserMasterDataGlobal?.[0].usertype !== "DAIRY OWNER"
        ) {
            navigate("/");
        }
    }
  }, [UserMasterDataGlobal]);

  const MySwal = withReactContent(Swal)
  const [defaultcenter, setdefaultcenter] = useState();
  const [customer_name_data,  setcustomer_name_data] =  useState([]);
  const [errors,  seterrors] =  useState({});
  const customer_centerwiseidRef = useRef(null);

  const [CenterMasterData, setCenterMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)

      .then((response) => {
        // Access the response data directly using response.data
        if(!defaultcenter){
          setCenterMasterData(response.data.CenterMasterData);
          setdefaultcenter(response.data.CenterMasterData[0].centerid);      
          setFormData({
            ...formData,
            centerid: response.data.CenterMasterData[0].centerid,
            centerno: response.data.CenterMasterData[0].centerid,
          })
          getmaxreceiptno(option1);
          getroutemasterdatabycenterid(response.data.CenterMasterData[0].centerid, "centername");
          
          if(option1 == "BANK") {
            setshowbankoptions(true);
          }
        }
        
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);


  const [BankMasterData, setBankMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(apireq05042024+`BankMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)

      .then((response) => {
        // Access the response data directly using response.data

        if (BankMasterData.length <= 0) {
          setBankMasterData(response.data.BankMasterData);
          // getmaxreceiptno(option1);

          // if(option1 == "BANK") {
          //   setshowbankoptions(true);
          // }
        }
        
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  const getCenterNameById = (centerid) => {
    const center = CenterMasterData.find(center => center.centerid === centerid);
    return center ? center.centername : '';
  };

  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const option1 = searchParams.get("option1");
  // const option2 = searchParams.get("option2");

  // if (!option1 || !option2) {
  //   // If option1 or option2 is not present, you can redirect the user to another page
  //   // For example, redirect to Navbar1
  //   navigate('/');
  // }
  // else{
  // }

  const [formData, setFormData] = useState({
    centerid: '',
    centerno: '',
    routename: '',
    customer_mp_id: '',
    customer_centerwiseid: '',
    customer_name: '',
    customer_place: '',
    customer_mobile: '',
    prevbalrs: '',
    prevmonthbalrs: '',
    tran_date: '',
    cashrecieved: '',
    bankrecieved: '',
    amount: '',
    cust_bank_name: '',
    cust_branch_name: '',
    cust_check_no: '',
    cust_check_date: '',
    operationtype: 'Save',
    receiptno: '',
    clbal: '',
    option2: 'CASH',
  });
  
  const [option1, setoption1] = useState();
  useEffect(() => {
    if(formData.option2 == "JANATA BANK" || formData.option2 == "OTHER BANK" ) {    
      
      setoption1("BANK");
      setshowbankoptions(true);
      getmaxreceiptno("BANK");
      
    }
    else {
      if(option1 == "BANK") {
        getmaxreceiptno("CASH");
      }
      else if(option1 == "") {
        getmaxreceiptno("CASH");
      }

      setoption1("CASH");
      setshowbankoptions(false);      
    }      
  }, [formData.option2]);

  useEffect(() => {
    // Update the date in the formData when the component mounts
    setFormData((prevData) => ({
      ...prevData,
      tran_date : getCurrentDate(),
    }));
  }, []); 

  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if(name == "centerid") {
      const [centerid, centername] = value.split('-');
      updatedFormData.centerno = centerid;
      setdefaultcenter(centerid);
      // getroutemasterdata(centerid, 'd6');
      getroutemasterdatabycenterid(centerid, "centername");

      updatedFormData.customer_mp_id =  '';
      updatedFormData.customer_centerwiseid =  '';
      updatedFormData.customer_name =  '';
      updatedFormData.customer_place =  '';
      updatedFormData.customer_mobile =  '';
      updatedFormData.prevbalrs =  '';
      updatedFormData.prevmonthbalrs =  '';
      updatedFormData.cashrecieved =  '';
      updatedFormData.bankrecieved =  '';
      updatedFormData.amount =  '';
      updatedFormData.clbal =  '';
      updatedFormData.cust_bank_name =  '';
      updatedFormData.cust_branch_name =  '';
      updatedFormData.cust_check_no =  '';
      updatedFormData.cust_check_date =  '';

    }

    if(name == "routename") {
      const [routecode, routename] = value.split('-');
      setdefaultroutename(routecode);
      fetchcustomer_name_data(formData.centerid, routecode);

      updatedFormData.customer_mp_id =  '';
      updatedFormData.customer_centerwiseid =  '';
      updatedFormData.customer_name =  '';
      updatedFormData.customer_place =  '';
      updatedFormData.customer_mobile =  '';
      updatedFormData.prevbalrs =  '';
      updatedFormData.prevmonthbalrs =  '';
      updatedFormData.cashrecieved =  '';
      updatedFormData.bankrecieved =  '';
      updatedFormData.amount =  '';
      updatedFormData.clbal =  '';
      updatedFormData.cust_bank_name =  '';
      updatedFormData.cust_branch_name =  '';
      updatedFormData.cust_check_no =  '';
      updatedFormData.cust_check_date =  '';
    }

    if(name == "amount") {
      var clbal = Number(formData.prevbalrs) - Number(value);
      clbal = Number(clbal).toFixed(2);
      updatedFormData.clbal = clbal;
    }

    if(name == "customer_mp_id") {
      getclbaltoeditrecord(value, formData.tran_date);
    }

    setFormData(updatedFormData);
    
  };

  const [defaultroutename, setdefaultroutename] = useState();
  const [RouteMasterData, setRouteMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0 && defaultcenter) {
      const axiostoapi = apireq05042024+`RouteMaster/GetRouteByCenterIdAndCompanyId/${UserMasterDataGlobal[0]?.CompanyId}/${defaultcenter}`;
      axios.get(axiostoapi)
        .then((response) => {
          setRouteMasterData(response.data.RouteMasterData);
        })
        .catch((error) => console.error('Error fetching route data:', error));
    }
  //we put defaultcenter below because when its value changes everytime this routemaster useeffect code will get executed
  }, [UserMasterDataGlobal, defaultcenter]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    var validationerrors = {};
    var cash_recd;
    var bank_recd;

    if(option1 ==  "CASH"){
      cash_recd = formData.get('amount');
      bank_recd = 0;
    }
    else{
      cash_recd = 0;
      bank_recd = formData.get('amount');
    }

    if(option1 == "BANK") {
      if(formData.get('cust_bank_name') == "") {
        validationerrors.cust_bank_name = "Please enter bank name";
      }
      if(formData.get('cust_branch_name') == "") {
        validationerrors.cust_branch_name = "Please enter branch name";
      }
      if(formData.get('cust_check_no') == "") {
        validationerrors.cust_check_no = "Please enter check no";
      }
      if(formData.get('cust_check_date') == "") {
        validationerrors.cust_check_date = "Please enter check date";
      }
    }

    if(formData.get('amount').trim() !== '') {
      if(!isNaN(formData.get('amount')) && parseFloat(formData.get('amount')) >= 0) {  
      }
      else{
        validationerrors.amount = "Please enter a valid positive numeric value for Cow Ltrs.";
      }
    }
    else{
      validationerrors.amount = "Please enter a value in amount input box.";
    }

    seterrors(validationerrors);

    var transaction_id;
    const data = {
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
      centerid: formData.get('centerid'),
      // centerid: formData.get('centerno'),
      userid: UserMasterDataGlobal?.[0]?.userid,
      trantype: 'RECEIPT',
      tran_date : formData.get('tran_date'),
      // payment_by : option1,
      payment_by : option1 == "CASH" && "ONLINE CASH" ? "CASH" : "BANK",
      sub_voucher_type : formData.get('option2'),
      routename : defaultroutename,  
      customer_name : formData.get('customer_name'),
      customer_centerwiseid : formData.get('customer_centerwiseid'),
      customer_mp_id : formData.get('customer_mp_id'),
      customer_mobile : formData.get('customer_mobile'),
      customer_place : formData.get('customer_place'),
      cust_bank_name : formData.get('cust_bank_name'),
      cust_branch_name : formData.get('cust_branch_name'),
      cust_check_no : formData.get('cust_check_no'),
      cust_check_date : formData.get('cust_check_date'),
      cramt : formData.get('amount'),
      cash_recd : cash_recd,
      bank_recd : bank_recd,
      balance : formData.get('clbal'),
      operationtype : formData.get('operationtype'),
      transaction_id : formData.get('transaction_id'),
      receiptno : formData.get('receiptno'),
    };
    // console.log(data);
    if(Object.keys(validationerrors).length == 0) {
      if(data.operationtype == "Save") {
        const response = await axios.post(apireq05042024+'ReceiptMaster/insert', data);
        if (response.status === 200) {
          if(!isNaN(+response.data)){
            transaction_id = response.data;
            MySwal.fire({
              html: <b>Record Saved Sucessfully!</b>,
              icon: 'success'
            })
            
            const newDataItem = {
              ...data,
              transaction_id: response.data,
              // receiptno: response.data.receiptno,
            };

            setReceiptMasterData((prevData) => {
              return [newDataItem, ...prevData];
            });
            emptyinputs();

            if(isCheckedPrintAfterSave) {
              printContent(newDataItem);
            }

          }
          // else if(response.data == "Something went wrong with customer_mp_id") {
          //   MySwal.fire({
          //     html: <b>Something went wrong with customer_mp_id</b>,
          //     icon: 'error'
          //   })
          // }
          // else if(response.data == "Voucher No already exists. Please type any different Voucher no") {
          //   MySwal.fire({
          //     html: <b>Voucher No already exists. Please type any different Voucher no</b>,
          //     icon: 'error'
          //   })
          // }
          // else if(response.data == "Route name not found for the given customer_centerwiseid and centerid") {
          //   MySwal.fire({
          //     html: <b>Route name not found for the given customer_centerwiseid and centerid</b>,
          //     icon: 'error'
          //   })
          // }
          else {
              MySwal.fire({
                html: <b>{response.data}</b>,
                icon: 'error'
              })
          }
          
        }
    
      } else {

        const response = await axios.post(apireq05042024+'ReceiptMaster/update', data);
        // console.log(response.data);
        if (response.status === 200) {
            if(response.data=="Sucesss"){
                MySwal.fire({
                  //   title: <strong>Record Saved Sucessfully!</strong>,
                  html: <b>Record updated Sucessfully!</b>,
                  icon: 'success'
                })

                setReceiptMasterData((prevData) => {
                  const updatedData = [...prevData];
                  const index = updatedData.findIndex((record) => record.transaction_id == data.transaction_id);

                  if (index !== -1) {
                    updatedData[index] = { ...updatedData[index], ...data };
                  }
                  return updatedData;
                });

                emptyinputs();
                seterrors([]);

                if(isCheckedPrintAfterSave) {
                  printContent(data);
                }
            }
            // else if (response.data=="failed"){
            //   MySwal.fire({
            //   //   title: <strong>Record Saved Sucessfully!</strong>,
            //     html: <b>Update failed or no matching records found!</b>,
            //     icon: 'error'
            //   })                
            // }    
            
            else {
              MySwal.fire({
              //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>{response.data}</b>,
                icon: 'error'
              })    
            }

        } else {
        }
      }
    }
    else{
      console.log("Validation errors...");
    }
  } 

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  function navigatetologin() {
    localStorage.removeItem('usertimestamp');
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  } 

  const centernoonblur = async (e) => {
    e.preventDefault();
    var { name, value } = e.target;

    if(value == ''){
      return;
    }
    setdefaultcenter(value);
    formData.centerid = value;
    // getroutemasterdata(value, 'd1');
    getroutemasterdatabycenterid(value);
    fetchcustomer_name_data(value,defaultroutename); 
  };

  const receiptnoonblur = async (e) => {
    e.preventDefault();
    var { name, value } = e.target;

    if(value == ''){
      return;
    }

    const data = {
      payment_by: option1,
      receiptno: value,
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
    }

    const response = await axios.post(apireq05042024+'ReceiptMaster/CheckReceiptNoExists', data);

    if (response.status == 200) {
      
      if(response.data.length > 0){
        
        setdefaultroutename(response.data[0].routename);
        // console.log(record.routename);
        setFormData({
          ...formData,
          transaction_id : response.data[0].transaction_id ,
          tran_date: response.data[0].tran_date,
          centerid: response.data[0].centerid,
          centerno: response.data[0].centerid,
          routename: response.data[0].routename,
          customer_centerwiseid: response.data[0].customer_centerwiseid,
          customer_name: response.data[0].customer_name,
          amount: response.data[0].cramt,
          cust_bank_name: response.data[0].cust_bank_name,
          cust_branch_name: response.data[0].cust_branch_name,
          cust_check_no: response.data[0].cust_check_no,
          cust_check_date: response.data[0].cust_check_date,
          operationtype: 'Edit',
          customer_mp_id: response.data[0].customer_mp_id,  
          option2: response.data[0].sub_voucher_type,  
        });

        if(response.data[0].payment_by == "BANK") {
          setshowbankoptions(true);
        }

        fetchcustomer_name_data(response.data[0].centerid, response.data[0].routename);
        // getrecordsbycustomer_centerwiseid_edit(response.data[0].customer_centerwiseid, 'customer_name_only', response.data[0].centerid, response.data[0].routename);
        getclbaltoeditrecord(response.data[0].customer_mp_id, response.data[0].tran_date);

        
      }
      else{
        emptyinputs('KeepReceiptNoAsitis');      
      }
    }

  };

  //to get the data from centerid/centerno we use this function
  async function getroutemasterdatabycenterid(centerid, rr=null) {
    var data1 = {
        centerid : centerid,
        CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
        setdefaultroutename(response1.data[0].routename);
        setRouteMasterData(response1.data);
        if(rr=="centername") {
            //populating customer_name list using customer_name_data array by calling below function.
            //i put below function here because when page first time renders and we try to change
            //centername from selectbox. it is trying to take routename of previous center.
            fetchcustomer_name_data(centerid,response1.data[0].routename);
        }
    })
  }

  //below function is for putting data in customer_name_data array
  async function  fetchcustomer_name_data(centerid, routecode) {
  
    setcustomer_name_data([]);

    var axiosreqtoapi = apireq05042024+'CustomerMaster/getCustomerDataWithCenterNoAndRouteCode?routecode='+routecode
    +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid;
    
    const response = axios.get(axiosreqtoapi)

    .then((response) => {
        if(response.data.customer_name_data.length > 0) {
            setcustomer_name_data(response.data.customer_name_data);
        }
    })
    .catch((error) => {
        console.error('Error fetching data:', error);
    });
  }

  //This function is for customer_centerwiseid onblur and customer_name selectbox value change
  async function getrecordsbycustomer_centerwiseid_edit(customer_centerwiseid=null, setcustomer_centerwiseid_edit=null, recordcenterid = null, recordroutename = null) {
    
    if(customer_centerwiseid == ''){
      return;
    }
    
    var axiosreqtoapi;
    if(recordcenterid == null) {
      axiosreqtoapi = apireq05042024+'CustomerWiseSalesEntryCard/getrecordbybycustomer_centerwiseid?customer_centerwiseid='+customer_centerwiseid+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId
      +'&centerid='+formData.centerno+'&routename='+defaultroutename;
    } else {
      axiosreqtoapi = apireq05042024+'CustomerWiseSalesEntryCard/getrecordbybycustomer_centerwiseid?customer_centerwiseid='+customer_centerwiseid+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId
      +'&centerid='+recordcenterid+'&routename='+recordroutename;
    }

    const response = await axios.get(axiosreqtoapi)
    
    .then((response) => {
    
      if(response.data.CustomerMasterData.length > 0){
        //below code is to see if the array we got in response is present already in customer_name_data array.

        const index = customer_name_data.findIndex(item =>
            item.customer_mp_id === response.data.CustomerMasterData[0].customer_mp_id
        );
        
        // If response array already exists in customer_name_data, move it to the first index
        if (index !== -1) {                
            const removedArray = customer_name_data.splice(index, 1)[0];        
            // Then insert it at the beginning
            const newData = [removedArray, ...customer_name_data];
            setcustomer_name_data(newData);

            if(setcustomer_centerwiseid_edit=="setcustomer_centerwiseid_edit") {
                 //putting the existing array customer_name to the value of select box
                 setFormData((prevData) => ({
                    ...prevData,
                    customer_centerwiseid: response.data.CustomerMasterData[0].customer_centerwiseid,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                    customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                    customer_place: response.data.CustomerMasterData[0].customer_place,
                    // prevbalrs: response.data.CustomerMasterData[0].clbal,
                }));
                getclbaltoeditrecord(response.data.CustomerMasterData[0].customer_mp_id, formData.tran_date);
            }
            else if(setcustomer_centerwiseid_edit==null) {
                setFormData((prevData) => ({
                  ...prevData,
                    customer_name: response.data.CustomerMasterData[0].customer_name,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                    customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                    customer_place: response.data.CustomerMasterData[0].customer_place,
                    // prevbalrs: response.data.CustomerMasterData[0].clbal,
                }));
                getclbaltoeditrecord(response.data.CustomerMasterData[0].customer_mp_id, formData.tran_date);
            }               
            else if(setcustomer_centerwiseid_edit=="customer_name_only") {
              setFormData((prevData) => ({
                ...prevData,
                customer_name : response.data.CustomerMasterData[0].customer_name,
              }));
            }

        } else {
            // If it doesn't exist, push it normally
            setcustomer_name_data(response.data.CustomerMasterData);

            if(setcustomer_centerwiseid_edit=="setcustomer_centerwiseid_edit") {
              //putting the existing array customer_name to the value of select box
              setFormData((prevData) => ({
                 ...prevData,
                 customer_centerwiseid: response.data.CustomerMasterData[0].customer_centerwiseid,
                 customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                 customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                 customer_place: response.data.CustomerMasterData[0].customer_place,
                //  prevbalrs: response.data.CustomerMasterData[0].clbal,
              }));
              getclbaltoeditrecord(response.data.CustomerMasterData[0].customer_mp_id, formData.tran_date);
            }
            else if(setcustomer_centerwiseid_edit==null) {
                setFormData((prevData) => ({
                  ...prevData,
                    customer_name: response.data.CustomerMasterData[0].customer_name,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                    customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                    customer_place: response.data.CustomerMasterData[0].customer_place,
                    // prevbalrs: response.data.CustomerMasterData[0].clbal,
                }));
                getclbaltoeditrecord(response.data.CustomerMasterData[0].customer_mp_id, formData.tran_date);
            }                
            else if(setcustomer_centerwiseid_edit=="customer_name_only") {
              setFormData((prevData) => ({
                ...prevData,
                customer_name : response.data.CustomerMasterData[0].customer_name,
              }));
            }
        }

      } else {
        //If no data from API then clear customer_centerwiseid field and apologies message
        if(formData.customer_centerwiseid !== '') {
            alert("No transactions found for this Customer CenterWise ID...");
            setFormData({
                ...formData,
                customer_centerwiseid: '',
                customer_name: '',
                customer_mp_id: '',
                customer_mobile: '',
                customer_place: '',
                prevbalrs: '',
                prevmonthbalrs: '',
            });                
        }        
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });    
  }
  
  const [ReceiptMasterData, setReceiptMasterData] = useState([]);
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0 && formData.tran_date !== '') {
      const apitoreq = apireq05042024+`ReceiptMaster/getdata/${formData.tran_date}/${UserMasterDataGlobal?.[0]?.CompanyId}/${option1}/${formData.option2}`;
      
      axios.get(apitoreq)
          .then((response) => {
            setReceiptMasterData(response.data);
            if(formData.option2 == "JANATA BANK") {
              setFormData((prevData) => ({
                ...prevData,
                cust_bank_name : "JANATA BANK",
              }));
            }
            else if(formData.option2 == "OTHER BANK") {
              setFormData((prevData) => ({
                ...prevData,
                cust_bank_name : "",
              }));
            }
          })
          .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal, formData.tran_date, option1, formData.option2]);

  function confirmDelete(event, id) {
    event.preventDefault();

    MySwal.fire({
        title: 'Are you sure you want to delete the record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            if (result.value) {
            // window.location.href = $(this).attr('href');
            deleteData(id);
            }
        }
    })
}

async function deleteData(id) {

  const response = await axios.delete(apireq05042024+`ReceiptMaster/delete/${id}/${UserMasterDataGlobal?.[0]?.CompanyId}/${UserMasterDataGlobal?.[0]?.userid}`);
  
  if (response.status == 200) {
    // The record was deleted successfully.
    // console.log('Record deleted successfully!');
      if(response.data=="Successfully deleted") {
          MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
          html: <b>Record deleted Sucessfully!</b>,
          icon: 'success'
          });
          setReceiptMasterData((prevData) => prevData.filter((record) => record.transaction_id !== id));  
          
          //after deletion of record we forcefully change paymeny_by option to CASH
          getmaxreceiptno(option1);

        } else if(response.data == "found") {
        MySwal.fire({
        //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>This customer has transactions; so can't delete this account</b>,
            icon: 'warning'
        })        
      }
      else{
        MySwal.fire({
        //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>{response.data}</b>,
            icon: 'error'
        })  
      }
} else {
      MySwal.fire({
      //   title: <strong>Record Saved Sucessfully!</strong>,
          html: <b>Record not deleted! Please try once again or call the developer</b>,
          icon: 'error'
      })
  }
}

function getdatabyrecordarray(record) {
  setdefaultroutename(record.routename);
  
  var validationerrors = {};

  seterrors(validationerrors);

  setFormData({
    ...formData,
    transaction_id : record.transaction_id ,
    tran_date: record.tran_date,
    receiptno: record.receiptno,
    centerid: record.centerid,
    centerno: record.centerid,
    routename: record.routename,
    customer_centerwiseid: record.customer_centerwiseid,
    customer_name: record.customer_name,
    customer_place: record.customer_place,
    customer_mobile: record.customer_mobile,
    amount: record.cramt,
    cust_bank_name: record.cust_bank_name,
    cust_branch_name: record.cust_branch_name,
    cust_check_no: record.cust_check_no,
    cust_check_date: record.cust_check_date,
    operationtype: 'Edit',
    customer_mp_id: record.customer_mp_id,  
    // option2: record.sub_voucher_type,
  });
  // getrecordsbycustomer_centerwiseid_edit(record.customer_centerwiseid, 'customer_name_only', record.centerid, record.routename);
  fetchcustomer_name_data(record.centerid, record.routename);
  getclbaltoeditrecord(record.customer_mp_id, record.tran_date);
  // following code is to go to top of the page after clicking on edit button.
  window.scrollTo(0, 0)
}

async function getclbaltoeditrecord(customer_mp_id, tran_date){
  try {
    const axiosreqtoapi = apireq05042024+`ReceiptMaster/GetclbalOfSpecificDate/reqfromFrontend/${customer_mp_id}/${tran_date}/${UserMasterDataGlobal?.[0]?.CompanyId}`;

    const response = await axios.get(axiosreqtoapi);

    var prevclbal = response.data.clbalvalue;
    var prevmonthbalrs = response.data.last_month_bal;

    setFormData((prevData) => ({
      ...prevData,
      prevbalrs:prevclbal,
      prevmonthbalrs:prevmonthbalrs,
    }));
  } catch (error) {
    console.error('Error fetching data:', error);
    return null; // Return null in case of error
  }
}

function emptyinputs(KeepReceiptNoAsitis = null) {

    setFormData((prevData) => ({
      ...prevData,
      customer_mp_id: '',
      customer_centerwiseid: '',
      customer_name: '',
      customer_place: '',
      customer_mobile: '',
      prevbalrs: '',
      prevmonthbalrs: '',
      cashrecieved: '',
      bankrecieved: '',
      amount: '',
      clbal: '',
      cust_bank_name: '',
      cust_branch_name: '',
      cust_check_no: '',
      cust_check_date: '',
      operationtype: 'Save',
    }));
    
    if(KeepReceiptNoAsitis == null) {
      getmaxreceiptno(option1);
    }
  }

    async function getmaxreceiptno(option1) {

      const axiosreqtoapi = apireq05042024+`ReceiptMaster/countmaxreceiptno/${UserMasterDataGlobal?.[0]?.CompanyId}/${option1}`;
      
      axios.get(axiosreqtoapi)

        .then((response) => {
          setFormData((prevData) => ({
            ...prevData,
            receiptno:response.data,
          }));
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  
  const printContent1 = (data) => {

    const printWindow = window.open('', '_blank');

    const parts = data.tran_date.split('-');
    // Rearrange the parts to form 'DD-MM-YYYY' format
    const tran_date = `${parts[2]}-${parts[1]}-${parts[0]}`;
    var cust_check_date;
    if(data.cust_check_date !== null) {
      const parts = data.cust_check_date.split('-');
      // Rearrange the parts to form 'DD-MM-YYYY' format
      cust_check_date = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    else{
      cust_check_date = null;
    }

    printWindow.document.write(`
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
      <h1 style="color: red; font-size: 40px; font-weight: bold; margin-top: 5px; text-align: center;">${option1} Receipt (${formData.option2})</h1>
      <div class="table-container col-md-12 mt-3">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="color: red;">Payment by</th>
              <td>${option1}</td>
              <th style="color: red;">Payment type</th>
              <td>${formData.option2}</td>
            </tr>
            <tr>
              <th style="color: red;">Receipt Date</th>
              <td>${tran_date}</td>
              <th style="color: red;">Center Name</th>
              <td>${getCenterNameById(data.centerid)}</td>
            </tr>
            <tr>
              <th style="color: red;">Line No</th>
              <td>${data.routename}</td>
              <th style="color: red;">Customer Name</th>
              <td>${data.customer_name}</td>
            </tr>
            <tr>
              <th style="color: red;">Receipt No</th>
              <td>${data.receiptno}</td>
              <th style="color: red;">Amount</th>
              <td>${data.cramt}</td>
            </tr>
            <tr>
              <th style="color: red;">Bank Name</th>
              <td>${data.cust_bank_name == null ? '' : data.cust_bank_name}</td>
              <th style="color: red;">Branch</th>
              <td>${data.cust_branch_name == null ? '' : data.cust_branch_name}</td>
            </tr>
            <tr>
              <th style="color: red;">Cheque No</th>
              <td>${data.cust_check_no == null ? '' : data.cust_check_no}</td>
              <th style="color: red;">Cheque date</th>
              <td>${cust_check_date == null ? '' : cust_check_date}</td>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
    `);
    printWindow.document.close();
    // printWindow.print();
  };


  const printContent = (data) => {

    const printWindow = window.open('', '_blank');

    // Rearrange the parts to form 'DD-MM-YYYY' format
    // const tran_date = `${parts[2]}-${parts[1]}-${parts[0]}`;
    // var cust_check_date;
    // if(data.cust_check_date !== null) {
    //   const parts = data.cust_check_date.split('-');
    //   // Rearrange the parts to form 'DD-MM-YYYY' format
    //   cust_check_date = `${parts[2]}-${parts[1]}-${parts[0]}`;
    // }
    // else{
    //   cust_check_date = null;
    // }
    const tran_date = getformatteddate(data.tran_date);
    var cust_check_date;
    if(data.cust_check_date !== null) {
      cust_check_date = getformatteddate(data.cust_check_date);
    }
    else {
      cust_check_date = null;
    }

    printWindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Receipt</title>
    </head>

    <style>
    @page {
      size: A4;
      margin: 10px; /* Adjusted the top margin */
    }

    body {
      margin: 0;
      padding: 10px 20px 0 20px; /* Added padding to prevent clipping */
      font-family: Arial, sans-serif;
    }
  
  .bill {
      width: 8.5in;
      /* height: 4in; 
      height: 3.77in;*/
      border: 1px solid #000;
      margin: 10px auto;
      padding: 5px;
      box-sizing: border-box;
      page-break-inside: avoid;
  }
  
  header {
      text-align: left;
      margin-bottom: 5px;
  }
  
  .info {
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
  }
  
  .content {
      display: flex;
      justify-content: space-between;
  }
  
  .section {
      width: 32%;
      border: 1px solid #000;
      padding: 5px;
      box-sizing: border-box;
  }
  
  .calendar table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      font-size: 10px;
  }
  
  .calendar th, .calendar td {
      border: 1px solid #000;
      text-align: center;
      padding: 2px;
      vertical-align: top;
      height: 25px;
  }
  
  .summary, .notes {
      font-size: 12px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
  }
  
  @media print {
      body {
          margin: 0;
      }
  
      .bill {
          page-break-inside: avoid;
          break-inside: avoid;
      }
  }
    </style>

    <body>
        <div class="bill">
            <header>
                <div class="info">
                    <span>
                        <a style="margin-bottom: 0%; font-size: 25px;font-weight: bold;">फडके ग्रुप</a><br>
                        <a>1785, SADASHIV PETH, PUNE - 411 030</a><br>
                        <a>OFFICE TEL.: 020-24470562, 24482145</a><br>
                        <a style="font-weight: bold;">OFFICE TIMINGS:</a><br>
                        <a>MONDAY-SATURDAY : 9.00 AM TO 12 NOON AND 4.30 PM TO 8.00 PM</a><br>
                        <a>SUNDAY TIMING : 9.00 AM TO 12 NOON AND 4.30 PM TO 7.00 PM</a>
                    </span>
                    <span>
                        <a style="font-size: 18px; font-weight: bold;">${data.sub_voucher_type} RECEIPT</a><br>
                        <a style="font-size: 14px;">Receipt No.: ${data.receiptno}</a><br>
                        <a style="font-size: 14px;">Date:${tran_date}</a><br>
                        <br>
                        <a style="font-size: 14px;">Center: ${getCenterNameById(data.centerid)}</a><br>
                        <a style="font-size: 14px;">Line No.: ${data.routename}</a><br>
                    </span>
                </div>
            </header>
            <div class="content">
                <h4>Customer No. & Name : ${data.customer_centerwiseid} ${data.customer_name}</h4> 
            </div>
            <div class="content">
                <h4>Received Amount Rs. ${data.cramt}</h4>            
            </div>
            ${data.payment_by == "BANK" ? `
              <div class="content">
                  <h4>Bank Name : ${data.cust_bank_name == null ? '' : data.cust_bank_name}</h4>
                  <h4>Branch : ${data.cust_branch_name == null ? '' : data.cust_branch_name}</h4>
              </div>
              <div class="content">
                  <h4>Cheque No. : ${data.cust_check_no == null ? '' : data.cust_check_no}</h4>
                  <h4>Cheque Date : ${data.cust_check_date == null ? '' : data.cust_check_date}</h4>
              </div>
          ` : ''}   
        </div>
    </body>
    </html>
    
    `);
    printWindow.document.close();
    // printWindow.print();
  };

  // Define a state variable to keep track of whether customer_centerwiseid input has been blurred
  const [customerCenterwiseIdBlurred, setCustomerCenterwiseIdBlurred] = useState(false);

  // Blur event handler for customer_centerwiseid
  const handleCustomerCenterwiseIdBlur = () => {
    setCustomerCenterwiseIdBlurred(true); // Set the flag to true when blurred
    getrecordsbycustomer_centerwiseid_edit(formData.customer_centerwiseid);
  };

  //below code is used to place customer_centerwiseid of selected customer from datalist in its inputbox.
  // Select event handler for customer_name
  const handleDatalistSelect = (e) => {
    const selectedOption = e.target.value;
    const selectedCustomer = customer_name_data.find((option) => option.customer_name === selectedOption);
    if (selectedCustomer) {
      setFormData((prevData) => ({
        ...prevData,
        customer_centerwiseid: selectedCustomer.customer_centerwiseid,
      }));
      // Check if customer_centerwiseid has been blurred recently before calling the function
      if (!customerCenterwiseIdBlurred) {
        getrecordsbycustomer_centerwiseid_edit(selectedCustomer.customer_centerwiseid, "setcustomer_centerwiseid_edit");
      }
    }
    // Reset the flag after handling the select event
    setCustomerCenterwiseIdBlurred(false);
  };

  const handleDatalistSelect_cust_bank_name = (e) => {
    const selectedOption = e.target.value;
    const selectedCustomer = customer_name_data.find((option) => option.bankname === selectedOption);
    if (selectedCustomer) {
      setFormData((prevData) => ({
        ...prevData,
        cust_bank_name: selectedCustomer.bankname,
      }));
    }
  };

  const [showbankoptions, setshowbankoptions] = useState(false);
  const [showtable, setshowtable] = useState(false);
  const [TotalCramt, setTotalCramt] = useState(0);

  const totalCrmat = ReceiptMasterData.reduce((acc, curr) => Number(acc) + Number(curr.cramt), 0);

  const [isCheckedPrintAfterSave, setisCheckedPrintAfterSave] = useState(false);
  const handleCheckboxChange = () => {
    setisCheckedPrintAfterSave(!isCheckedPrintAfterSave);
  };

  const[AllCustomerMasterData, setAllCustomerMasterData] = useState([]);
    //2) using axios
  useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`ReceiptMaster/GetAllDataCustomerMasterTable/${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          // Access the response data directly using response.data
          if(AllCustomerMasterData.length <= 0){
            setAllCustomerMasterData(response.data);
          }                    
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(AllCustomerMasterData);

  const handleFindClick = (event) => {
    event.preventDefault();
    setShowPopup(true);
    setFilteredData(AllCustomerMasterData); // Reset filtered data when opening the popup
  };

  const handleInputChangePopUpTable = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    const filtered = AllCustomerMasterData.filter((item) => {
      // Improved search across all columns using `some`
      return item.customer_name.toLowerCase().includes(searchTerm);
    });
    setFilteredData(filtered);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleRowClick = (customer) => { 
    
    setdefaultcenter(customer.centerid);
    setdefaultroutename(customer.routename);

    setFormData({
      ...formData,
      centerid: customer.centerid,
      centerno: customer.centerno,
      routename: customer.routename,
      customer_centerwiseid: customer.customer_centerwiseid,
      customer_mp_id: customer.customer_mp_id,
      customer_name: customer.customer_name,
      customer_mobile: customer.customer_mobile,
      customer_place: customer.customer_place,
      amount: 0,
      option2: "CASH",
      cust_bank_name: '',
      cust_branch_name: '',
      cust_check_no: '',
      cust_check_date: '',
      operationtype: "Save",
    })
    
    if(formData.tran_date !== "") {
      getclbaltoeditrecord(customer.customer_mp_id, formData.tran_date);
    }

    setshowbankoptions(false);
    setShowPopup(false); // Close the popup
    fetchcustomer_name_data(customer.centerid, customer.routename);
  };

  const getformatteddate = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
  };

  return (

    <>
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', fontSize: 20 }} className="text-center"> {option1} Receipt ({formData.option2}) </h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>
    
    <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} > 
    <form onSubmit={handleSubmit} method="POST" id="form1">
    <div className="row" styles="background-color:lightgreen;">
      <div className="col-md-12">
        {/* <form onSubmit={handleSubmit} method="POST" id="form1"> */}
          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="id-tran_date" className="form-label">Date</label>
              {/* to dispplay record by date we also used tran_date in a useeffect */}
              <input type="date" className="form-control" name="tran_date" value={formData.tran_date}  onChange={handleInputChange} required/>
            </div>

            <div className='col-md-2'>
              <label htmlFor="receiptno" className="form-label">Voucher No</label>
              {/* max + 1 this formula for below value */}
              <input type="number" className="form-control" name="receiptno" value={formData.receiptno}  onChange={handleInputChange} onBlur={receiptnoonblur}/>
              {errors.receiptno && <span style={{color: 'red', fontSize: '10px'}}>{errors.receiptno}</span>}
            </div>

          {/* </div>
          
          <div className="row mb-3"> */}
            <div className="col-md-2">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="number" className="form-control" name="centerno" placeholder='Type here' 
                value={formData.centerno}  onBlur={centernoonblur} onChange={handleInputChange}
                required 
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER" ||
                  formData.operationtype == "Edit"                  
                }/>
                
                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
            </div> 
            
            <div className="col-md-2">
              <label htmlFor="centerid" className="form-label">Center</label>
              <select
                  className="form-select"
                  name="centerid" // <-- should match `formData.uom1` property
                  value={formData.centerid}
                  onChange={handleInputChange}
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER" ||
                    formData.operationtype == "Edit"
                  }
                >
                {/* uom option from selected record when available */}
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>              
            </div>

            <div className="col-md-2">
              <label htmlFor="id-routename" className="form-label">Line Name</label>
              
              <select
              name="routename"
              className="form-select"
              id="id-routename"
              value={formData.routename}
              onChange={handleInputChange} 
              >

              {defaultroutename && (
                <option value={defaultroutename}>
                  {defaultroutename}
                </option>
              )}

              {RouteMasterData.map((r) => (
                 defaultroutename !== r.routename ?
                 <option key={r.routecode} value={r.routename}>
                   {r.routename}
                 </option>
                  : null
              ))}

              </select>
              {errors.routename && <span style={{color: 'red', fontSize: '10px'}}>{errors.routename}</span>}
            </div>
          
          </div>

          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="id-purchase_rate" className="form-label">Customer No</label>
              <input type="number" className="form-control" name="customer_centerwiseid" ref={customer_centerwiseidRef} value={formData.customer_centerwiseid} onChange={handleInputChange} onBlur={() => handleCustomerCenterwiseIdBlur()} placeholder='Type here' required />
              {errors.customer_centerwiseid && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_centerwiseid}</span>}
              <input type="hidden" className="form-control" name="customer_mp_id" value={formData.customer_mp_id} onChange={handleInputChange} placeholder='customer_mp_id'/>              
              <input type="hidden" className="form-control" name="customer_place" value={formData.customer_place} onChange={handleInputChange} placeholder='customer_place'/>              
              <input type="hidden" className="form-control" name="customer_mobile" value={formData.customer_mobile} onChange={handleInputChange} placeholder='customer_mobile'/>                
              <input type="hidden" className="form-control" name="centerid" value={formData.centerid} onChange={handleInputChange} placeholder='centerid'/>      
                            
            </div>
            <div className="col-md-4">
              <label htmlFor="customer_name" className="form-label">Customer Name</label>

              <input
                type='text'
                name='customer_name'
                list='customer_name_list'
                className='form-control'
                value={formData.customer_name}
                onChange={handleInputChange}
                onSelect={handleDatalistSelect}
                autoComplete='off' //to not show cache data in inputbox
              />
              <datalist id="customer_name_list" >
                {Array.isArray(customer_name_data) && customer_name_data.map((option) => ( 
                  <option key={option.customer_centerwiseid} value={option.customer_name} />
                ))}
              </datalist>
            </div>   
            
            <div className='col-md-1'>
              <label htmlFor="customer_name" className="form-label" style={{color: 'white'}}>Find</label>
              <button type="submit" className="btn btn-primary w-100" onClick={handleFindClick}>Find</button>
            </div>
            
            <Modal show={showPopup} onHide={handlePopupClose} backdrop="static" keyboard={false} > {/* Disable background click and keyboard close */}
            <ModalHeader style={{backgroundColor: 'green', color: 'white'}}closeButton>
              <h3>Search Results</h3>
            </ModalHeader>
            <ModalBody>
              
              {/* <Table striped bordered hover >  */}
              <div className="table-container" style={{height: '350px', overflow: 'auto', borderWidth:'0px' }}>
              <input type="text" className='form-control' placeholder="Search..." style={{marginBottom: 8, border: '2px solid black'}} value={searchTerm} onChange={handleInputChangePopUpTable} />
                <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{backgroundColor:'#009879', color:"white", verticalAlign: 'top'}}>Customer Name</th>
                    <th style={{backgroundColor:'#009879', color:"white", verticalAlign: 'top'}}>Center Name</th>
                    <th style={{backgroundColor:'#009879', color:"white", verticalAlign: 'top', textAlign: 'right'}}>Line No</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.customer_mp_id} onClick={() => handleRowClick(item)}>
                      <td style={{cursor: 'pointer'}}>{item.customer_name}</td>
                      <td style={{cursor: 'pointer'}}>{getCenterNameById(item.centerid)}</td>
                      <td style={{cursor: 'pointer', textAlign: 'right'}}>{item.routename}</td>
                    </tr>
                  ))}
                </tbody>
              {/* </Table> */}
              </table>
              </div>
            </ModalBody>
            
            </Modal>

          </div>

          <div className='row mb-3'> 
            <div className='col-md-2'>
              <label>Previous month balance</label>
              <br></br>
              <label className='form-label' style={{marginTop:5, fontSize:20}}>{Number(formData.prevmonthbalrs).toFixed(2)}</label>
              <input type="hidden" className="form-control" name="prevmonthbalrs" value={Number(formData.prevmonthbalrs).toFixed(2)} onChange={handleInputChange} placeholder='Type here' required readOnly/>
            </div>
            
            <div className='col-md-2'>
              <label>Balance Till date</label>
              <br></br>
              <label className='form-label' style={{marginTop:5, fontSize:20}}>{Number(formData.prevbalrs).toFixed(2)}</label>
              <input type="hidden" className="form-control" name="prevbalrs" value={Number(formData.prevbalrs).toFixed(2)} onChange={handleInputChange} placeholder='Type here' required readOnly/>
            </div>
            <div className='col-md-2'>
              <label htmlFor="amount" className="form-label">Amount Rs.</label>
              <input type="text" className="form-control" name="amount" value={formData.amount} onChange={handleInputChange} placeholder='Type here' required />
              {errors.amount && <span style={{color: 'red', fontSize: '10px'}}>{errors.amount}</span>}
            </div>
            <div className='col-md-2' style={{display: 'none'}}>
              <label htmlFor="clbal" className="form-label">Closing balance</label>
              <input type="number" className="form-control" name="clbal" value={formData.clbal} onChange={handleInputChange} placeholder='Type here' required readOnly/>
            </div>          

          </div>

          <div className='row mb-3'>
            <div className="col-md-2">
              <label htmlFor="option2" className="form-label">Payment Mode</label>
              
              <select
              name="option2"
              className="form-select"
              id="id-option2"
              value={formData.option2}
              onChange={handleInputChange} 
              >

              <option value="CASH">CASH</option>
              <option value="ONLINE CASH">ONLINE CASH</option>
              <option value="JANATA BANK">JANATA BANK</option>
              <option value="OTHER BANK">OTHER BANK</option>

              </select>
              {errors.option2 && <span style={{color: 'red', fontSize: '10px'}}>{errors.option2}</span>}
            </div>
            
          </div>
          
          {showbankoptions && ( // Render BankSection code only if ShowBankSection is true
            <>
            <div className='row mb-3'>
              <div className='col-md-3'>

                <label htmlFor="cust_bank_name" className="form-label">Bank Name</label>
                {/* <select
                name="cust_bank_name"
                className="form-select"
                value={formData.cust_bank_name}
                onChange={handleInputChange} 
                >         
                Hey chatgpt, put here code to show JANATA BANK bank        
                <option value="JANATA BANK">JANATA BANK</option>
                <option value="SBI">SBI</option>
                <option value="Union Bank">Union Bank</option>
                <option value="HDFC Bank">HDFC Bank</option>
                </select> */}

                <input
                type='text'
                name='cust_bank_name'                
                className='form-control'
                value={formData.cust_bank_name}
                onChange={handleInputChange}    
                autoComplete='off'
                list='cust_bank_name_list'
                onSelect={handleDatalistSelect_cust_bank_name}
                />
                
                {formData.option2 === "OTHER BANK" && (
                <datalist id="cust_bank_name_list">                  
                  {Array.isArray(BankMasterData) && BankMasterData.map((option) => (
                    <option key={option.bankid} value={option.bankname} />
                  ))}
                </datalist>
                )}
                {errors.cust_bank_name && <span style={{color: 'red', fontSize: '10px'}}>{errors.cust_bank_name}</span>}

              </div>
              
              <div className='col-md-3'>
                <label htmlFor="cust_branch_name" className="form-label">Branch</label>
                <input type="text" className="form-control" name="cust_branch_name" value={formData.cust_branch_name} onChange={handleInputChange} placeholder='Type here' />
                {errors.cust_branch_name && <span style={{color: 'red', fontSize: '10px'}}>{errors.cust_branch_name}</span>}
              </div>
            {/* </div>
            <div className='row mb-3'> */}
              <div className='col-md-2'>
                <label htmlFor="cust_check_no" className="form-label">Cheque No</label>
                <input type="text" className="form-control" name="cust_check_no" value={formData.cust_check_no} onChange={handleInputChange} placeholder='Type here' />
                {errors.cust_check_no && <span style={{color: 'red', fontSize: '10px'}}>{errors.cust_check_no}</span>}
              </div>
              <div className='col-md-2'>
                <label htmlFor="cust_check_date" className="form-label">Cheque Date</label>
                <input type="date" className="form-control" name="cust_check_date" value={formData.cust_check_date} onChange={handleInputChange} placeholder='Type here' />
                {errors.cust_check_date && <span style={{color: 'red', fontSize: '10px'}}>{errors.cust_check_date}</span>}
              </div>
            </div>
            </>
          )}
          
          <input type="hidden" className="form-control" name="transaction_id" value={formData.transaction_id} onChange={handleInputChange} />
          <input type="hidden" className="form-control" name="operationtype" value={formData.operationtype}  onChange={handleInputChange} />

          <div className='row mb-3'>
            <div className='col-md-1'>
              <button type="submit" className="btn btn-primary w-100" >{formData.operationtype == 'Save' ? 'Save' : 'Edit'}</button>
            </div>
            <div className='col-md-1' style={{width : 150}} >
              <button type="button" className="btn btn-primary w-100" onClick={() => setshowtable(!showtable)}>
                {showtable ? 'Hide Table' : 'Show Table'}
              </button>
            </div>

            <div className='col-md-1' style={{width : 350, marginTop: -25}} >
              <input className="form-check-input" type="checkbox" checked={isCheckedPrintAfterSave} onChange={handleCheckboxChange} name="DeleteAndGenerateAgain" style={{marginTop: '40px'}} />
              <label className="form-check-label" htmlFor="flexCheckDefault" style={{marginTop: '36px', marginLeft: '10px'}}>
                Print After Save
              </label>
            </div>

            <div className='col-md-2' style={{marginTop: 8}}>
              <label className='form-label'>Number of Vouchers : {ReceiptMasterData.length}</label>
            </div>
            <div className='col-md-2' style={{marginTop: 8}}>
              <label className='form-label'>Amount Received : {Number(totalCrmat).toFixed(2)} </label>
            </div>

          </div>
      </div>
    </div>
    </form>
    
    {showtable && (
      <>
      <div className="row mt-2">
          <div className="col-md-12 table-responsive-xl">
          {/* below table-container code addded to keep table size fixed still its records increases */}
            <div className="table-container" style={{ height: '300px', overflow: 'auto', borderWidth:'0px' }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'right', position: 'sticky', top: 0,left:0, zIndex:1}}>Voucher No</th>
                    <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'center', position: 'sticky', top: 0,left:0, zIndex:1}}>Customer Name</th>
                    <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'center', position: 'sticky', top: 0,left:0, zIndex:1}}>Center Name</th>
                    <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'right', position: 'sticky', top: 0,left:0, zIndex:1}}>Line No</th>
                    <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'right', position: 'sticky', top: 0,left:0, zIndex:1}}>Amount</th>
                    <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center', position: 'sticky', top: 0,left:0, zIndex:1}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                {ReceiptMasterData.map((c, index) => {
                  return (
                    <tr key={c.transaction_id}>
                      <td style={{textAlign: 'right'}}>{c.receiptno}</td>
                      <td style={{textAlign: 'center'}}>{c.customer_name}</td>
                      <td style={{textAlign: 'center'}}>{getCenterNameById(c.centerid)}</td>
                      <td style={{textAlign: 'right'}}>{c.routename}</td>
                      <td style={{textAlign: 'right'}}>{c.cramt}</td>      
                      <td style={{textAlign: 'center'}}>
                        <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-sm">Edit</button>
                        &nbsp;<button onClick={() => printContent(c)} className="btn btn-info btn-sm" style={{backgroundColor: 'green'}}>Print</button>
                        &nbsp;<button onClick={(event) => confirmDelete(event, c.transaction_id)} className="btn btn-danger btn-sm ml-1">
                        Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>      

          </div>
      </div>
      </>
    )}

  </div>
  </>
  );


}

export default Receipt;
