import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';

function DailyDeliverySheet() {
    
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";

    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }
  
      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds
  
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);

    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;

        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
      const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
      localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
      navigate('/loginpage');
    } 

    const [CustomerMasterData, setCustomerMasterData] = useState([]);
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      
      const formData = new FormData(event.target);
      var validationerrors = {};

      if(defaultroutename.trim() !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 
      seterrors(validationerrors);

      console.log(formData.get('routecode'));
      if(Object.keys(validationerrors).length == 0) {
        var data = {
            centerno: formData.get('centerno'),
            routecode: formData.get('routecode'),
            bookdate: formData.get('bookdate'),
            CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
        };       

        var response  = "";
        response = await axios.post(apireq05042024+'DailyDeliverySheet', data);
        // console.log(response.data);
        // return;
        // setCustomerMasterData([]);
        if (response.status === 200) {
          // setCustomerMasterData([]);  
          setCustomerMasterData(response.data.customer_data);
          // console.log(CustomerMasterData);          
        }        
      }  
    }

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };
    

      if(name == "centerid") {
        const [centerid, centername] = value.split('-');
        updatedFormData.centerno = centerid;
        setdefaultcenter(centerid);
        // getroutemasterdata(centerid, 'd6');
        getroutemasterdatabycenterid(centerid, "centername");
      }

      if(name == "routename") {
        const [routecode, routename] = value.split('-');
        // updatedFormData.routecode = routecode;
        setdefaultroutename(routecode);
        fetchcustomer_name_data(formData.centerid, routecode, formData.bookdate);
      }

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      centerno: '',
      routecode: '',
      bookdate: '',
      centername: '',
      routename: '',
      centerid: '',
      DeleteAndGenerateAgain: '',
      customer_centerwiseid_edit: '',
      customer_name_edit: '',
      cowqty_edit: '',
      cowrate_edit: '',
      buffqty_edit: '',
      buffrate_edit: '',
      transactionid_edit: '',
      customer_mp_id_edit: '',
    });

    function navigatetosale() {
      temporaryvalues_table()
      // navigate('/SalesMaster');
      navigate('/Test2');
    }

    const [defaultcenter, setdefaultcenter] = useState();
    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          if(!defaultcenter){
            setCenterMasterData(response.data.CenterMasterData);
            setdefaultcenter(response.data.CenterMasterData[0].centerid);
            setFormData({
              ...formData,
              centerno: response.data.CenterMasterData[0].centerid,
              bookdate: getCurrentDate(),
            })
            getroutemasterdata(UserMasterDataGlobal?.[0]?.centerid, '2d');
          }

        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const getCenterNameById = (centerid) => {
      const center = CenterMasterData.find(center => center.centerid === centerid);
      return center ? center.centername : '';
    };

    const [defaultroutename, setdefaultroutename] = useState();
    const [RouteMasterData, setRouteMasterData] = useState([]);

    const routecodeonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;
  
      if (value.includes('-')) {
        // Split the value based on '-'
        const [routecode, routename] = value.split('-');
        value = routecode;
      }
  
      if(value == ''){
        return;
      }
  
      const response = await axios.get(apireq05042024+'RouteMaster/getroutenamebyroutecode?routecode='+value+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)
   
      .then((response) => {
        // Access the response data directly using response.data       
        try {
          // formData.routename = response.data.customer_name_data[0].routename;
          setdefaultroutename(response.data.routenamedata[0].routename);
        }
        catch{

        }
        
      });   
    };
  
    const centernoonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;

      if(value == ''){
        return;
      }
      setdefaultcenter(value);
      formData.centerid = value;
      // getroutemasterdata(value, 'd1');
      getroutemasterdatabycenterid(value);
      fetchcustomer_name_data(value,defaultroutename, formData.bookdate); 
    };

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        bookdate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    function confirmDelete(event, id) {
      event.preventDefault();

      MySwal.fire({
          title: 'Are you sure you want to delete the transaction record?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
          if (result.isConfirmed) {
              if (result.value) {
              // window.location.href = $(this).attr('href');
              deleteData(id);
              }
          }
      })
  }

  async function deleteData(id) {

    const response = await axios.delete(apireq05042024+`OtherController/delete/${id}/${UserMasterDataGlobal?.[0]?.CompanyId}`);
    
    if (response.status === 200) {
        if(response.data==="Sucessfully deleted") {
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record deleted Sucessfully!</b>,
            icon: 'success'
            })

            setCustomerMasterData((prevData) => prevData.filter((record) => record.transaction_id !== id));
          } else if(response.data == "found") {
          MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>This cusomer has transactions; so can't delete this account</b>,
              icon: 'warning'
          })        
        } 
  } else {
        MySwal.fire({
        //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record not deleted! Please try once again or call the developer</b>,
            icon: 'error'
        })
    }
  }

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      temporaryvalues_table();
      
  }

  async function temporaryvalues_table(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(defaultroutename !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 

      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {

        var data = {
          // centerno: defaultcenter || (UserMasterDataGlobal?.[0]?.centerid || ""),
          centerno:formData.centerno,
          routecode: defaultroutename,
          // routecode : formData.routecode ,
          bookdate: formData.bookdate || getCurrentDate(),
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          userid: UserMasterDataGlobal?.[0]?.userid || "",
        };
        // alert(data.routecode + " at step1");
       
        setFormData({
          ...formData,
          centerno: data.centerno,
          routecode: data.routecode,
          bookdate: data.bookdate
        });
        // alert(data.routecode + " at step2");
        // return;
        setdefaultcenter(data.centerno);
        setdefaultroutename(data.routecode);

        try {
          
          var response  = "";
          response = await axios.post(apireq05042024+'DailyDeliverySheet/updateinsert_temporaryvalues_table', data);

          if (response.status === 200) {
            // alert(response.data);
            // return;
          }
          
          if(toshowcustomermasterdata !== "notshow") {
            var response  = "";
            response = await axios.post(apireq05042024+'DailyDeliverySheet', data);

            if (response.status === 200) {
              // setCustomerMasterData([]);                
                setCustomerMasterData(response.data.customer_data);          
              // console.log(CustomerMasterData);          
            } 
            fetchcustomer_name_data(defaultcenter, defaultroutename, formData.bookdate);
          }

        } catch {

        } 

      }  
  }

  function regeneraterecords (event) {
  // function regeneraterecords(event) {
    event.preventDefault();

    if(isCheckedGenerateAndDeleteAgain == 1) {
      MySwal.fire({
        title: 'Proceeding will delete previous transaction data. Confirm?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, continue!'
      }).then((result) => {
          if (result.isConfirmed) {
              if (result.value) {
              // window.location.href = $(this).attr('href');
                addbulkentries();
              }
              else{
                return;
              }
          }
      })
    }
    else {
      addbulkentries();
    }    
  }

  async function addbulkentries() {

    const centerno = formData.centerid;
    const routecode = formData.routecode;
    const bookdate = formData.bookdate;

    const data = {
      centerno: defaultcenter,
      routecode: routecode,
      bookdate: bookdate,
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
      userid: UserMasterDataGlobal?.[0]?.userid,
      isCheckedGenerateAndDeleteAgain: isCheckedGenerateAndDeleteAgain,
    }

    var response  = "";
    response = await axios.post(apireq05042024+'DailyDeliverySheet/getCustomerMasterdataonregenerate', data);
    console.log(response.data);
    // setCustomerMasterData([]);
    if (response.status === 200) {
      if(response.data.message == "Records saved succesfully") {
        MySwal.fire({
          icon: 'success',
          title: 'Successful!',
          text: `${response.data.message}`
        });
        temporaryvalues_table();
      }else if (response.data.message == "No results found.") {
        MySwal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: `${response.data.message}`
        });
      }
      // setCustomerMasterData(response.data.customer_data);        
    }
  }

  function deletealltransactionrecords (event) {
    event.preventDefault();

    MySwal.fire({
      title: 'This action will affect data for a particular transactions. Confirm?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, continue!'
    }).then((result) => {
        if (result.isConfirmed) {
            if (result.value) {
            // window.location.href = $(this).attr('href');
              deleteData1()
            }
            else{
              return;
            }
        }
    })
  }

  async function deleteData1() {

    const centerno = formData.centerno;
    const routecode = formData.routecode;
    const bookdate = formData.bookdate;

    const data = {
      centerno: centerno,
      routecode: routecode,
      bookdate: bookdate,
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
    };

    var response  = "";
    response = await axios.post(apireq05042024+'DailyDeliverySheet/deleteall1', data);

    // setCustomerMasterData([]);
    if (response.status === 200) {
      // console.log(response.data);
      if(response.data=="Successfully deleted") {
        MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
          html: <b>Record deleted Sucessfully!</b>,
          icon: 'success'
        })
        
        setCustomerMasterData([]);  
      }
    }
  }

  const { state } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state) {
          setFormData({
            ...formData,
            centerno: state?.centerid,
            routecode: state?.routecode,
            bookdate: state?.transdate,
          });  
          // formref.current.submit(); 
          // console.log('s');
          // if (buttonref.current) {
          Swal.fire("Click on view button to see the latest record added!");
          // }
        }

      } catch (error) {
        console.error('Error fetching data for editing:', error);
      }
    };

    fetchData();
  }, [state]);
    
  // const [temporaryvalues_table_data, settemporaryvalues_table_data] = useState([]);
  
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      
      const userIdapireq = UserMasterDataGlobal[0]?.userid;
      const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;
      
      const apirequest = apireq05042024+`DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;

      axios.get(apirequest)
        .then((response) => {
          try {
            if(!defaultcenter){
              setdefaultroutename(response.data[0].text3);
              setdefaultcenter(response.data[0].text1);

              setFormData({
                ...formData,
                routecode: response.data[0].text3,
                centerno: response.data[0].text1,
                bookdate: response.data[0].text5,
              });
              fetchcustomer_name_data(response.data[0].text1, response.data[0].text3, response.data[0].text5);
            }
          }
          catch {

          }          

        })

        .catch((error) => console.error('Error fetching route data:', error));
    }
  }, [UserMasterDataGlobal]);

  async function getroutemasterdata(centerid, rr) {
    // console.log(rr);
    var data1 = {
      centerid : centerid,
      CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    // console.log(data1);
    var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
      setdefaultroutename(response1.data[0].routename);
      setRouteMasterData(response1.data);
    })
  }

  //below code is for GenerateAndDeleteAgain
  const [isCheckedGenerateAndDeleteAgain, setisCheckedGenerateAndDeleteAgain] = useState(false);
  const handleCheckboxChange = () => {
    setisCheckedGenerateAndDeleteAgain(!isCheckedGenerateAndDeleteAgain);
  };

  //this is the array mounted for populating customer_name list in its selectbox
  const [customer_name_data,  setcustomer_name_data] =  useState([]);

  //This function is for customer_centerwiseid onblur and customer_name selectbox value change
  async function getrecordsbycustomer_centerwiseid_edit(customer_centerwiseid_edit=null, setcustomer_centerwiseid_edit=null) {

    if(customer_centerwiseid_edit == ''){
      return;
    }

    const axiosreqtoapi = apireq05042024+'DailyDeliverySheet/getrecordbybycustomer_centerwiseid?customer_centerwiseid='+customer_centerwiseid_edit+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId
    +'&centerid='+formData.centerno+'&routename='+defaultroutename+'&tran_date='+formData.bookdate;

    const response = await axios.get(axiosreqtoapi)
    
    .then((response) => {
    
      if(response.data.CustomerTransactionData.length > 0){
        //below code is to see if the array we got in response is present already in customer_name_data array.

        const index = customer_name_data.findIndex(item =>
            item.customer_mp_id === response.data.CustomerTransactionData[0].customer_mp_id
        );
 
        // If response array already exists in customer_name_data, move it to the first index
        if (index !== -1) {                
            const removedArray = customer_name_data.splice(index, 1)[0];        
            // Then insert it at the beginning
            const newData = [removedArray, ...customer_name_data];
            setcustomer_name_data(newData);

            if(setcustomer_centerwiseid_edit=="setcustomer_centerwiseid_edit") {
                 //putting the existing array customer_name to the value of select box
                setFormData({
                    ...formData,
                    transactionid_edit: response.data.CustomerTransactionData[0].transaction_id,
                    customer_centerwiseid_edit: response.data.CustomerTransactionData[0].customer_centerwiseid,
                    cowqty_edit: response.data.CustomerTransactionData[0].cowqty,
                    cowrate_edit: response.data.CustomerTransactionData[0].cowrate,
                    buffqty_edit: response.data.CustomerTransactionData[0].buffqty,
                    buffrate_edit: response.data.CustomerTransactionData[0].buffrate,
                    customer_mp_id_edit: response.data.CustomerTransactionData[0].customer_mp_id,
                });
            }
            else{
                setFormData({
                    ...formData,
                    transactionid_edit: response.data.CustomerTransactionData[0].transaction_id,
                    customer_name_edit: response.data.CustomerTransactionData[0].customer_name,
                    cowqty_edit: response.data.CustomerTransactionData[0].cowqty,
                    cowrate_edit: response.data.CustomerTransactionData[0].cowrate,
                    buffqty_edit: response.data.CustomerTransactionData[0].buffqty,
                    buffrate_edit: response.data.CustomerTransactionData[0].buffrate,
                    customer_mp_id_edit: response.data.CustomerTransactionData[0].customer_mp_id,
                });
            }               

        } else {
            // If it doesn't exist, push it normally
            setcustomer_name_data(response.data.CustomerTransactionData);

            if(setcustomer_centerwiseid_edit=="setcustomer_centerwiseid_edit") {
                //putting the existing array customer_name to the value of select box
               setFormData({
                   ...formData,
                   transactionid_edit: response.data.CustomerTransactionData[0].transaction_id,
                   customer_centerwiseid_edit: response.data.CustomerTransactionData[0].customer_centerwiseid,
                   cowqty_edit: response.data.CustomerTransactionData[0].cowqty,
                   cowrate_edit: response.data.CustomerTransactionData[0].cowrate,
                   buffqty_edit: response.data.CustomerTransactionData[0].buffqty,
                   buffrate_edit: response.data.CustomerTransactionData[0].buffrate,
                   customer_mp_id_edit: response.data.CustomerTransactionData[0].customer_mp_id,
               });
            }
            else{
              setFormData({
                ...formData,
                transactionid_edit: response.data.CustomerTransactionData[0].transaction_id,
                customer_name_edit: response.data.CustomerTransactionData[0].customer_name,
                cowqty_edit: response.data.CustomerTransactionData[0].cowqty,
                cowrate_edit: response.data.CustomerTransactionData[0].cowrate,
                buffqty_edit: response.data.CustomerTransactionData[0].buffqty,
                buffrate_edit: response.data.CustomerTransactionData[0].buffrate,
                customer_mp_id_edit: response.data.CustomerTransactionData[0].customer_mp_id,
              });
            }
        }

      } else {
        //If no data from API then clear customer_centerwiseid field and apologies message
        if(formData.customer_centerwiseid_edit !== '') {
            alert("No transactions found for this Customer CenterWise ID...");
            setFormData({
                ...formData,
                transactionid_edit: '',
                customer_centerwiseid_edit: '',
                cowqty_edit: '',
                cowrate_edit: '',
                buffqty_edit: '',
                buffrate_edit: '',
                customer_mp_id_edit: '',
            });                
        }        
      }
    })
    
    .catch((error) => {
      console.error('Error fetching data:', error);
    });    
  }

    //below function is for putting data in customer_name_data array
    async function  fetchcustomer_name_data(centerid, routecode, bookdate) {
      
      setcustomer_name_data([]);

      var axiosreqtoapi = apireq05042024+'RouteMaster/getCustomerDataWithCenterNoAndRouteCode?routecode='+routecode
      +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid+'&bookdate='+bookdate;
      
      const response = axios.get(axiosreqtoapi)

      .then((response) => {
          if(response.data.customer_name_data.length > 0) {
              setcustomer_name_data(response.data.customer_name_data);
          }
      })
      .catch((error) => {
          console.error('Error fetching data:', error);
      });
    }

    //to get the data from centerid/centerno we use this function
    async function getroutemasterdatabycenterid(centerid, rr=null) {
      var data1 = {
          centerid : centerid,
          CompanyId : UserMasterDataGlobal[0]?.CompanyId,
      }
      var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
      .then((response1) => {
          setdefaultroutename(response1.data[0].routename);
          setRouteMasterData(response1.data);
          if(rr=="centername") {
              //populating customer_name list using customer_name_data array by calling below function.
              //i put below function here because when page first time renders and we try to change
              //centername from selectbox. it is trying to take routename of previous center.
              fetchcustomer_name_data(centerid,response1.data[0].routename, formData.bookdate);
          }
      })
  }

    async function ShortEdit() {
      
      var data = {
        CompanyId: UserMasterDataGlobal?.[0].CompanyId,
        userid: UserMasterDataGlobal?.[0].userid,
        tran_date: formData.bookdate,
        cowqty: formData.cowqty_edit,
        cowrate: formData.cowrate_edit,
        cowamt: formData.cowqty_edit * formData.cowrate_edit,
        buffqty: formData.buffqty_edit,
        buffrate: formData.buffrate_edit,
        buffamt: formData.buffqty_edit * formData.buffrate_edit,
        transactionid: formData.transactionid_edit,
        customer_mp_id: formData.customer_mp_id_edit,
      };

      if(data.transactionid == '') {
        alert('Please type customer no in its inputbox or select customer name from list to edit the record');
        return;
      }

      const response = await axios.post(apireq05042024+'DailyDeliverySheet/ShortEdit', data);
      if (response.status === 200) {
        if(response.data=="Successfully Updated") {
          MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record Updated Sucessfully!</b>,
            icon: 'success'
          })
          
          setCustomerMasterData((prevData) => {
            const updatedData = [...prevData];
            const index = updatedData.findIndex((record) => record.transaction_id == data.transactionid);

            if (index !== -1) {
              updatedData[index] = { ...updatedData[index], ...data };
            }           
              
            return updatedData;
          });

          EmptyInputsAfterEdit();

        }
      }
    }

    function EmptyInputsAfterEdit() {

      setFormData((prevData) => ({
        ...prevData,
        cowqty_edit: '',
        cowrate_edit: '',
        buffqty_edit: '',
        buffrate_edit: '',
        customer_centerwiseid_edit: '',
      }));
    }

    //These variables declared here to show Total of Qty and Amt at the last of table.
    var CowQtyTotal =0;
    var BuffQtyTotal =0;
    var CowAmtTotal =0;
    var BuffAmtTotal =0;

  // Define a state variable to keep track of whether customer_centerwiseid input has been blurred
  const [customerCenterwiseIdBlurred, setCustomerCenterwiseIdBlurred] = useState(false);

  // Blur event handler for customer_centerwiseid
  const handleCustomerCenterwiseIdBlur = () => {
    setCustomerCenterwiseIdBlurred(true); // Set the flag to true when blurred
    getrecordsbycustomer_centerwiseid_edit(formData.customer_centerwiseid_edit);
  };

  //below code is used to place customer_centerwiseid of selected customer from datalist in its inputbox.
  // Select event handler for customer_name
  const handleDatalistSelect = (e) => {
    const selectedOption = e.target.value;
    const selectedCustomer = customer_name_data.find((option) => option.customer_name === selectedOption);
    if (selectedCustomer) {
      setFormData((prevData) => ({
        ...prevData,
        customer_centerwiseid_edit: selectedCustomer.customer_centerwiseid,
      }));
      // Check if customer_centerwiseid has been blurred recently before calling the function
      if (!customerCenterwiseIdBlurred) {
        getrecordsbycustomer_centerwiseid_edit(selectedCustomer.customer_centerwiseid, "setcustomer_centerwiseid_edit");
      }
    }
    // Reset the flag after handling the select event
    setCustomerCenterwiseIdBlurred(false);
  };

    return(
            
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', fontSize: 20, cursor: 'default' }} className="text-center"> Daily Order Sheet</h1>
        <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
            <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
                </li>
            </ul>
            <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
            </div>
        </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form onSubmit={handleSubmit} method='get'> 

            <div className="row mb-2">
              <div className="col-md-1">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="text" className="form-control" name="centerno" id="id-centerno" value={formData.centerno} onChange={handleInputChange}
                onBlur={centernoonblur} required 
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                }/>

                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
              </div> 

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="centerid" className="form-label">Select Center</label>

                <select
                  className="form-select"
                  name="centerid" // <-- should match `formData.uom1` property
                  // value={formData.centerid}
                  onChange={handleInputChange}
                  
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                {/* uom option from selected record when available */}
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>
              </div>

              {/* <div className="col-md-2"> */}
                {/* <label htmlFor="id-routecode" className="form-label">Line No </label> */}
                <input type="hidden" className="form-control" name="routecode" value={defaultroutename} onChange={handleInputChange} 
                onBlur={routecodeonblur} required />
                
              {/* </div> */}

              <div className="col-md-1">
                <label htmlFor="id-routename" className="form-label">Select Line</label>
                <select
                  className="form-select"
                  id="id-routename" 
                  name="routename"
                  // value={formData.routename}
                  onChange={handleInputChange}
                >

                {defaultroutename && (
                  <option value={defaultroutename}>
                    {defaultroutename}
                  </option>
                )}

                {RouteMasterData.map((r) => (
                  defaultroutename !== r.routename ?
                  <option key={r.routecode} value={`${r.routecode}-${r.routename}`} >
                    {r.routename}
                  </option>
                    : null
                ))}
                </select>
                {errors.routecode && <span style={{color: 'red', fontSize: '10px'}}>{errors.routecode}</span>}
              </div>

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="id-bookdate" className="form-label">Date</label>
                <input type="date" className="form-control" name="bookdate" id="id-bookdate" value={formData.bookdate} onChange={handleInputChange} required/>
                {errors.bookdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.bookdate}</span>}
              </div>

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
                {/* <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} >View</button> */}
              </div>
              
              {/* because 'Delete and generate again' checkbox overlapping on deleteall button. I put zindex there. */}
              <div className="col-md-1" style={{zIndex: 111}}>
                <button type="submit" className="btn btn-primary w-80" style={{ marginTop: '32px', marginLeft: -1, width: 100}} onClick={(event) => deletealltransactionrecords(event)}>Delete All</button>
              </div>
                
              <div className="col-md-3"  style={{textAlign:'right', marginLeft: -50}}>
              <input className="form-check-input" type="checkbox" checked={isCheckedGenerateAndDeleteAgain} onChange={handleCheckboxChange} name="DeleteAndGenerateAgain" style={{marginTop: '40px'}} />
              <label className="form-check-label" htmlFor="flexCheckDefault" style={{marginTop: '36px', marginLeft: '10px'}}>
                        Delete and generate again
              </label>
                  
              </div>
              <div className="col-md-2">                
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => regeneraterecords(event)} >Genarate in Bulk</button>
              </div>

            </div> 
          </form>
      </div>
      
      <div className='row mt-3' style={{backgroundColor: '#009879', color: 'white', paddingBottom: '15px', borderRadius: '5px',  marginLeft: '2px', marginRight: '2px'}}>
          <div className='col-md-2'>
            <label style={{marginBottom: 4}}>Customer No</label>
            <input className='form-control' placeholder='Type here' name="customer_centerwiseid_edit"
            onBlur={() => handleCustomerCenterwiseIdBlur()} value={formData.customer_centerwiseid_edit} onChange={handleInputChange}></input>              
          </div>
          <div className='col-md-2'>
            <label style={{marginBottom: 4}}>Customer Name</label>

              <input
                type='text'
                name='customer_name_edit'
                list='customer_name_list_edit'
                className='form-control'
                value={formData.customer_name_edit}
                onChange={handleInputChange}
                onSelect={handleDatalistSelect}
                autoComplete='off' //to not show cache data in inputbox
              />
              <datalist id="customer_name_list_edit" >
                {Array.isArray(customer_name_data) && customer_name_data.map((option) => ( 
                  <option key={option.customer_centerwiseid} value={option.customer_name} />
                ))}
              </datalist>

          </div>
          <div className='col-md-1'>
            <label style={{marginBottom: 4}}>Buff Qty</label>
            <input className='form-control' placeholder='0.00' name="buffqty_edit"
            value={formData.buffqty_edit} onChange={handleInputChange}></input>
          </div>
          <div className='col-md-2'>
            <label style={{marginBottom: 4}}>Buff Rate</label>
            <input className='form-control' placeholder='0.00' name="buffrate_edit"
            value={formData.buffrate_edit} onChange={handleInputChange}></input>
          </div> 
          <div className='col-md-1'>
            <label style={{marginBottom: 4}}>Cow Qty</label>
            <input className='form-control' placeholder='0.00' name="cowqty_edit"
            value={formData.cowqty_edit} onChange={handleInputChange}></input>
          </div>
          <div className='col-md-2'>
            <label style={{marginBottom: 4}}>Cow rate</label>
            <input className='form-control' placeholder='0.00' name="cowrate_edit"
            value={formData.cowrate_edit} onChange={handleInputChange}></input>
          </div>
          <div className='col-md-2' style={{marginTop:  '25px'}}>
            <button className='btn btn-primary w-100' name="ShortEdit" onClick={ShortEdit}
            style={{border: '2px solid white'}}>Update</button>
          </div>        
          <input type="hidden" className='form-control' name="transactionid_edit"
            value={formData.transactionid_edit} onChange={handleInputChange}></input> 
      </div>

      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '300px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#009879', color:"white", width: '2%'}}>Tran. Id</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '2%'}}>Customer No</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '10%'}}>Name</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '4%', textAlign: 'right'}}>Buff Qty</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '4%', textAlign: 'right'}}>Buff Rate</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '4%', textAlign: 'right'}}>Buff Amt</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '4%', textAlign: 'right'}}>Cow Qty</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '4%', textAlign: 'right'}}>Cow Rate</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '4%', textAlign: 'right'}}>Cow Amt</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '2%', textAlign: 'center'}}>Action</th>
                </tr>
              </thead>
              <tbody>
              {CustomerMasterData.map((c, index) => {
                CowQtyTotal = Number(CowQtyTotal) + Number(c.cowqty);
                CowAmtTotal = Number(CowAmtTotal) + Number(c.cowamt);
                BuffQtyTotal = Number(BuffQtyTotal) + Number(c.buffqty);
                BuffAmtTotal = Number(BuffAmtTotal) + Number(c.buffamt);

                return (
                  <tr key={c.transaction_id}>
                    <td>{c.transaction_id}</td>
                    <td>{c.customer_centerwiseid}</td>
                    <td>{c.customer_name}</td>
                    <td style={{textAlign: 'right'}}>{c.buffqty}</td>
                    <td style={{textAlign: 'right'}}>{c.buffrate}</td>  
                    <td style={{textAlign: 'right'}}>{c.buffamt}</td>  
                    <td style={{textAlign: 'right'}}>{c.cowqty}</td>
                    <td style={{textAlign: 'right'}}>{c.cowrate}</td>
                    <td style={{textAlign: 'right'}}>{c.cowamt}</td>       
                    <td style={{textAlign: 'center'}}>
                      &nbsp;<button onClick={(event) => confirmDelete(event, c.transaction_id)} className="btn btn-danger btn-sm ml-1">
                      Delete
                      </button>
                    </td>
                  </tr>
                );
              })}

              </tbody>
            </table>
          </div>

          <div className="row mb-3">
            <div className='col-md-3' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: '#009879', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
            <span style={{fontSize: 20, color: 'white'}}>Total Buff Qty = {BuffQtyTotal}</span>
            </div>
            <div className='col-md-3' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: '#009879', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
            <span style={{fontSize: 20, color: 'white'}}>Total Buff Amt = {BuffAmtTotal}</span>
            </div>
            <div className='col-md-3' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: '#009879', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total Cow Qty = {CowQtyTotal}</span>
            </div>
            <div className='col-md-3' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: '#009879', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total Cow Amt = {CowAmtTotal}</span>
            </div>           
          </div>

        </div>
      </div>

    </div>

    );

}

export default DailyDeliverySheet;