import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './styles/editable-table.css';
import { Collapse } from 'bootstrap';
import QRCode from "react-qr-code";

function MonthlySheet() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [activeCell, setActiveCell] = useState({ row: 0, col: 0 });
  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  const [CowRateVisible, setCowRateVisible] = useState(false); 	
  const [sortBy, setSortBy] = useState({ column: null, ascending: true });
  const [defaultroutename, setdefaultroutename] = useState();
  const [RouteMasterData, setRouteMasterData] = useState([]);
    
  const [defaultcenter, setdefaultcenter] = useState();
  const [CenterMasterData, setCenterMasterData] = useState([]);
    
  const [errors, seterrors] = useState({});
  const [ShowTable, setShowTable] = useState(false); // State to manage visibility of table code
  
  const [isfastforwardqtyentries, setisfastforwardqtyentries] = useState(false);
  const [ApplyCustomerMasterRates, setApplyCustomerMasterRates] = useState(false);

  const handleCheckboxChange = () => {
    setisfastforwardqtyentries(!isfastforwardqtyentries);
  };

  const handleApplyCustomerMasterRatesCheckBox = () => {
    setApplyCustomerMasterRates(!ApplyCustomerMasterRates);
    setFormData((prevData) => ({
      ...prevData,
      maxcowrate : 0,
      maxbuffrate : 0,
    }));
  };

  const [formData, setFormData] = useState({
    month: '',
    centerno: '',
    routecode: '',
    maxcowrate: '',
    maxbuffrate: '',
    nextmonthrate: '',
    fromcustomer_centerwiseid: '',
    tocustomer_centerwiseid: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}&getCompanyName=true`);
        if(response.data == "logout") {
          navigatetologin();
        }
        if(UserMasterDataGlobal.length <= 0) {
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  } 

  // ***********************************************************************************
  // const [data, setData] = useState([
  //   { id: 1, srNo: 1, studentName: 'Alice', days: ['', '', '', '', '', '', '', '', '', '','', '', '', '', '','', '', '', '', '', '', '', '', '', '','', '', '', '', '', ''] },
  //   { id: 2, srNo: 2, studentName: 'Bob', days: ['', '', '', '', '', '', '', '', '', '','', '', '', '', '','', '', '', '', '', '', '', '', '', '','', '', '', '', '', ''] },
  // ]);
  
  const sampledata = [
    { customer_centerwiseid: 1, customer_name: 'Alice', days: ['', '', '', '', '', '', '', '', '', '','', '', '', '', '','', '', '', '', '', '', '', '', '', '','', '', '', '', '', ''] },
    { customer_centerwiseid: 2, customer_name: 'Bob', days: ['', '', '', '', '', '', '', '', '', '','', '', '', '', '','', '', '', '', '', '', '', '', '', '','', '', '', '', '', ''] },
  ];

  const [CustomerData, setCustomerData] = useState(sampledata);
  const [CustomerDataPrint, setCustomerDataPrint] = useState(sampledata);
  
  const [DataToInsert, setDataToInsert] = useState([]);

  async function handleEditCell (customer_centerwiseid, fieldName, value, rowvalue = null) {
    const newData = [...CustomerData]; // Create copy
    const index = newData.findIndex(item => item.customer_centerwiseid === customer_centerwiseid);
    const newDays = [...newData[index].days]; // Copy of days array
    
    //below array is for making key-value pair of day and its quanity and pass it to api 
    //to save the record.
    var objQtyToApi = {};
    
    if(value == ".") {
      return;
    }

    if (!isNaN(value)) {
      if(isfastforwardqtyentries){
        for (let i = rowvalue; i < newData[index].days.length; i++) {
            objQtyToApi[Number(i + 1)] = value;
            newDays[i] = value;
        }
      } 
      else{
        objQtyToApi[rowvalue + 1] = value;
        newDays[rowvalue] = value;
      }
    } else {
      newDays[index] = value; // Update specific day if not numeric
    }

    const [year, month] = formData.month.split('-');

    newData[index].days = newDays;
    setCustomerData(newData);
    
    const data = {
      customer_centerwiseid : customer_centerwiseid,
      centerid : defaultcenter,
      routename : defaultroutename,
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
      userid: UserMasterDataGlobal?.[0]?.userid || "",      
      month: month,
      year: year,
      cowrate : ApplyCustomerMasterRates ? 0 : formData.maxcowrate,
      buffrate : ApplyCustomerMasterRates ? 0 : formData.maxbuffrate,
      qtys: objQtyToApi,
      takemaxrate: ApplyCustomerMasterRates
    }
    
    const response = await axios.post(apireq05042024+'MonthlySheet/insert', data);
    // console.log(response.data);
    if (response.status == 200) {
      if(response.data=="Sucesss"){
        
      }
      else if(response.data=="Failed"){ // put here code to response.data like Failed
        console.log('Failed');
        MySwal.fire({
          title: 'Failed',
          text: 'Record not saved. Please try to save it again.',
          icon: 'Failed'
        })
      }

    } 

    // setDataToInsert(prevData => [...prevData, data]);

  };

  const inputRefs = useRef([]);

  // useEffect(() => {
  //   if (inputRefs.current[activeCell.row] && inputRefs.current[activeCell.row][activeCell.col]) {
  //     inputRefs.current[activeCell.row][activeCell.col].focus();   
  //     // Use setTimeout for delayed selection
  //     setTimeout(() => {
  //       inputRefs.current[activeCell.row][activeCell.col].select(); // Or trigger a synthetic Ctrl+A event
  //     }, 0);   
  //   }
  // }, [activeCell, inputRefs.current]);
  
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (inputRefs.current[activeCell.row] && inputRefs.current[activeCell.row][activeCell.col]) {
      inputRefs.current[activeCell.row][activeCell.col].focus();   
      // Clear any existing timer
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // Set a new timer
      timeoutRef.current = setTimeout(() => {
        inputRefs.current[activeCell.row][activeCell.col].select(); // Or trigger a synthetic Ctrl+A event
      }, 0);   
    }
  }, [activeCell, inputRefs.current]);
  

  const handleKeyDown = (event) => {

    const { keyCode } = event;
    const { row, col } = activeCell;

    switch (keyCode) {
      case 37: // Left arrow
        setActiveCell({ row, col: Math.max(col - 1, 0) });
        break;
      case 38: // Up arrow
        setActiveCell({ row: Math.max(row - 1, 0), col });
        break;
      case 39: // Right arrow
        setActiveCell({ row, col: Math.min(col + 1, CustomerData[0].days.length - 1) });
        break;
      case 40: // Down arrow
        setActiveCell({ row: Math.min(row + 1, CustomerData.length - 1), col });
        break;
      default:
        break;
    }
  };

  // ***********************************************************************************
  
  //This handlesubmit code is for form submission but it is never used because for every button
  //in this page we use "onclick" attribute to call javascript function.
  const handleSubmit = async (event) => {
    event.preventDefault();
  
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if(name == "month") {
      setShowTable(false);
      setApplyCustomerMasterRates(false);
    }

    if(name == "centerid") {
      const [centerid, centername] = value.split('-');
      updatedFormData.centerno = centerid;
      setShowTable(false);
      setdefaultcenter(centerid);
      setApplyCustomerMasterRates(false);
      // console.log('dfads');
      // getroutemasterdatabycenterid(centerid, "centername");
      
    }

    if(name == "routename") {
      const [routecode, routename] = value.split('-');
      // updatedFormData.routecode = routecode;
      setShowTable(false);
      setdefaultroutename(routecode);
      fetchmaxrates(defaultcenter, routecode);
      setApplyCustomerMasterRates(false);
    }

    setFormData(updatedFormData);
  };

  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      // if(!defaultcenter){
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)  
        .then((response) => {
          if(!defaultcenter){
            setCenterMasterData(response.data.CenterMasterData);
            setdefaultcenter(response.data.CenterMasterData[0].centerid);
            // console.log('popo');
            setFormData({
              ...formData,
              centerno: response.data.CenterMasterData[0].centerid,
            })
            getroutemasterdata(response.data.CenterMasterData[0].centerid, 'getmaxrates');
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      // }
    }
  }, [UserMasterDataGlobal]);

  useEffect(() => {
      if(defaultcenter && formData.month){
        getroutemasterdata(defaultcenter, 'getmaxrates');    
        // ShowCustomerData();  
      }          
  }, [defaultcenter]);

  const getCenterNameById = (centerid) => {
    const center = CenterMasterData.find(center => center.centerid === centerid);
    return center ? center.centername : '';
  };

  async function viewonclick (event) {
    event.preventDefault();     
    ShowCustomerData();
  }

  async function ShowCustomerData(toshowcustomermasterdata = null) {  

    var validationerrors = {};

    if(defaultroutename !== '') {
    }else{
      validationerrors.routecode = "Please select prefered line no from list.";
    } 

    if(defaultcenter !== '') {
    }else{
      validationerrors.centerno = "Please select prefered center from list or type its number here";
    } 

    if(formData.month !== '') {
    }else{
      validationerrors.month = "Please select month & year from month picker";
    } 
    
    const [year, month] = formData.month.split('-');

    seterrors(validationerrors);

    if(Object.keys(validationerrors).length == 0) {

      var data = {
        centerid: defaultcenter,
        routename: defaultroutename,
        year: year,
        month: month,
        CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
        userid: UserMasterDataGlobal?.[0]?.userid || "",
      };

      // setFormData({
      //   ...formData,
      //   month: data.centerno,
      //   routecode: data.routecode,
      //   fromdate: data.fromdate,
      //   todate: data.todate,
      //   customer_centerwiseid: data.customer_centerwiseid
      // });

      try {          
                 
        var response  = "";

        response = await axios.post(apireq05042024+'MonthlySheet/getMonthlySheet', data);
        setCustomerData([]);     
        if (response.status === 200) {  
          if(response.data.length >0) {
            // console.log(response.data);
            // return;
            setCustomerData(response.data);   
            setShowTable(true);       
          }   
          else{
            setCustomerData(sampledata);
            setShowTable(false);
          }    
        }         

      } catch {

      } 

    }  

  }

  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      // if(!defaultcenter){
        const userIdapireq = UserMasterDataGlobal[0]?.userid;
        const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;      
        const apirequest = apireq05042024+`DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;

        axios.get(apirequest)
          .then((response) => {
            try {
              if(!defaultcenter){
                setdefaultroutename(response.data[0].text3);
                setdefaultcenter(response.data[0].text1);
                // console.log('aasssasa');
                setFormData({
                  ...formData,
                  routecode: response.data[0].text3,
                  centerno: response.data[0].text1,
                });
              }
            }
            catch {}     
          })
          .catch((error) => console.error('Error fetching route data:', error));
      }      
    // }
  }, [UserMasterDataGlobal]);

  async function getroutemasterdata(centerid, rr) {
    // console.log(rr);
    var data1 = {
      centerid : centerid,
      CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    // console.log(data1);
    var response1 = await axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
      setdefaultroutename(response1.data[0].routename);
      setRouteMasterData(response1.data);
      fetchmaxrates(centerid, response1.data[0].routename);
    })
  }

  async function  fetchmaxrates(centerid, routecode) {
      
    var axiosreqtoapi = apireq05042024+'MonthlySheet/fetchmaxrates?routecode='+routecode
    +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid;
    
    const response = axios.get(axiosreqtoapi)

    .then((response) => {

        if(response.data.length !== "") {
          setFormData((prevData) => ({
            ...prevData,
            maxcowrate : response.data.MaxCowRate,
            maxbuffrate : response.data.MaxBuffRate,
          }));
          if(response.data.MaxCowRate > 0.0001) {
            setCowRateVisible(true);
          }
          else{
            setCowRateVisible(false);
          }
        }
    })
    .catch((error) => {
        console.error('Error fetching data:', error);
    });
  }

  //Below code is to sort data in the table by customer name ascending descending.
  //***********************************************************************************/

  // const [sortBy, setSortBy] = useState({ column: '', ascending: true });
  const handleSort = (column) => {
    setSortBy((prevSortBy) => ({
      column,
      ascending: prevSortBy.column === column ? !prevSortBy.ascending : true,
    }));
    sortData(column);
  };

  const sortData = (column) => {
    const sortedData = [...CustomerData].sort((a, b) => {
      if (column === 'customer_centerwiseid') {
        return sortBy.ascending
          ? a[column] - b[column]
          : b[column] - a[column];
      } else {
        const comparison = a[column].localeCompare(b[column]);
        return sortBy.ascending ? comparison : -comparison;
      }
    });
    setCustomerData(sortedData);
  };
  //***********************************************************************************/

  function regeneraterecords (event) {
    // function regeneraterecords(event) {
      event.preventDefault();

      var validationerrors = {};

      if(defaultroutename !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 
  
      if(defaultcenter !== '') {
      }else{
        validationerrors.centerno = "Please select prefered center from list or type its number here";
      } 
  
      if(formData.month !== '') {
      }else{
        validationerrors.month = "Please select month & year from month picker";
      } 

      seterrors(validationerrors);
  
      if(Object.keys(validationerrors).length == 0) {

        MySwal.fire({
          title: "Proceeding will delete previous transaction data and generate new data. If Confirm then type 'confirm' in below input box.",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Proceed",
          showLoaderOnConfirm: true,
          preConfirm: async (inputValue) => {
            if (inputValue === 'confirm') {
              try {
                await addbulkentries();
              } catch (error) {
                MySwal.showValidationMessage(`
                  Request failed: ${error}
                `);
              }
            } else {
              MySwal.showValidationMessage('You must type "confirm" to proceed.');
              return false;
            }
          },
          allowOutsideClick: () => !MySwal.isLoading()
        }).then((result) => {
          // console.log(result);
          if (result.isConfirmed) {
            MySwal.fire({
              title: 'Success',
              text: 'Transaction data has been processed.',
              icon: 'success'
            });
          }
        });      
      }
    }

    function deleteallconfirmation1 (event) {
      // function regeneraterecords(event) {
      event.preventDefault();
              
      var validationerrors = {};

      if(defaultroutename !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 
  
      if(defaultcenter !== '') {
      }else{
        validationerrors.centerno = "Please select prefered center from list or type its number here";
      } 
  
      if(formData.month !== '') {
      }else{
        validationerrors.month = "Please select month & year from month picker";
      }
        
      seterrors(validationerrors);
      
      if(Object.keys(validationerrors).length == 0) {
        MySwal.fire({
          title: "Proceeding will delete saved transaction data if it contains any. If Confirm then type 'confirm' in below input box.",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Proceed",
          showLoaderOnConfirm: true,
          preConfirm: async (inputValue) => {
            if (inputValue == 'confirm') {
              try {
                await deleteall1();
              } catch (error) {
                MySwal.showValidationMessage(`
                  Request failed: ${error}
                `);
              }
            } else {
              MySwal.showValidationMessage('You must type "confirm" to proceed.');
              return false;
            }
          },
          allowOutsideClick: () => !MySwal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            MySwal.fire({
              title: 'Success',
              text: 'Records deleted successfully.',
              icon: 'success'
            });
          }
        });      
      }
      
      }
  
    async function addbulkentries() {

      const [year, month] = formData.month.split('-');
      
      var data = {
        centerid: defaultcenter,
        routename: defaultroutename,
        year: year,
        month: month,
        CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
        userid: UserMasterDataGlobal?.[0]?.userid || "",
        cowrate: formData.maxcowrate,
        buffrate: formData.maxbuffrate,
        takemaxrate: ApplyCustomerMasterRates
      };
      // console.log(data);
      // return;
      const response = await axios.post(apireq05042024+'MonthlySheet/Generateinbulk', data);
            
      // setCustomerMasterData([]);
      if (response.status === 200) {
        console.log(response.data);
        if(response.data.message == "Records saved succesfully") {
          MySwal.fire({
            icon: 'success',
            title: 'Successful!',
            text: `${response.data.message}`
          });
          ShowCustomerData();
        }else if (response.data.message == "No results found.") {
          MySwal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: `${response.data.message}`
          });
        }else if (response.data.message == "Failed") {
          MySwal.fire({
            icon: 'error',
            title: 'Failed. Try to run it again.',
            text: `${response.data.message}`
          });
        }
        // setCustomerMasterData(response.data.customer_data);        
      }
      
    }

    async function deleteall1() {

      const [year, month] = formData.month.split('-');

      var response  = "";
      response = await axios.post(apireq05042024+`MonthlySheet/DeleteAllButton/${defaultcenter}/${defaultroutename}/${UserMasterDataGlobal?.[0]?.CompanyId}/${month}/${year}/${UserMasterDataGlobal?.[0]?.userid}`);
      
      // setCustomerMasterData([]);
      if (response.status == 200) {
        if(response.data == "Success"){
          MySwal.fire({
            icon: 'success',
            title: 'Successful!',
            text: 'Records deleted succesfully.'
          });
          ShowCustomerData();  
        } else if(response.data == "Failed"){
          MySwal.fire({
            icon: 'error',
            title: 'Failed',
            text: 'Failed. Try again.'
          });
        } 
      }        
    }

    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
      if (dataLoaded) {
        printContent();
      }
    }, [dataLoaded]);

    async function getMonthlyBillReport() {
      // event.preventDefault();
      var validationerrors = {};

      if(defaultroutename !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 

      if(defaultcenter !== '') {
      }else{
        validationerrors.centerno = "Please select prefered center from list or type its number here";
      } 

      if(formData.month !== '') {
      }else{
        validationerrors.month = "Please select month & year from month picker";
      } 

      if (formData.fromcustomer_centerwiseid !== '') {
        if (formData.tocustomer_centerwiseid == '') {
          validationerrors.tocustomer_centerwiseid = "Please type the value in 'to customer centerwise id' input.";
        } else if (parseInt(formData.fromcustomer_centerwiseid) <= 0) {
          validationerrors.fromcustomer_centerwiseid = "From customer centerwise ID should be a positive number.";
        } else if (parseInt(formData.tocustomer_centerwiseid) <= 0) {
          validationerrors.tocustomer_centerwiseid = "To customer centerwise ID should be a positive number.";
        } else if (parseInt(formData.fromcustomer_centerwiseid) > parseInt(formData.tocustomer_centerwiseid)) {
          validationerrors.fromcustomer_centerwiseid = "From customer centerwise ID should be smaller than to customer centerwise ID.";
        }
      } else if (formData.tocustomer_centerwiseid !== '') {
        if (formData.fromcustomer_centerwiseid == '') {
          validationerrors.fromcustomer_centerwiseid = "Please type the value in 'from customer centerwise id' input.";
        } else if (parseInt(formData.fromcustomer_centerwiseid) <= 0) {
          validationerrors.fromcustomer_centerwiseid = "From customer centerwise ID should be a positive number.";
        } else if (parseInt(formData.tocustomer_centerwiseid) <= 0) {
          validationerrors.tocustomer_centerwiseid = "To customer centerwise ID should be a positive number.";
        }
        
      }

      const [year, month] = formData.month.split('-');

      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {

        var data = {
          centerid: defaultcenter,
          routename: defaultroutename,
          year: year,
          month: month,
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          userid: UserMasterDataGlobal?.[0]?.userid || "",
          fromcustomer_centerwiseid: formData.fromcustomer_centerwiseid,
          tocustomer_centerwiseid: formData.tocustomer_centerwiseid
        };
        // console.log(data);
        // return;
        try {          
          
          // Reset report data before fetching new data
          setCustomerDataPrint([]);
          setDataLoaded(false);

          MySwal.fire({
            title: 'Generating Report',
            text: 'Please wait while the report is being generated.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          var response  = "";

          response = await axios.post(apireq05042024+'MonthlySheet/getMonthlyBillReport', data);
             
          if (response.status === 200) {
            if(response.data.length >0) {
              
              // return;      
              MySwal.close();
              setCustomerDataPrint(response.data);  
              setDataLoaded(true); 
            }   
            else{
              setCustomerDataPrint(sampledata);
            }    
          }         

        } catch {

        } 

      }        
    }

async function printContent() {
  const printWindow = window.open('', '_blank');
  const [year, month] = formData.month.split('-');

  let billsHtml = '';

  CustomerDataPrint.forEach(customer => {
      const customerName = customer.customer_name;
      const days = customer.days;
      const rate_customer_masterwise = customer.rate;
      const firstDay = new Date(year, month - 1, 1).getDay();
      let dayHeaders = '';

      let daysHtml = '';
      let weekHtml = '';

      for (let i = 0; i < 7; i++) {
          const dayIndex = (firstDay + i) % 7;
          const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          dayHeaders += `<th>${dayNames[dayIndex]}</th>`;
      }

      // for (let day = 1; day <= 31; day++) {
      //     if (day % 7 === 1) { 
      //         if (weekHtml) {
      //             daysHtml += `<tr>${weekHtml}</tr>`;
      //         }
      //         weekHtml = '';
      //     }
      //     weekHtml += `<td>${day}<br>${days[day] || `<span style="color: red;">X</span>`}</td>`;
      // }
      // if (weekHtml) {
      //     daysHtml += `<tr>${weekHtml}</tr>`;
      // }

      const daysInMonth = new Date(year, month, 0).getDate(); // Get the number of days in the month

      for (let day = 1; day <= daysInMonth; day++) { // Loop only up to the days in the month
        if (day % 7 === 1) {
          if (weekHtml) {
            daysHtml += `<tr>${weekHtml}</tr>`;
          }
          weekHtml = '';
        }
        weekHtml += `<td>${day}<br>${days[day] || `<span style="color: red;">X</span>`}</td>`;
      }
      if (weekHtml) {
        daysHtml += `<tr>${weekHtml}</tr>`;
      }
      
      billsHtml += `
          <div class="bill_printmonthlybill">
              <header style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <div style="flex: 1;">
                    <span style="font-size: small;"><b>Office Tel: 02024470562 / 24482145</b> </span>
                    <br />
                    <span style="font-size: small;"><b>Mobile: 9699455048/ 8999045862</b> </span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <img src=${process.env.REACT_APP_phadake_group_symbol} alt="Symbol" style="max-width: 50px; max-height: 40px; margin-right: 10px;" />
                    <h2 style="margin: 0;">${UserMasterDataGlobal?.[0]?.companyname}</h2>
                  </div>
                  <div style="flex: 1; text-align: right;">
                      <span style="display: block; font-size: small">1785, SADASHIV PETH</span>
                      <span style="display: block; font-size: small">PUNE - 411 030</span>
                      <span style="display: block; font-size: small"><b>Office time: 9 AM to 7:30 PM</b></span>
                  </div>
              </header>

              <div class="content_printmonthlybill">
                  <div class="section_printmonthlybill calendar_printmonthlybill">
                      <table>
                          <thead>
                              <tr>
                                  <th colspan="7">${getMonthName(month)} ${year}</th>
                              </tr>
                              <tr>
                                  ${dayHeaders}
                              </tr>
                          </thead>
                          <tbody>
                              ${daysHtml}
                          </tbody>
                      </table>
                      <p></p>
                      
                      <span style="font-size: small;">पहाटे संपर्कासाठी - ५ ते ९ </span>
                      <br />
                      <span style="font-size: small;"><b>नं: 02024449896 / 24449897</b></span>
                      <br />
                      <span style="font-size: small;"><b>9699455048</b></span>
                  </div>
                  <div class="section_printmonthlybill summary_printmonthlybill">
                      <p><b style="font-weight: 900">Customer Name</b>: <strong style="text-decoration: underline;">${customerName}</strong></p>
                      <p><b style="font-weight: 900">Center Name</b>: ${getCenterNameById(defaultcenter)}</p>
                      
                      <div style="display: flex; justify-content: space-between;">
                          <p style="margin: 0;"><b style="font-weight: 900">Line No</b>: ${defaultroutename}</p>
                          <p style="margin: 0;"><b style="font-weight: 900">Rate</b>: ${ ApplyCustomerMasterRates == false ?  formData.maxcowrate == "" || Number(formData.maxcowrate) == 0 ? formData.maxbuffrate : formData.maxcowrate : rate_customer_masterwise}</p>
                      </div>
                      <p></p>
                      <div style="display: flex; justify-content: space-between;">
                          <p style="margin: 0;"><b style="font-weight: 900">Total Liters</b>: ${calculateTotalLiters(days)}</p>
                          <p style="margin: 0;"><b style="font-weight: 900">Amount</b>: ${ ApplyCustomerMasterRates == false ? Number(calculateAmount(days)).toFixed(2) : Number(calculateAmount(days, rate_customer_masterwise)).toFixed(2)}</p>
                      </div>
                      <p></p>
                      <div style="display: flex; justify-content: space-between;">
                          <p></p>
                          <p style="margin: 0;">${Number(customer.balance) < 0 ? '<b style="font-weight: 900">Advance</b>: ' : '<b style="font-weight: 900">Arrears</b>: '} ${Number(customer.balance).toFixed(2)}</p>
                      </div>
                      <p></p>
                      <div style="display: flex; justify-content: space-between;">
                          <p></p>
                          <p style="margin: 0;"><b style="font-weight: 900">Total Amount</b>: ${ ApplyCustomerMasterRates == false ? (Number(customer.balance) + Number(calculateAmount(days))).toFixed(2) : (Number(customer.balance) + Number(calculateAmount(days, rate_customer_masterwise))).toFixed(2)}</p>
                      </div>
                      <p></p>
                      <p><b style="font-weight: 900">Next Month Rate</b>: ${Number(formData.nextmonthrate).toFixed(2)}</p>
                     
                      <p style="text-align: center;"><p style="text-align: center;"><img src=${process.env.REACT_APP_phadke_group_payment_qr_code} alt="Symbol" style="max-width: 150px; max-height: 150px;" /></p>
                      <span>[ <b> Note: कृपया QR code फक्त दुध बिल भरण्याकरिता वापरावा. ] </b> </span>
                  </div>
                  <div class="section_printmonthlybill notes_printmonthlybill">
                      <p style="text-align: center; margin:auto; display:table;"><b>* सूचना *</b> </p>
                      <br />
                      <span>१. या बिलाचे पेमेंट, चेकने किंवा ऑनलाईन करता येईल. चेक 'फडके ग्रुप' या नावाने काढावा, चेक क्रॉस करूनच द्यावा.
                      <br />
                      २. कोणत्याही परिस्थितीत बिलाची रोख रक्कम लाइनमन जवळ देऊ नये, रोख रक्कम लाइनमन जवळ दिल्यास त्याची संपूर्ण जबाबदारी ग्राहकाची राहील.
                      <br />
                      ३.  रोखीने अथवा चेकने समक्ष बिल भरणा करताना सोबत बिल घेऊन यावे. बिल दरमहा १० तारखेपर्यंत भरावे.
                      </span>                        
                      
                      <p style="text-align: center; margin:auto; display:table;"><b>* Online Payment Details *</b> </p>
                      <br />
                      <span>* बँक : जनता सहकारी बँक लि. पुणे, बाजीराव रस्ता</span>
                      <br />
                      <span>* खात्याचे नाव : फडके ग्रुप</span>
                      <br />
                      <span>* IFSC Code : JSBP0000098</span>
                      <br />
                      <span>* CC A/C No : 001153100006912</span>
                      <br />
                      <br />
                      <span><b>[ * पेमेंट केल्यानंतर 7391011785 या व्हाटसॅप क्रमांकावर पेमेंटचा स्क्रीनशॉट पाठविणे. ]</b></span>
                  </div>
              </div>
          </div>
          <hr />
      `;
  });

  setDataLoaded(false); 
  printWindow.document.write(`
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Milk Bill</title>          
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
  </head>
  <style>
      @page {
          size: A4;
          margin: 0;
      }

      body {
          margin: 0;
          padding: 0;
          font-family: Arial, sans-serif;
      }

      .bill_printmonthlybill {
          width: 8.5in;
          height: 5.75in; /* Adjusted height for two per page */
          border: 1px solid #000;
          margin-bottom: 10px;
          padding: 5px;
          box-sizing: border-box;
          page-break-inside: avoid;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
      }

      .info_printmonthlybill {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
      }

      .content_printmonthlybill {
          display: flex;
          justify-content: space-between;
      }

      .section_printmonthlybill {
          width: 32%;
          border: 1px solid #000;
          padding: 5px;
          box-sizing: border-box;
          margin-bottom: 10px; /* Added margin-bottom for gap */
      }
      
      .section_printmonthlybill {
        width: 32%;
        border: 1px solid #000;
        padding: 5px;
        box-sizing: border-box;
      }

      .calendar_printmonthlybill table {
          width: 100%;
          border-collapse: collapse;
          table-layout: fixed;
          font-size: 10px;
      }

      .calendar_printmonthlybill th, .calendar_printmonthlybill td {
          border: 1px solid #000;
          text-align: center;
          padding: 2px;
          vertical-align: top;
          height: 25px;
      }

      .summary_printmonthlybill, .notes_printmonthlybill {
          font-size: 12px;
          text-align: left;             
      }

      @media print {
          body {
              margin: 0;
          }

          .bill_printmonthlybill {
              page-break-inside: avoid;
              break-inside: avoid;
          }
      }
  </style>
  <body>
      ${billsHtml}
  </body>
  </html>
  `);

  printWindow.document.close();
}

  
  function calculateTotalLiters(days) {
      let total = 0;
      for (let day in days) {
          if (days[day]) {
              total += parseFloat(days[day]);
          }
      }
      return total;
  }
  
  function calculateAmount(days, rate = null) {
    // console.log(rate);
    if(rate == null) {
      const pricePerLiter = Number(formData.maxcowrate) > Number(formData.maxbuffrate) ? formData.maxcowrate : formData.maxbuffrate;
      // console.log(Number(formData.maxcowrate));
      return calculateTotalLiters(days) * pricePerLiter;
    }
    else{
      const pricePerLiter = Number(rate);
      // console.log(calculateTotalLiters(days) * pricePerLiter);
      return calculateTotalLiters(days) * pricePerLiter;
    }
  }
  
  function getMonthName(monthNumber) {
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames[monthNumber - 1]; // Subtract 1 because array index starts at 0
  }

  return (

    <div className="container-fluid" style={{color: '#4229cb', fontSize:18, fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      {/* <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default', fontSize: 20 }} className="text-center"> Monthly Sheet </h1> */}
        <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: '#4229cb', marginTop:'-8px', padding: 6}}>
            <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <span className="nav-link" style={{cursor: 'default', color: 'white'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
                </li>
            </ul>
            <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
            </div>
        </nav>
      
        <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form onSubmit={handleSubmit} method='get'> 

          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="month" className="form-label">M - Year</label>
              <input type="month" className="form-control" name="month" value={formData.month} onChange={handleInputChange} />
              {errors.month && <span style={{ color: 'red', fontSize: '10px' }}>{errors.month}</span>}
            </div>

            <div className='col-md-2'>
              <input type="hidden" className="form-control" name="centerno" value={defaultcenter} onChange={handleInputChange} />
              <label htmlFor="centerid" className="form-label">Center Name</label>
              <select
                className="form-select"
                name="centerid"
                onChange={handleInputChange}
                
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                }
              >
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                    : null
                ))}
              </select>
              {errors.centerno && <span style={{ color: 'red', fontSize: '10px' }}>{errors.centerno}</span>}
            </div>

            <input type="hidden" className="form-control" name="routecode" value={defaultroutename} onChange={handleInputChange} required />

            <div className="col-md-2">
              <label htmlFor="id-routename" className="form-label">Select Line</label>
              <select
                className="form-select"
                id="id-routename"
                name="routename"
                onChange={handleInputChange}
              >
                {defaultroutename && (
                  <option value={defaultroutename}>
                    {defaultroutename}
                  </option>
                )}

                {RouteMasterData.map((r) => (
                  defaultroutename !== r.routename ?
                    <option key={r.routecode} value={`${r.routecode}-${r.routename}`} >
                      {r.routename}
                    </option>
                    : null
                ))}
              </select>
              {errors.routecode && <span style={{ color: 'red', fontSize: '10px' }}>{errors.routecode}</span>}
            </div>

            <div className="col-md-2">
              {CowRateVisible ? (
                <>
                  <label htmlFor="id-cowrate" className="form-label">Cow rate</label>
                  <input type="number" className="form-control" name="maxcowrate" value={formData.maxcowrate} onChange={handleInputChange} readOnly />
                </>
              ) : (
                <>
                  <label htmlFor="id-buffrate" className="form-label">Buff rate</label>
                  <input type="number" className="form-control" name="maxbuffrate" value={formData.maxbuffrate} onChange={handleInputChange} readOnly />
                </>
              )}

              <input className="form-check-input" type="checkbox" checked={ApplyCustomerMasterRates} onChange={handleApplyCustomerMasterRatesCheckBox} name="ApplyCustomerMasterRates" style={{marginTop: '20px'}} />
              &nbsp;<label className="form-check-label" htmlFor="flexCheckDefault" style={{marginTop: '15px'}}>
                Customer Master Rate
              </label>

            </div>

            <div className="col-md-2">
              <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
            </div>
            
            {
            
            UserMasterDataGlobal?.length > 0 && (
              UserMasterDataGlobal[0].usertype === "ADMIN" || // Use strict equality (===)
              UserMasterDataGlobal[0].usertype === "DAIRY OWNER"
            ) && (
              <div className="col-md-2">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  style={{ marginTop: '32px' }}
                  onClick={(event) => regeneraterecords(event)}
                >
                  Delete and Generate
                </button>
              </div>
            )
          }

            {/* {
            
            UserMasterDataGlobal?.length > 0 && (
              UserMasterDataGlobal[0].usertype === "ADMIN" || 
              UserMasterDataGlobal[0].usertype === "DAIRY OWNER"
            ) && (
              <div className="col-md-2">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  style={{ marginTop: '32px' }}
                  onClick={(event) => regeneraterecords(event)}
                >
                  Delete and Generate
                </button>
              </div>
            )
          } */}
          
          <div className="col-md-2">
            <input className="form-check-input" type="checkbox" checked={isfastforwardqtyentries} onChange={handleCheckboxChange} name="isfastforwardqtyentries" style={{marginTop: '40px'}} />
            {/* <label className="form-check-label" htmlFor="flexCheckDefault" style={{marginTop: '36px', marginLeft: '10px'}}> */}
            &nbsp;<label className="form-check-label" htmlFor="flexCheckDefault" style={{marginTop: '36px'}}>
              Fastforward qty entries
            </label>
          </div>
        
        
        </div>

        </form>
      </div>

      {ShowTable && ( 
        <>
        <div className="table-container" style={{ height: '480px', overflow: 'auto', borderWidth:'0px' }}>
          <table className="table table-bordered" onKeyDown={handleKeyDown} style={{borderCollapse: Collapse}}>
            <thead>
              <tr>
                {/* <th style={{ minWidth: '60px', backgroundColor: '#009879', color:"white", border: '1px solid black', position: 'sticky', top: 0, left:0, zIndex:1  }}>C.No.</th> */}
                
                <th style={{ minWidth: '80px', backgroundColor: '#009879', color:"white", border: '1px solid black', position: 'sticky', top: 0, left:0, zIndex:1 }}>
                  Sr. No
                </th>
                
                <th style={{ minWidth: '80px', backgroundColor: '#009879', color:"white", border: '1px solid black', position: 'sticky', top: 0, left:0, zIndex:1 }} onClick={() => handleSort('customer_centerwiseid')}>
                  C. No {sortBy.column === 'customer_centerwiseid' && (sortBy.ascending ? '↑' : '↓')}
                </th>

                {/* <th style={{ minWidth: '200px', backgroundColor: '#009879', color:"white", border: '1px solid black', position: 'sticky', top: 0, left:0, zIndex:1 }}>Customer Name</th> */}
                <th style={{ minWidth: '200px', backgroundColor: '#009879', color:"white", border: '1px solid black', position: 'sticky', top: 0, left:0, zIndex:1, cursor: 'pointer' }} onClick={() => handleSort('customer_name')}>
                  Customer Name {sortBy.column === 'customer_name' && (sortBy.ascending ? '↑' : '↓')}
                </th>
                
                {[...Array(CustomerData[0].days.length)].map((_, index) => (
                  <th key={index} style={{textAlign: 'center', minWidth: '60px', backgroundColor: '#009879', color:"white", border: '1px solid black', position: 'sticky', top: 0 }}>
                    {index + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {CustomerData.map((item, rowIndex) => (
                <tr key={item.customer_centerwiseid}>
                  <td style={{color: 'black', padding: 0}}>
                    {rowIndex+1 < 10 ? `0${rowIndex+1}` : rowIndex+1}
                  </td>
                  <td style={{color: 'black', padding: 0}}>
                    {item.customer_centerwiseid < 10 ? `0${item.customer_centerwiseid}` : item.customer_centerwiseid}
                  </td>
                  <td style={{padding: 0, position: 'sticky', left: 0}}>
                    <input
                      type="text"
                      value={item.customer_name}
                      onChange={(e) => handleEditCell(item.customer_centerwiseid, 'customer_name', e.target.value)}
                    />
                  </td>
                  {item.days.map((dayValue, colIndex) => (
                    <td
                      key={colIndex}
                      className={activeCell.row === rowIndex && activeCell.col === colIndex ? 'active-cell' : ''}
                      style={{padding: 0}}
                    >
                      <input
                        type="text"
                        value={dayValue}
                        onChange={(e) => handleEditCell(item.customer_centerwiseid, 'days', e.target.value, colIndex)}
                        onKeyPress={(e) => {
                          // Allow only digits [0-9] and '.' character
                          const charCode = e.charCode;
                          if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                              e.preventDefault();
                          }
                        }}
                        style={{ width: '100%',textAlign: 'center' }}
                        ref={(el) => {
                          if (!inputRefs.current[rowIndex]) {
                            inputRefs.current[rowIndex] = [];
                          }
                          inputRefs.current[rowIndex][colIndex] = el;                          
                        }}
                        onFocus={(e) => {
                          setActiveCell({ row: rowIndex, col: colIndex });
                          e.target.select();
                        }}
                        className={activeCell.row === rowIndex && activeCell.col === colIndex ? 'active-input' : ''}
                      />
                    </td>
                  ))}
                </tr>
              ))}

              <tr>
                <td></td>
                <td></td>
                <td style={{ fontWeight: 'bold', position: 'sticky', left: 0 }}>Total</td>
                {CustomerData[0].days.map((_, colIndex) => {
                  const total = CustomerData.reduce((acc, curr) => acc + parseFloat(curr.days[colIndex] || 0), 0);
                  return <td key={colIndex} style={{ fontWeight: 'bold', textAlign: 'center' }}>{total.toFixed(1)}</td>;
                })}
              </tr>

            </tbody>
          </table>
        </div>        

        </>
      )}

      { /*<div className="col-md-2" style={{float: 'right'}}>
        <button
          type="submit"
          className="btn btn-primary w-100"
          style={{ marginTop: '28px' }}
          onClick={(event) => InsertDataToInsert(event)}
        >
          Save Monthly Sheet
        </button>
      </div> */}

      <br /><br />

      <div className='row'>
        {
            
            UserMasterDataGlobal?.length > 0 && (
              UserMasterDataGlobal[0].usertype === "ADMIN" || // Use strict equality (===)
              UserMasterDataGlobal[0].usertype === "DAIRY OWNER"
            ) && (
              <>
              <div className="col-md-2">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  style={{ marginTop: '48px' }}
                  onClick={(event) => deleteallconfirmation1(event)}
                >
                  Delete
                </button>
              </div>

              <div className="col-md-2">
              <button
                type="submit"
                className="btn btn-primary w-100"
                style={{ marginTop: '48px' }}
                onClick={(event) => getMonthlyBillReport(event)}
              >
                Monthly Bill Report
              </button>
              </div>
              <div className="col-md-2" >
                <input type="number" className='form-control' style={{ marginTop: '48px' }} name='nextmonthrate' value={formData.nextmonthrate == "" ? Number(formData.maxcowrate) > Number(formData.maxbuffrate) ? Number(formData.maxcowrate) : Number(formData.maxbuffrate) : formData.nextmonthrate} placeholder='Type next month rate here' onChange={handleInputChange}></input>
              </div>
              
              <div className="col-md-5" style={{ marginTop: '32px' }}>
                <div style={{
                  border: '2px solid #000',
                  padding: '16px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                  <div style={{ flex: '1', position: 'relative', marginRight: '8px' }}>
                    <input
                      type="number"
                      className='form-control'
                      name='fromcustomer_centerwiseid'
                      value={formData.fromcustomer_centerwiseid}
                      placeholder='From Customer No'
                      onChange={handleInputChange}
                    />
                    {errors.fromcustomer_centerwiseid && 
                      <span style={{ color: 'red', fontSize: '10px', position: 'absolute', top: '100%', left: '0' }}>
                        {errors.fromcustomer_centerwiseid}
                      </span>
                    }
                  </div>
                  <div style={{ flex: '1', position: 'relative' }}>
                    <input
                      type="number"
                      className='form-control'
                      name='tocustomer_centerwiseid'
                      value={formData.tocustomer_centerwiseid}
                      placeholder='To Customer No'
                      onChange={handleInputChange}
                    />
                    {errors.tocustomer_centerwiseid && 
                      <span style={{ color: 'red', fontSize: '10px', position: 'absolute', top: '100%', left: '0' }}>
                        {errors.tocustomer_centerwiseid}
                      </span>
                    }
                  </div>
                </div>
              </div>


              </>
            )
          }
        </div>     

      </div>
  );

    
};

export default MonthlySheet;
