import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';

function Company_register() {
    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";

    const MySwal = withReactContent(Swal);
    const [errors, seterrors] = useState({});
    const [CompanyMasterData, setCompanyMasterData] = useState([]);
    useEffect(() => {
        axios.get(apireq05042024+'CompanyMaster')
    
        .then((response) => {
            setCompanyMasterData(response.data.CompanyMasterData);
        })
        .catch((error) => console.error('Error fetching data:', error));

    }, []);

    const [formData, setFormData] = useState({
        CompanyId: '',
        companyname: '',
        address1: '',
        Place: '',
        Tal: '',
        Dist: '',
        Statename: '',
        Country: '',
        mobile1: '',
        owner_name: '',
        emailaddress: '',
        co_startdate: '',
        co_enddate: '',
        operationtype: 'Add Company',
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData(event.target);
        
        var validationerrors = {};
        
        if(formData.get('mobile1').trim() !== '') {
          if(formData.get('mobile1').length !== 10){
            validationerrors.mobile1 = "Please enter a 10 digit valid mobile number.";
          }
        }else{
          validationerrors.mobile1 = "Please enter a valid mobile number.";
        }
    
        seterrors(validationerrors);
    
        if(Object.keys(validationerrors).length == 0) {
              
          const data = {
            CompanyId : formData.get('CompanyId'),
            companyname : formData.get('companyname'),
            address1 : formData.get('address1'),
            Place : formData.get('Place'),
            Tal : formData.get('Tal'),
            Dist : formData.get('Dist'),
            mobile1 : formData.get('mobile1'),
            owner_name : formData.get('owner_name'),
            Statename : formData.get('Statename'),
            Country : formData.get('Country'),
            emailaddress : formData.get('emailaddress'),
            co_startdate : formData.get('co_startdate'),
            co_enddate : formData.get('co_enddate'),
            operationtype : formData.get('operationtype'),
          };

          if(data.operationtype == 'Add Company'){
            const response = await axios.post(apireq05042024+'CompanyMaster/insert_company', data);

            if(response.status == 200) {
              if(!isNaN(+response.data)){
                MySwal.fire({ 
                  //   title: <strong>Record Saved Sucessfully!</strong>,
                  html: <b> Record saved successfully  </b>,
                  icon: 'success'
                })            
                const newDataItem = {
                  ...data,
                  CompanyId: response.data,
                };
                setCompanyMasterData((prevData) => {
                  return [newDataItem, ...prevData];
                });
                emptyinputs()
              } 
              else if(response.data == "mobile1_present") {
                MySwal.fire({ 
                  html: <b> Please enter another mobile no as this mobile no already present in the Database.
                        </b>,
                  icon: 'warning'
                })
              }
              else if(response.data == "emailaddress_present") {
                MySwal.fire({ 
                  html: <b> Please enter another email address as this email address already present in the Database.
                        </b>,
                  icon: 'warning'
                })
              }
            }
          }
          else {
    
            const response = await axios.post(apireq05042024+'CompanyMaster/update', data);

            if(response.status == 200) {
              if(response.data=="Sucesss"){
                MySwal.fire({
                //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>Record updated Sucessfully!</b>,
                icon: 'success'
                })
    
                setCompanyMasterData((prevData) => {
                  const updatedData = [...prevData];
                  const index = updatedData.findIndex((record) => record.CompanyId == data.CompanyId);
    
                  if (index !== -1) {
                    updatedData[index] = { ...updatedData[index], ...data };
                  }                  
                  console.log(updatedData);
                  return updatedData;
                });
                emptyinputs()
              }
            }
    
          }
    
        }
        else {
          console.log("has validation errors...");
        }

      }
    
      function emptyinputs(){
        setFormData({
          CompanyId: '',
          companyname: '',
          address1: '',
          Place: '',
          Tal: '',
          Dist: '',
          Statename: '',
          Country: '',
          mobile1: '',
          owner_name: '',
          emailaddress: '',
          co_startdate: '',
          co_enddate: '',
          operationtype: 'Add Company',
        });
      }
      
      function getdatabyrecordarray(record) {
        // console.log(record);
        setFormData({
          CompanyId: record.CompanyId,
          companyname: record.companyname,
          address1: record.address1,
          Place: record.Place,
          Tal: record.Tal,
          Dist: record.Dist,
          Statename: record.Statename,
          Country: record.Country,
          mobile1: record.mobile1,
          owner_name: record.owner_name,
          emailaddress: record.emailaddress,
          co_startdate: record.co_startdate,
          co_enddate: record.co_enddate,
          operationtype: 'Edit Company',
        });
        
        // following code is to go to top of the page after clicking on edit button.
        window.scrollTo(0, 0)
      }

      function confirmDelete(event, id) {
        event.preventDefault();
    
        MySwal.fire({
            title: 'Are you sure you want to delete the record?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                // window.location.href = $(this).attr('href');
                deleteData(id);
                }
            }
        })
      }
    
      async function deleteData(CompanyId) {
    
        const response = await axios.delete(apireq05042024+`Companymaster/delete/${CompanyId}`);
    
        if (response.status === 200) {
            if(response.data==="Sucessfully deleted") {
                MySwal.fire({
                //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>Record deleted Sucessfully!</b>,
                icon: 'success'
                })
                setCompanyMasterData((prevData) => prevData.filter((record) => record.CompanyId !== CompanyId));
            }
      } else {
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>Record not deleted! Please try once again or call the developer</b>,
                icon: 'error'
            })
        }
      }

      const [currentPage, setCurrentPage] = useState(1);
      const recordsPerPage = 5;
      const lastIndex = currentPage * recordsPerPage;
      const firstIndex = lastIndex - recordsPerPage;
      const records = CompanyMasterData.slice(firstIndex, lastIndex);
      const npage = Math.ceil(CompanyMasterData.length / recordsPerPage);
      //const numbers = [...Array(npage + 1).keys()].slice(1);
    
      const getVisiblePageNumbers = (currentPage, totalPages) => {
        // Handle cases with 5 or fewer pages
        if (totalPages <= 5) {
          return [...Array(totalPages + 1).keys()].slice(1);
        }
    
        // Handle cases with more than 5 pages
        const visiblePages = [1, 2, currentPage, totalPages - 1, totalPages];
        let gap = false;
        const finalNumbers = [];
    
        for (let i = 1; i <= totalPages; i++) {
          if (visiblePages.includes(i)) {
            finalNumbers.push(i);
            gap = false;
          } else if (!gap) {
            finalNumbers.push('...');
            gap = true;
          }
        }
        return finalNumbers;
      };
    
      const numbers = getVisiblePageNumbers(currentPage, npage);
    
    return (
        <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
            <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', fontSize: 20, cursor: 'default' }} className="text-center"> Company Master </h1>
            <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
                <a className="navbar-brand" style={{color: 'rgb(230 238 247)', cursor: 'default'}} >Home</a>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <span className="nav-link" style={{cursor: 'default'}}>Welcome Super Admin</span>
                    </li>
                </ul>
                <a className="navbar-brand" style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
                </div>
            </nav>
        
            <div className='mt-4'>
            <form onSubmit={handleSubmit} method="POST" id="form1">
                <div className="mb-3 row">
                    <input type="hidden" className="form-control" id="CompanyId" name='CompanyId' value={formData.CompanyId} onChange={handleInputChange} required/>     
                    <input type="hidden" className="form-control" id="operationtype" name='operationtype' value={formData.operationtype} onChange={handleInputChange} required/>     
                    <div className='col-md-2'>
                        <label htmlFor="companyname" className="">Company Name</label>          
                        <input type="text" className="form-control" id="companyname" name='companyname' value={formData.companyname} onChange={handleInputChange} required/>     
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="address1" className="">Address</label>          
                        <input type="text" className="form-control" id="address1" name='address1' value={formData.address1} onChange={handleInputChange} required/>      
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="Place" className="">Place</label>          
                        <input type="text" className="form-control" id="Place" name='Place' value={formData.Place} onChange={handleInputChange} required/>      
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="Tal" className="">Tal</label>          
                        <input type="text" className="form-control" id="Tal" name='Tal' value={formData.Tal} onChange={handleInputChange} required/>      
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="Dist" className="">Dist</label>          
                        <input type="text" className="form-control" id="Dist" name='Dist' value={formData.Dist} onChange={handleInputChange} required/>      
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="Statename" className="">State</label>          
                        <input type="text" className="form-control" id="Statename" name='Statename' value={formData.Statename} onChange={handleInputChange} required/>      
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className='col-md-2'>
                        <label htmlFor="Country" className="">Country</label>          
                        <input type="text" className="form-control" id="Country" name='Country' value={formData.Country} onChange={handleInputChange} required/>      
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="mobile1" className="">Mobile no</label>          
                        <input type="text" className="form-control" id="mobile1" name='mobile1' value={formData.mobile1} onChange={handleInputChange} required/>      
                        {errors.mobile1 && <span style={{color: 'red', fontSize: '10px'}}>{errors.mobile1}</span>}
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="owner_name" className="">Owner name</label>          
                        <input type="text" className="form-control" id="owner_name" name='owner_name' value={formData.owner_name} onChange={handleInputChange} required/>      
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="emailaddress" className="">Email</label>          
                        <input type="text" className="form-control" id="emailaddress" name='emailaddress' value={formData.emailaddress} onChange={handleInputChange} required/>      
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="co_startdate" className="">Start Date</label>          
                        <input type="date" className="form-control" id="co_startdate" name='co_startdate' value={formData.co_startdate} onChange={handleInputChange} required/>      
                    </div>
                    <div className='col-md-2'>
                        <label htmlFor="co_enddate" className="">End Date</label>          
                        <input type="date" className="form-control" id="co_enddate" name='co_enddate' value={formData.co_enddate} onChange={handleInputChange} required/>      
                    </div>
                </div>
                
                <div className="mb-3 row">
                    <div className='offset-md-10 col-md-2'>
                        <button type="submit" className="btn btn-primary w-100" style={{marginTop: 25}}>
                            {formData.operationtype == 'Add Company' ? 'Add Company' : 'Edit Company'}
                        </button>
                    </div>
                </div>
                </form>
            </div>


            <div className="row mt-4">
                <div className="col-md-12 table-responsive-xl">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th style={{backgroundColor:'#009879', color:"white"}}>Company Id</th>
                        <th style={{backgroundColor:'#009879', color:"white"}}>Company Name</th>
                        <th style={{backgroundColor:'#009879', color:"white"}}>Sub Database</th>
                        <th style={{backgroundColor:'#009879', color:"white", textAlign: 'center'}}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {records.map((u, index) => (
                    <tr key={u.CompanyId}>
                        <td>{u.CompanyId}</td>
                        <td>{u.companyname}</td>
                        <td>{u.subdatabase}</td>
                        <td style={{textAlign: 'center'}}>
                        <button onClick={() => getdatabyrecordarray(u)} className="btn btn-info btn-sm">Edit</button>
                        &nbsp;<button onClick={(event) => confirmDelete(event, u.CompanyId)} className="btn btn-danger btn-sm ml-1">
                        Delete
                        </button>
                        </td>
                    </tr>
                    ))}
                    </tbody>
                </table>
                
                <nav style={{float: 'right' }}>
                    <ul className='pagination'>
                        <li className='page-item'>
                        <a href='#' className='page-link'
                        onClick={prePage}>Prev</a>
                        </li>
                        
                        {
                        numbers.map((n, i) => (
                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                            {n === '...' ? (
                            <span className="page-link disabled">...</span>
                            ) : (
                            <a href="#" className="page-link" onClick={() => ChangeCpage(n)}>
                                {n}
                            </a>
                            )}
                        </li>
                        ))}

                        <li className='page-item'>
                        <a href='#' className='page-link'
                        onClick={nextPage}>Next</a>
                        </li>
                    </ul>
                </nav>

                </div>
            </div>
        </div>
    );

    function prePage() {
        if(currentPage !== 1) {
          setCurrentPage(currentPage - 1)
        }
      }
    
      function ChangeCpage(id) {
        setCurrentPage(id)
      }
    
      function nextPage() {
        if(currentPage !== npage) {
          setCurrentPage(currentPage + 1)
        }
      }

}

export default Company_register;
