import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';

function DailyDeliverySheet() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      if(name == "centerid") {
        const [centerid, centername] = value.split('-');
        updatedFormData.centerno = centerid;
        setdefaultcenter(centerid);
        // getroutemasterdata(centerid, 'd6');
        getroutemasterdatabycenterid(centerid, "centername");
      }

      if(name == "routename") {
        const [routecode, routename] = value.split('-');
        // updatedFormData.routecode = routecode;
        setdefaultroutename(routecode);
        // fetchcustomer_name_data(formData.centerid, routecode, formData.bookdate);
      }

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      centerno: '',
      routecode: '',
      bookdate: '',
      centername: '',
      routename: '',
      centerid: '',
      customer_centerwiseid: '',
      customer_name: '',
      transactionid: '',
      customer_status: '',
      outstanding_above: ''
    });

    const [defaultcenter, setdefaultcenter] = useState();
    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          if(!defaultcenter) {
            setCenterMasterData(response.data.CenterMasterData);
            setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
            setFormData({
              ...formData,
              centerno: UserMasterDataGlobal?.[0]?.centerid,
              bookdate: getCurrentDate(),
            })
            getroutemasterdata(UserMasterDataGlobal?.[0]?.centerid, '2d');
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const getCenterNameById = (centerid) => {
      const center = CenterMasterData.find(center => center.centerid === centerid);
      return center ? center.centername : '';
    };

    const [defaultroutename, setdefaultroutename] = useState();
    const [RouteMasterData, setRouteMasterData] = useState([]);

    const routecodeonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;
  
      if (value.includes('-')) {
        // Split the value based on '-'
        const [routecode, routename] = value.split('-');
        value = routecode;
      }
  
      if(value == ''){
        return;
      }
  
      const response = await axios.get(apireq05042024+'RouteMaster/getroutenamebyroutecode?routecode='+value+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)
   
      .then((response) => {
        // Access the response data directly using response.data       
        try {
          // formData.routename = response.data.customer_name_data[0].routename;
          setdefaultroutename(response.data.routenamedata[0].routename);
        }
        catch{

        }
        
      });   
    };
  
    const centernoonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;

      if(value == ''){
        return;
      }
      setdefaultcenter(value);
      
      // formData.centerid = value;
      // setFormData((prevData) => ({
      //   ...prevData,
      //   centerid : value,
      // }));
      setFormData({
        ...formData,
        centerid : value,
      })
      // return;
      getroutemasterdatabycenterid(value);
      // fetchcustomer_name_data(value,defaultroutename, formData.bookdate); 
    };

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        bookdate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      temporaryvalues_table();
      
  }

  async function temporaryvalues_table(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(defaultroutename !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 

      if(formData.centerno !== '') {
      }else{
        validationerrors.centerno = "Please select prefered center from list.";
      } 

      if(formData.bookdate !== '') {
      }else{
        validationerrors.bookdate = "Please select date from datepicker.";
      } 
      
      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {
      
        var data = {
          // centerno: defaultcenter || (UserMasterDataGlobal?.[0]?.centerid || ""),
          centerno:formData.centerno,
          routecode: defaultroutename,
          // routecode : formData.routecode ,
          bookdate: formData.bookdate || getCurrentDate(),
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          userid: UserMasterDataGlobal?.[0]?.userid || "",
          customer_status: formData.customer_status !== "" ?  formData.customer_status : "ALL",
          outstanding_above: formData.outstanding_above
        };
        // alert(data.routecode + " at step1");
       
        setFormData({
          ...formData,
          centerno: data.centerno,
          routecode: data.routecode,
          bookdate: data.bookdate          
        });
        // alert(data.routecode + " at step2");
        // return;
        setdefaultcenter(data.centerno);
        setdefaultroutename(data.routecode);

        try {
          var response  = "";
          if(toshowcustomermasterdata !== "notshow") {
            response = await axios.post(apireq05042024+'Reports/OutStandingReport', data);
            setCustomerMasterData([]);  
            if (response.status === 200) {
                console.log(response.data);
                // return;
                setCustomerMasterData(response.data);                   
            }   
          }
        } catch {
        
        } 
      }  
  }

  const { state } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state) {
          setFormData({
            ...formData,
            centerno: state?.centerid,
            routecode: state?.routecode,
            bookdate: state?.transdate,
          });  
          // formref.current.submit(); 
          // console.log('s');
          // if (buttonref.current) {
          Swal.fire("Click on view button to see the latest record added!");
          // }
        }

      } catch (error) {
        console.error('Error fetching data for editing:', error);
      }
    };

    fetchData();
  }, [state]);
    
  // const [temporaryvalues_table_data, settemporaryvalues_table_data] = useState([]);
  
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      
      const userIdapireq = UserMasterDataGlobal[0]?.userid;
      const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;
      
      const apirequest = apireq05042024+`DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;

      axios.get(apirequest)
        .then((response) => {
          try {
            if(!defaultcenter) {
              setdefaultroutename(response.data[0].text3);
              setdefaultcenter(response.data[0].text1);

              setFormData({
                ...formData,
                routecode: response.data[0].text3,
                centerno: response.data[0].text1,
                bookdate: response.data[0].text5,
              });
              // fetchcustomer_name_data(response.data[0].text1, response.data[0].text3, response.data[0].text5);
            }
          }
          catch {

          }          

        })

        .catch((error) => console.error('Error fetching route data:', error));
    }
  }, [UserMasterDataGlobal]);

  async function getroutemasterdata(centerid, rr) {
    // console.log(rr);
    var data1 = {
      centerid : centerid,
      CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    // console.log(data1);
    var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
      setdefaultroutename(response1.data[0].routename);
      setRouteMasterData(response1.data);
    })
  }

  //this is the array mounted for populating customer_name list in its selectbox
  const [customer_name_data,  setcustomer_name_data] =  useState([]);

    //below function is for putting data in customer_name_data array
    async function  fetchcustomer_name_data(centerid, routecode, bookdate) {
      
      setcustomer_name_data([]);

      var axiosreqtoapi = apireq05042024+'RouteMaster/getCustomerDataWithCenterNoAndRouteCode?routecode='+routecode
      +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid+'&bookdate='+bookdate;
      
      const response = axios.get(axiosreqtoapi)

      .then((response) => {
          if(response.data.customer_name_data.length > 0) {
              setcustomer_name_data(response.data.customer_name_data);
          }
      })
      .catch((error) => {
          console.error('Error fetching data:', error);
      });
    }

    //to get the data from centerid/centerno we use this function
    async function getroutemasterdatabycenterid(centerid, rr=null) {
      var data1 = {
          centerid : centerid,
          CompanyId : UserMasterDataGlobal[0]?.CompanyId,
      }
      var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
      .then((response1) => {
          setdefaultroutename(response1.data[0].routename);
          setRouteMasterData(response1.data);
          if(rr=="centername") {
              //populating customer_name list using customer_name_data array by calling below function.
              //i put below function here because when page first time renders and we try to change
              //centername from selectbox. it is trying to take routename of previous center.
              // fetchcustomer_name_data(centerid,response1.data[0].routename, formData.bookdate);
          }
      })
  }

    //These variables declared here to show Total of Qty and Amt at the last of table.
    var TotalOutStanding =0;
    var TotalCustomerCount =0;

  async function printContent (event) {
    event.preventDefault();

    await temporaryvalues_table();
    
    const printWindow = window.open('', '_blank');

    const bookDate = formData.bookdate;

    // Split the date into year, month, and day
    const parts = bookDate.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    // Create a new date object with the parts
    const date = new Date(year, month - 1, day);

    // Get the day, month, and year
    const formattedDate = `${day}/${month}/${year}`;

    const totalCustomers = CustomerMasterData.filter(c => Number(c.clbal).toFixed(2) !== '0.00').length;
    const totalClbal = CustomerMasterData.reduce((total, customer) => {
      const clbal = Number(customer.clbal);
      return clbal !== 0.00 ? total + clbal : total;
    }, 0).toFixed(2);

    printWindow.document.write(`
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
    <div class="table-container col-md-12 ">
      <h1 style="color: red; font-size: 30px; font-weight: bold; margin-top: 5px; text-align: center;">Outstanding Report</h1>
      <h1 style="color: red; font-size: 20px; font-weight: bold; margin-top: 20px; text-align: center;">Till Date - ${formattedDate}</h1>
      
      <span style="text-align: left; font-size: large;">Center Name - ${getCenterNameById(formData.centerno)}</span>
      <br>
      <span style="text-align: left; font-size: large;">Line No - ${formData.routecode}</span>

      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Customer Id</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle">Customer Name</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Last month outstanding</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Total receipt</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Outstanding</th>
            <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Mobile No</th>
          </tr>
          </thead>
          <tbody>
          ${CustomerMasterData.filter(c => Number(c.clbal).toFixed(2) !== '0.00').map((c, index) => `
          <tr key=${index}>
            <td style="text-align: right">${c.customer_centerwiseid}</td>
            <td>${c.customer_name}</td>
            <td style="text-align: right">${Number(c.last_month_clbal).toFixed(2)}</td>
            <td style="text-align: right">${Number(c.total_cramt).toFixed(2)}</td>
            <td style="text-align: right">${Number(c.clbal).toFixed(2)}</td>
            <td style="text-align: right">
              ${c.customer_mobile === 'NULL' || c.customer_mobile === '' || c.customer_mobile == 0 || c.customer_mobile == '0000000000' ? '' : c.customer_mobile}
            </td>
          </tr>          
        `).join('')}
          <tr>
            <td colspan="3" style="text-align: center; font-weight: bold">Total Customers: ${totalCustomers}</td>
            <td colspan="3" style="text-align: center; font-weight: bold">Total Outstanding: ${totalClbal}</td>
          </tr>
        </tbody>
      </table>
    </div>
  `);
  

    printWindow.document.close();

  };

    return(
            
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default', fontSize: 20 }} className="text-center"> Outstanding list</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

            <div className="row mb-2">
              <div className="col-md-2">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="text" className="form-control" name="centerno" id="id-centerno" value={formData.centerno} onChange={handleInputChange}
                onBlur={centernoonblur} required 
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                }/>

                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
              </div> 

              <div className="col-md-2">
                <label htmlFor="centerid" className="form-label">Select Center</label>

                <select
                  className="form-select"
                  name="centerid" // <-- should match `formData.uom1` property
                  value={defaultcenter}
                  onChange={handleInputChange}
                  
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                {/* uom option from selected record when available */}
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>
              </div>

              {/* <div className="col-md-2"> */}
                {/* <label htmlFor="id-routecode" className="form-label">Line No </label> */}
                <input type="hidden" className="form-control" name="routecode" value={defaultroutename} onChange={handleInputChange} 
                onBlur={routecodeonblur} required />
                
              {/* </div> */}

              <div className="col-md-1">
                <label htmlFor="id-routename" className="form-label">Select Line</label>
                <select
                  className="form-select"
                  id="id-routename" 
                  name="routename"
                  // value={formData.routename}
                  onChange={handleInputChange}
                >

                {defaultroutename && (
                  <option value={defaultroutename}>
                    {defaultroutename}
                  </option>
                )}

                {RouteMasterData.map((r) => (
                  defaultroutename !== r.routename ?
                  <option key={r.routecode} value={`${r.routecode}-${r.routename}`} >
                    {r.routename}
                  </option>
                    : null
                ))}
                </select>
                {errors.routecode && <span style={{color: 'red', fontSize: '10px'}}>{errors.routecode}</span>}
              </div>

              <div className="col-md-2">
                <label htmlFor="id-bookdate" className="form-label">Till Date</label>
                <input type="date" className="form-control" name="bookdate" id="id-bookdate" value={formData.bookdate} onChange={handleInputChange} required/>
                {errors.bookdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.bookdate}</span>}
              </div>

              <div className="col-md-1">
                <label htmlFor="customer_status" className="form-label">Status</label>
                <select className='form-select' name='customer_status' onChange={handleInputChange}>
                    <option value='ALL'>ALL</option>
                    <option value='ACTIVE'>ACTIVE</option>
                    <option value='DEACTIVE'>DEACTIVE</option>
                </select>
              </div>
              
              <div className="col-md-2">
                <label htmlFor="outstanding_above" className="form-label">Outstanding above</label>
                <input type="number" className="form-control" name="outstanding_above" id="id-outstanding_above" value={formData.outstanding_above} onChange={handleInputChange} required/>
                {errors.outstanding_above && <span style={{color: 'red', fontSize: '10px'}}>{errors.outstanding_above}</span>}
              </div>

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
                {/* <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} >View</button> */}
              </div>

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={printContent}>Print</button>
              </div>
               
            </div> 
          </form>
      </div>


      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'right'}}>Customer Id</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'left'}}>Customer Name</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Last Month Outstanding</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Total Receipt</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'center'}}>Outstanding</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '9%', textAlign: 'center'}}>Mobile No</th>
                </tr>
              </thead>
              <tbody>
              {CustomerMasterData.filter(c => Number(c.clbal).toFixed(2) !== '0.00').map((c, index) => {
                TotalOutStanding = Number(TotalOutStanding) + Number(c.clbal);
                TotalCustomerCount = TotalCustomerCount + 1;
                return (
                  <tr key={c.customer_mp_id}>
                    <td style={{textAlign: 'right'}}>{c.customer_centerwiseid}</td>
                    <td style={{textAlign: 'left'}}>{c.customer_name}</td>
                    <td style={{textAlign: 'center'}}>{Number(c.last_month_clbal).toFixed(2)}</td>
                    <td style={{textAlign: 'center'}}>{Number(c.total_cramt).toFixed(2)}</td>
                    <td style={{textAlign: 'center'}}>{Number(c.clbal).toFixed(2)}</td>
                    <td style={{ textAlign: 'center' }}>
                      {c.customer_mobile === 'NULL' || c.customer_mobile === '' || c.customer_mobile == 0 || c.customer_mobile == '0000000000' ? '' : c.customer_mobile}
                    </td>

                  </tr>
                );
              })}

              </tbody>
            </table>
          </div>

          <div className="row mb-3">

             <div className='col-md-3 offset-md-2' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: 'rgb(41 113 203)', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total Customers = {TotalCustomerCount}</span>
            </div>
            <div className='col-md-3 offset-md-1' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: 'rgb(255, 12, 12)', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total outstanding = {Number(TotalOutStanding).toFixed(2)}</span>
            </div>
          </div>

        </div>
      </div>

    </div>

    );

}

export default DailyDeliverySheet;