import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function CenterMaster() {
    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }
  
      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds
  
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);

    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;
  
        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)
  
      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);
  
    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
      const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
      localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
      navigate('/loginpage');
    }

    //Below code is for making these page only accessible  to authorized users i.e., admin and manager.
    useEffect(() => {
        if(UserMasterDataGlobal.length>0){
            if (
                UserMasterDataGlobal?.[0].usertype !== "ADMIN" &&
                UserMasterDataGlobal?.[0].usertype !== "DAIRY OWNER"
            ) {
                navigate("/");
            }
        }
    }, [UserMasterDataGlobal]);

    const [formData, setFormData] = useState({
        centerid: '',
        centername: '',
        operationtype: 'Save',
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        var validationerrors = {};

        if(formData.get('centername').trim() !== '') {
        }else{
          validationerrors.centername = "Please enter a valid centername.";
        }   

        seterrors(validationerrors);
        
        if(Object.keys(validationerrors).length == 0) {
            // alert('no validation message');
            // return;
            var data = {
              CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
              centerid: formData.get('centerid'),
              centername: formData.get('centername'),
              operationtype: formData.get('operationtype'),
            }

            if(data.operationtype == 'Save'){
        
                const response = await axios.post(apireq05042024+'CenterMaster/insert', data);
        
                if (response.status === 200) {

                    if(!isNaN(+response.data)){
                        MySwal.fire({
                          html: <b>Record Saved Sucessfully!</b>,
                          icon: 'success'
                        })
                        const newDataItem = {
                          ...data,
                          centername: data.centername,
                          centerid: response.data,
                        };
                
                        setCenterMasterData((prevData) => {
                          return [newDataItem, ...prevData];
                        });
                        
                        emptyinputs();
                    } else if (response.data==="c_present"){
                      
                        MySwal.fire({ 
                        //   title: <strong>Record Saved Sucessfully!</strong>,
                          html: <b>  This Center name already present. can't save this record...</b>,
                          icon: 'warning'
                        })
                      
                    }
                }

            }
            else {

                const response = await axios.post(apireq05042024+'CenterMaster/update', data);
        
                if (response.status === 200) {
                
                    if(response.data.message == "Sucesss"){
                        
                        MySwal.fire({
                            //   title: <strong>Record Saved Sucessfully!</strong>,
                            html: <b>Record updated Sucessfully!</b>,
                            icon: 'success'
                        })
                        
                        setCenterMasterData((prevData) => {
                          const updatedData = [...prevData];
                          const index = updatedData.findIndex((record) => record.centerid == data.centerid);
        
                          if (index !== -1) {
                            updatedData[index] = { ...updatedData[index], ...data };
                          }           
                            
                          return updatedData;
                        });
                        // console.log(CustomerMasterData);
                        emptyinputs();
                    } 

                }

            }

        }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [errors, seterrors] = useState({});
    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          // Access the response data directly using response.data
          if(!defaultcenter){
            setCenterMasterData(response.data.CenterMasterData);
            if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
              setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
              setFormData({
                ...formData,
                centerid: UserMasterDataGlobal?.[0]?.centerid,
              })
            }
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    function confirmDelete(event, id) {
        event.preventDefault();
  
        MySwal.fire({
            title: 'Are you sure you want to delete the record?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                // window.location.href = $(this).attr('href');
                deleteData(id);
                }
            }
        })
    }
  
    async function deleteData(id) {

      const response = await axios.delete(apireq05042024+`CenterMaster/delete/${id}/${UserMasterDataGlobal?.[0]?.CompanyId}`);

      if (response.status === 200) {
        // The record was deleted successfully.
        // console.log('Record deleted successfully!');
          if(response.data==="Sucessfully deleted") {
              MySwal.fire({
              //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>Record deleted Sucessfully!</b>,
              icon: 'success'
              })
              setCenterMasterData((prevData) => prevData.filter((record) => record.centerid !== id));
          } else if(response.data == "found") {
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>This center has transactions; so can't delete this account</b>,
                icon: 'warning'
            })        
          } 
    } else {
          MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>Record not deleted! Please try once again or call the developer</b>,
              icon: 'error'
          })
      }
    }

    const [defaultcenter, setdefaultcenter] = useState();
    
    function getdatabyrecordarray(record) {
        
        setFormData({
            centerid: record.centerid,
            centername: record.centername,
            operationtype: 'Edit',
        });
        // following code is to go to top of the page after clicking on edit button.
        window.scrollTo(0, 0)
    }

    function emptyinputs(){
        setFormData({
            centerid: '',
            centername: '',
            operationtype: 'Save',
        });
    }

    return (
        
    <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', fontSize: 20, cursor: 'default' }} className="text-center"> Center Master</h1>
        <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
        </nav>

    <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        <div className="col-md-6 offset-md-3">
            <form onSubmit={handleSubmit} method="POST" id="form1">
                <div className="row mb-3">
                    <div className="col-md-3">
                        <input type="hidden" className="form-control" name="centerid" value={formData.centerid}  onChange={handleInputChange}
                        placeholder='Type here' required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="centername" className="form-label">Center Name</label>
                        <input type="text" className="form-control" name="centername" value={formData.centername}  onChange={handleInputChange}
                        placeholder='Type here' required />
                        {errors.centername && <span style={{color: 'red', fontSize: '10px'}}>{errors.centername}</span>}
                    </div>

                    <input type="hidden" className="form-control" name="operationtype" value={formData.operationtype}  onChange={handleInputChange}
                    required />

                    <div className="col-md-4">
                        <button type="submit" className="btn btn-primary" style={{marginTop: 29, height: 42}}>{formData.operationtype == 'Save' ? 'Save' : 'Edit'}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>


    <div className="row mt-4">
    <div className="col-md-12 table-responsive-xl">
        <table className="table table-striped">
            <thead>
                <tr>
                <th style={{backgroundColor:'#009879', color:"white"}}>Center No</th>
                <th style={{backgroundColor:'#009879', color:"white"}}>Center Name</th>
                <th style={{backgroundColor:'#009879', color:"white", textAlign: 'center'}}>Action</th>
                </tr>
            </thead>
            <tbody>
                {CenterMasterData.map((c, index) => (
                <tr key={c.centerid}>
                <td>{c.centerid}</td>
                <td>{c.centername}</td>
                <td style={{textAlign: 'center'}}>
                    <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-sm">Edit</button>
                    {/* <Link to={generatePath(routes.customerDetails, { customerid1: c.customer_mp_id })}  className="btn btn-success btn-sm">edit</Link> */}
                    &nbsp;<button onClick={(event) => confirmDelete(event, c.centerid)} className="btn btn-danger btn-sm ml-1">
                    Delete
                    </button>
                </td>
                </tr>
                ))}
            </tbody>
        </table>       


    </div>
    </div>
    </div>

    );

}

export default CenterMaster;
