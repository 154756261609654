import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './styles/navbar1.css';

function NavBarGlobal() {

    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  
    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}&getCompanyName=true`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }
  
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds
  
      return () => clearInterval(intervalId);
  
    }, [usernameglobal]); // Dependency array includes usernameglobal
  
    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {        
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };
  
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices
  
        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);
  
    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;
  
        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)
  
      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    
async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
}  

return(
    <Navbar style={{ marginTop: -8, marginBottom: 18, color: 'white'}} expand="lg" bg="dark" variant='dark'>
    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
    <Navbar.Collapse id="basic-navbar-nav" >
        <Nav className="mr-auto">
        <NavDropdown title="Master" id="basic-nav-dropdown" >
            <NavDropdown.Item href="/CustomerMaster" >Customer Master</NavDropdown.Item>
            <NavDropdown.Item href="/ProductMaster">Product Master</NavDropdown.Item>
            <NavDropdown.Item href="/BankMaster">Bank Master</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Transaction" id="basic-nav-dropdown">
            {/* <NavDropdown.Item href="/DailyDeliverySheet">Daily Order Sheet</NavDropdown.Item> */}
            {/* <NavDropdown.Item href="/Test2">Sales</NavDropdown.Item> */}
            {/* <NavDropdown.Item href="/CustomerWiseSalesEntryCard">Customer Card Alteration</NavDropdown.Item> */}
            <NavDropdown.Item href="/MonthlySheet">Monthly Order Sheet</NavDropdown.Item>
            <NavDropdown.Item href="/Receipt">Receipt</NavDropdown.Item>            
        </NavDropdown>
        {UserMasterDataGlobal?.[0]?.usertype === "ADMIN" && (
        <NavDropdown title="Admin" id="basic-nav-dropdown">
            <NavDropdown.Item href="/registration">Registration</NavDropdown.Item>
            <NavDropdown.Item href="/CenterMaster">Center Master</NavDropdown.Item>
            <NavDropdown.Item href="/RouteMaster">Line Master</NavDropdown.Item>
            <NavDropdown.Item href="/HealthCheck_parth1">Health CheckUp</NavDropdown.Item>
        </NavDropdown>
        )}      
        <NavDropdown title="Reports" id="basic-nav-dropdown">
            <NavDropdown.Item href="/OutStandingReport">OutStanding List</NavDropdown.Item>
            <NavDropdown.Item href="/DailySummaryReport">Daily Summary</NavDropdown.Item>
            <NavDropdown.Item href="/MonthlyCustomerWiseReport">Monthly CustomerWise Report</NavDropdown.Item>
            <NavDropdown.Item href="/ChequeTransactionReport">Transaction Report</NavDropdown.Item>
            <NavDropdown.Item href="/LedgerReport">Ledger Report</NavDropdown.Item>
        </NavDropdown>      
        </Nav>
    </Navbar.Collapse>
    <Nav.Link style={{cursor: 'default', color: 'black', fontWeight: 500, fontSize: 'large'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</Nav.Link>
    <Nav.Link onClick={navigatetologin} style={{ marginLeft: 15, marginRight:5, cursor: 'pointer', color: 'black', fontWeight: 500, fontSize: 'large'}}>
        Log out
    </Nav.Link>
    </Navbar>
);

}
export default NavBarGlobal;