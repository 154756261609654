import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';

function ChequeTransactionReport() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  //below declaration of variable 'previousCenterId' to not show same centerid if it is 
  //same in next loop also only show once.
  let previousCenterIdTable = null;
  let previousCenterIdPrint = null;

  //below variables to show total at last of page in table
  let totalcowqtytable= 0;
  let totalcowamttable= 0;
  let totalbuffqtytable= 0;
  let totalbuffamttable= 0;
  let totalcash_recdtable= 0;
  let totalbank_recdtable= 0;

  //below variables to show total at last of page in print
  let totalcowqtyprint= 0;
  let totalcowamtprint= 0;
  let totalbuffqtyprint= 0;
  let totalbuffamtprint= 0;
  let totalcash_recdprint= 0;
  let totalbank_recdprint= 0;


  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };
      setFormData(updatedFormData);
    };

  // Function to get the first date of the current month
  const getFirstDateOfCurrentMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 2).toISOString().split('T')[0];
  };

  // Function to get the last date of the current month
  const getLastDateOfCurrentMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().split('T')[0];
  };

  const [formData, setFormData] = useState({
    fromdate: getFirstDateOfCurrentMonth(),
    todate: getLastDateOfCurrentMonth(),
    fromvno: '',
    tovno: '',
    sub_voucher_type: 'CASH',
  });

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      temporaryvalues_table();
      
  }

  async function temporaryvalues_table(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(formData.fromdate !== '') {
      }else{
        validationerrors.fromdate = "Please select date from datepicker.";
      } 

      if(formData.todate !== '') {
      }else{
        validationerrors.todate = "Please select date from datepicker.";
      } 

      if (formData.fromvno !== '') {
        if (formData.tovno == '') {
          validationerrors.tovno = "Please type the value in 'to voucher no' input.";
        } else if (parseInt(formData.fromvno) <= 0) {
          validationerrors.fromvno = "From Receipt No should be a positive number.";
        } else if (parseInt(formData.tovno) <= 0) {
          validationerrors.tovno = "To Receipt No should be a positive number.";
        } else if (parseInt(formData.fromvno) > parseInt(formData.tovno)) {
          validationerrors.fromvno = "From Receipt No should be smaller than to Receipt No.";
        }
      } else if (formData.tovno !== '') {
        if (formData.fromvno == '') {
          validationerrors.fromvno = "Please type the value in 'from Receipt No' input.";
        } else if (parseInt(formData.fromvno) <= 0) {
          validationerrors.fromvno = "From Receipt No should be a positive number.";
        } else if (parseInt(formData.tovno) <= 0) {
          validationerrors.tovno = "To Receipt No should be a positive number.";
        }
      }

      seterrors(validationerrors);
      
      if(Object.keys(validationerrors).length == 0) {
      
        var data = {
          // centerno: defaultcenter || (UserMasterDataGlobal?.[0]?.centerid || ""),
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          fromdate: formData.fromdate,
          todate: formData.todate,
          fromvno: formData.fromvno,
          tovno: formData.tovno,
          sub_voucher_type: formData.sub_voucher_type,
        };
        
        try {
          var response  = "";
          if(toshowcustomermasterdata !== "notshow") {
            response = await axios.post(apireq05042024+'Reports/GetChequeTransactionReport', data);

            setCustomerMasterData([]);  
            if (response.status === 200) {   
     
              if(response.data !== "No data found for the given date.") {                
                setCustomerMasterData(response.data);  
              }                             
            } 

          }
        } catch {
        
        } 
      } 
      else {
        return "validation errors.";
      } 
  }
  
  const getformatteddate = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
  };

  let previousDate = null;
  let currentTotalCount = 0;
  let currentTotalAmount = 0;
  const rows = [];

  CustomerMasterData.forEach((c, index) => {
    if (previousDate !== c.tran_date) {
      if (previousDate !== null) {
        rows.push(
          <tr key={`total-${previousDate}`} style={{ backgroundColor: '#f1f1f1' }}>
            <td colSpan="5" style={{ textAlign: 'right' }}><strong>Total for {previousDate}</strong></td>
            <td style={{ textAlign: 'right' }}><strong>{currentTotalCount}</strong></td>
            <td style={{ textAlign: 'center' }}><strong>{currentTotalAmount.toFixed(2)}</strong></td>
          </tr>
        );
      }
      previousDate = c.tran_date;
      currentTotalCount = 0;
      currentTotalAmount = 0;
    }

    currentTotalCount += 1;
    currentTotalAmount += parseFloat(c.cramt);

    rows.push(
      <tr key={c.customer_mp_id}>
        <td style={{ textAlign: 'center' }}>{getformatteddate(c.tran_date)}</td>
        <td style={{ textAlign: 'center' }}>{c.cust_check_no}</td>
        <td style={{ textAlign: 'center' }}>{getformatteddate(c.cust_check_date)}</td>
        <td style={{ textAlign: 'center' }}>{c.cust_bank_name}</td>
        <td style={{ textAlign: 'center' }}>{c.cust_branch_name}</td>
        <td style={{ textAlign: 'center' }}>{c.receiptno}</td>
        <td style={{ textAlign: 'center' }}>{c.cramt}</td>
      </tr>
    );
  });

  if (previousDate !== null) {
    rows.push(
      <tr key={`total-${previousDate}`} style={{ backgroundColor: '#f1f1f1' }}>
        <td colSpan="5" style={{ textAlign: 'center' }}><strong>Total for {getformatteddate(previousDate)}</strong></td>
        <td style={{ textAlign: 'right' }}><strong>{currentTotalCount}</strong></td>
        <td style={{ textAlign: 'center' }}><strong>{currentTotalAmount.toFixed(2)}</strong></td>
      </tr>
    );
  }
  
  // async function redirecttoprintContent(sub_voucher_type, event) {
  //   event.preventDefault();
  //   temporaryvalues_table();
  //   if(sub_voucher_type == "CASH" || sub_voucher_type == "ONLINE CASH") {      
  //     printContent2();
  //   }
  //   else{
  //     printContent();
  //   }
    
  // }
  //remove above code if new below code works neatly.
  useEffect(() => {
    if (dataLoaded) {
      if (formData.sub_voucher_type === "CASH" || formData.sub_voucher_type === "ONLINE CASH") {
        printContent2();
      } else {
        printContent();
      }
      setDataLoaded(false); // Reset the dataLoaded state
    }
  }, [dataLoaded]);

  async function redirecttoprintContent(sub_voucher_type, event) {
    event.preventDefault();
    await temporaryvalues_table();
    setDataLoaded(true); // Set dataLoaded to true after fetching data  
  }

  async function printContent() {
  
    const printWindow = window.open('', '_blank');
  
    let previousDate = null;
    let currentTotalCount = 0;
    let currentTotalAmount = 0;
    let tableRows = '';
    let GrandTotalCount = 0;
    let GrandTotalAmount = 0;

    CustomerMasterData.forEach((c, index) => {
      if (previousDate !== c.tran_date) {
        if (previousDate !== null) {
          tableRows += `
            <tr style="background-color: #f1f1f1;">
              <td colspan="5" style="text-align: center;"><strong>Total for ${getformatteddate(previousDate)}</strong></td>
              <td style="text-align: right;"><strong>${currentTotalCount}</strong></td>
              <td></td>
              <td style="text-align: right;"><strong>${currentTotalAmount.toFixed(2)}</strong></td>
            </tr>
          `;
          GrandTotalCount += currentTotalCount;
          GrandTotalAmount += currentTotalAmount;
        }
        previousDate = c.tran_date;
        currentTotalCount = 0;
        currentTotalAmount = 0;
      }
  
      currentTotalCount += 1;
      currentTotalAmount += parseFloat(c.cramt);
  
      tableRows += `
        <tr>
          <td style="text-align: center;">${getformatteddate(c.tran_date)}</td>
          <td style="text-align: right;">${c.cust_check_no}</td>
          <td style="text-align: center;">${getformatteddate(c.cust_check_date)}</td>
          <td style="text-align: center;">${c.cust_bank_name}</td>
          <td style="text-align: center;">${c.cust_branch_name}</td>
          <td style="text-align: right;">${c.receiptno}</td>
          <td style="text-align: left;">${c.customer_name}</td>
          <td style="text-align: right;">${c.cramt}</td>
        </tr>
      `;
    });
  
    if (previousDate !== null) {
      tableRows += `
        <tr style="background-color: #f1f1f1;">
          <td colspan="5" style="text-align: center;"><strong>Total for ${getformatteddate(previousDate)}</strong></td>
          <td style="text-align: right;"><strong>${currentTotalCount}</strong></td>
          <td></td>
          <td style="text-align: right;"><strong>${currentTotalAmount.toFixed(2)}</strong></td>
        </tr>
      `;
      GrandTotalCount += currentTotalCount;
      GrandTotalAmount += currentTotalAmount;
    }
  
    printWindow.document.write(`
      <html>
      <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
      </head>
      <body>
        <div class="table-container col-12">
          <h1 style="color: red; font-size: 25px; font-weight: bold; margin-top: 5px; text-align: center;">Janta Sahakari Bank Ltd, Pune</h1>
          <h2 style="color: red; font-size: 14px; font-weight: bold; margin-top: 5px; text-align: center;">(Scheduled Bank)</h2>
          <br />
          <div class="row">
            <div class="col-6">
                <h2 style="color: black; font-size: 14px; font-weight: normal; margin-top: 5px; text-align: left;">H.0. 1444, Shukrawar Peth</h2>
                <h2 style="color: black; font-size: 14px; font-weight: normal; margin-top: 5px; text-align: left;">Thorale Bajirao Road, Pune 411002</h2>    
            </div>
            <div class="col-6">
                <h2 style="color: black; font-size: 14px; font-weight: bold; margin-top: 5px; text-align: center;">${formData.sub_voucher_type}</h2>
            </div>
          </div>
        
          <h2 style="color: black; font-size: 14px; font-weight: normal; margin-top: 5px; text-align: left;">Branch: <b>Bajirao Road</b></h2>
          <h2 style="color: black; font-size: 14px; font-weight: normal; margin-top: 5px; text-align: left;">Paid in credit of <b>Phadake Group</b></h2>
          <br />
  
          <div class="row">
            <div class="col-12">
                <h1 style="color: black; font-size: 20px; font-weight: bold; margin-top: 5px; text-align: left;">Cheque Transaction Report From - ${getformatteddate(formData.fromdate)} To ${getformatteddate(formData.todate)}</h1>
            </div>

          </div>
  
          <table class="table table-bordered mt-3">
            <thead>
              <tr>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: center">Tr date</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Cheque No</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: center">Cheque Date</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: center">Bank</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: center">Branch</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">VNum</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: left">Customer Name</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Tr Amt</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows}
              <tr style="background-color: #f1f1f1;">
                <td colspan="5"  style="text-align: center;"><strong>Grand Total</strong></td>
                <td style="text-align: right;"><strong>${GrandTotalCount}</strong></td>
                <td></td>
                <td style="text-align: right;"><strong>${GrandTotalAmount.toFixed(2)}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </body>
      </html>
    `);
  
    printWindow.document.close();
  } 
  
  async function printContent2() {
  
    const printWindow = window.open('', '_blank');
  
    let previousDate = null;
    let currentTotalCount = 0;
    let currentTotalAmount = 0;
    let tableRows = '';
    let GrandTotalCount = 0;
    let GrandTotalAmount = 0;
  
    CustomerMasterData.forEach((c, index) => {
      if (previousDate !== c.tran_date) {
        if (previousDate !== null) {
          tableRows += `
            <tr style="background-color: #f1f1f1;">
              <td style="text-align: center;"><strong>Total for ${getformatteddate(previousDate)}</strong></td>
              <td style="text-align: right;"><strong>${currentTotalCount}</strong></td>
              <td></td>
              <td style="text-align: right;"><strong>${currentTotalAmount.toFixed(2)}</strong></td>
            </tr>
          `;
          GrandTotalCount += currentTotalCount;
          GrandTotalAmount += currentTotalAmount;
        }
        previousDate = c.tran_date;
        currentTotalCount = 0;
        currentTotalAmount = 0;
      }
  
      currentTotalCount += 1;
      currentTotalAmount += parseFloat(c.cramt);
  
      tableRows += `
        <tr>
          <td style="text-align: center;">${getformatteddate(c.tran_date)}</td>
          <td style="text-align: right;">${c.receiptno}</td>
          <td style="text-align: left;">${c.customer_name}</td>
          <td style="text-align: right;">${c.cramt}</td>
        </tr>
      `;
    });
  
    if (previousDate !== null) {
      tableRows += `
        <tr style="background-color: #f1f1f1;">
          <td style="text-align: center;"><strong>Total for ${getformatteddate(previousDate)}</strong></td>
          <td style="text-align: right;"><strong>${currentTotalCount}</strong></td>
          <td></td>
          <td style="text-align: right;"><strong>${currentTotalAmount.toFixed(2)}</strong></td>
        </tr>
      `;
      GrandTotalCount += currentTotalCount;
      GrandTotalAmount += currentTotalAmount;
    
    }
  
    printWindow.document.write(`
      <html>
      <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
      </head>
      <body>
        <div class="table-container col-12">
          <h1 style="color: red; font-size: 25px; font-weight: bold; margin-top: 5px; text-align: center;">${formData.sub_voucher_type} Report</h1>
          <br />
          <div class="row">
            <div class="col-6">
                <h2 style="color: black; font-size: 14px; font-weight: normal; margin-top: 5px; text-align: left;">H.0. 1444, Shukrawar Peth</h2>
                <h2 style="color: black; font-size: 14px; font-weight: normal; margin-top: 5px; text-align: left;">Thorale Bajirao Road, Pune 411002</h2>    
            </div>
            <div class="col-6">
                <h2 style="color: black; font-size: 14px; font-weight: bold; margin-top: 5px; text-align: center;">${formData.sub_voucher_type}</h2>
            </div>
          </div>
        
          <h2 style="color: black; font-size: 14px; font-weight: normal; margin-top: 5px; text-align: left;">Branch: <b>Bajirao Road</b></h2>
          <h2 style="color: black; font-size: 14px; font-weight: normal; margin-top: 5px; text-align: left;">Paid in credit of <b>Phadake Group</b></h2>
          <br />
  
          <div class="row">
              <h1 style="color: black; font-size: 20px; font-weight: bold; margin-top: 5px; text-align: center;">From ${getformatteddate(formData.fromdate)} To ${getformatteddate(formData.todate)}</h1>
          </div>
  
          <table class="table table-bordered mt-3">
            <thead>
              <tr>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: center">Tr date</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">VNum</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: left">Customer Name</th>
                <th style="color: black; background-color: #ececec; border-top: 2px solid black; border-bottom: 2px solid black; vertical-align: middle; text-align: right">Tr Amt</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows}
              <tr style="background-color: #f1f1f1;">
                <td style="text-align: center;"><strong>Grand Total</strong></td>
                <td style="text-align: right;"><strong>${GrandTotalCount}</strong></td>
                <td></td>
                <td style="text-align: right;"><strong>${GrandTotalAmount.toFixed(2)}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </body>
      </html>
    `);
  
    printWindow.document.close();
  } 
  
    return(
            
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default', fontSize: 20 }} className="text-center">Transaction Report</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

             <div className="row mb-2">

              <div className="col-md-2">
                <label htmlFor="id-fromdate" className="form-label">From Date</label>
                <input type="date" className="form-control" name="fromdate" id="id-fromdate" value={formData.fromdate} onChange={handleInputChange} required/>
                {errors.fromdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.fromdate}</span>}
              </div>

              <div className="col-md-2">
                <label htmlFor="id-todate" className="form-label">To Date</label>
                <input type="date" className="form-control" name="todate" id="id-todate" value={formData.todate} onChange={handleInputChange} required/>
                {errors.todate && <span style={{color: 'red', fontSize: '10px'}}>{errors.todate}</span>}
              </div>

              <div className="col-md-2">
                <label htmlFor="id-fromvno" className="form-label">From Voucher No</label>
                <input type="number" className="form-control" name="fromvno" id="id-fromvno" value={formData.fromvno} placeholder="Leave empty if not require" onChange={handleInputChange} required/>
                {errors.fromvno && <span style={{color: 'red', fontSize: '10px'}}>{errors.fromvno}</span>}
              </div>

              <div className="col-md-2">
                <label htmlFor="id-tovno" className="form-label">To Voucher No</label>
                <input type="number" className="form-control" name="tovno" id="id-tovno" value={formData.tovno} placeholder="Leave empty if not require" onChange={handleInputChange} required/>
                {errors.tovno && <span style={{color: 'red', fontSize: '10px'}}>{errors.tovno}</span>}
              </div>

              <div className="col-md-2">
                <label htmlFor="id-sub_voucher_type" className="form-label">Choose Payment Mode</label>
                {/* <input type="" className="form-control" name="sub_voucher_type" id="id-sub_voucher_type" value={formData.sub_voucher_type} onChange={handleInputChange} required/> */}

                <select
                name="sub_voucher_type"
                className="form-select"
                id="id-sub_voucher_type"
                value={formData.sub_voucher_type}
                onChange={handleInputChange} 
                >
                <option value="CASH">CASH</option>
                <option value="ONLINE CASH">ONLINE CASH</option>
                <option value="JANATA BANK">JANATA BANK</option>
                <option value="OTHER BANK">OTHER BANK</option>

                </select>

                {errors.sub_voucher_type && <span style={{color: 'red', fontSize: '10px'}}>{errors.sub_voucher_type}</span>}
              </div>

              {/* <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
              </div> */}

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={(event) => redirecttoprintContent(formData.sub_voucher_type, event)}>Print</button>
              </div>
               
            </div> 


          </form>
      </div>

    </div>

    );

}

export default ChequeTransactionReport;