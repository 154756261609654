import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavBarGlobal from './NavBarGlobal';

function Navbar1() {

  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}&getCompanyName=true`);
        if(response.data == "logout") {
          navigatetologin();
        }
       
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);

  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);
        
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  } 
  
  useEffect(() => {
    // Check if the page has already been reloaded
    if (!localStorage.getItem('pageReloaded')) {
      // Perform reload
      localStorage.setItem('pageReloaded', 'true');
      window.location.reload();
    } else {
      // Clear the flag for future reloads (if needed)
      localStorage.removeItem('pageReloaded');
    }
  }, []);
  
  return (

    <div className="container-fluid" style={{ color: '#4229cb', backgroundColor: 'rgb(255 255 255)' }}>
      <h2 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px',marginBottom:'0px', cursor: 'default'}} className="text-center">
        मिल्कप्रो - MDS
      </h2>

      <h4 style={{ fontSize:'20px', color: 'white', backgroundColor: '#4229cb', marginTop:'0px', padding: '5px', cursor: 'default' }} className="text-center">
        {/* In below code i want to access array properties of NavbarGlobal page to
        display company name */}
        Licensed to : {UserMasterDataGlobal?.[0]?.companyname}
      </h4>     
      
      <NavBarGlobal />

    </div>
    
    );

}

export default Navbar1;
