import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Table } from 'react-bootstrap'; // Import Bootstrap components

function LedgerReport() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const MySwal = withReactContent(Swal);
  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  //below declaration of variable 'previousCenterId' to not show same centerid if it is 
  //same in next loop also only show once.
  let previousCenterIdTable = null;
  let previousCenterIdPrint = null;

  //below variables to show total at last of page in table
  let totalcowqtytable= 0;
  let totalcowamttable= 0;
  let totalbuffqtytable= 0;
  let totalbuffamttable= 0;
  let totalcash_recdtable= 0;
  let totalbank_recdtable= 0;

  //below variables to show total at last of page in print
  let totalcowqtyprint= 0;
  let totalcowamtprint= 0;
  let totalbuffqtyprint= 0;
  let totalbuffamtprint= 0;
  let totalcash_recdprint= 0;
  let totalbank_recdprint= 0;


  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});
    const [defaultroutename, setdefaultroutename] = useState();
    const [RouteMasterData, setRouteMasterData] = useState([]);
      
    const [defaultcenter, setdefaultcenter] = useState();

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      if(name == "centerid") {
        const [centerid, centername] = value.split('-');
        updatedFormData.centerno = centerid;
        setdefaultcenter(centerid);
        // console.log('dfads');
        getroutemasterdata(centerid);
        
      }
  
      if(name == "routename") {
        const [routecode, routename] = value.split('-');
        setdefaultroutename(routecode);
      }
  
      setFormData(updatedFormData);
    };
    
    
  // useEffect(() => {
  //   if(defaultcenter){
  //       getroutemasterdata(defaultcenter, 'getmaxrates');    
  //     // ShowCustomerData();  
  //     }          
  // }, [defaultcenter]);

  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      // if(!defaultcenter){
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)  
        .then((response) => {
          if(!defaultcenter){
            setCenterMasterData(response.data.CenterMasterData);
            setdefaultcenter(response.data.CenterMasterData[0].centerid);
            // console.log('popo');
            setFormData({
              ...formData,
              centerno: response.data.CenterMasterData[0].centerid,
            })
            getroutemasterdata(response.data.CenterMasterData[0].centerid);
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      // }
    }
  }, [UserMasterDataGlobal]);

    async function getroutemasterdata(centerid, routename=null) {
      // console.log(rr);
      var data1 = {
        centerid : centerid,
        CompanyId : UserMasterDataGlobal[0]?.CompanyId,
      }
      // console.log(data1);
      var response1 = await axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
      .then((response1) => {
        if(routename==null) {
          setdefaultroutename(response1.data[0].routename);
        }
        else{
          setdefaultroutename(routename);
        }
        setRouteMasterData(response1.data);
      })
    }

    const getCenterNameById = (centerid) => {
      const center = CenterMasterData.find(center => center.centerid === centerid);
      return center ? center.centername : '';
    };


  // Function to get the first date of the current month
  const getFirstDateOfCurrentMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 2).toISOString().split('T')[0];
  };

  // Function to get the last date of the current month
  const getLastDateOfCurrentMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().split('T')[0];
  };

  const [formData, setFormData] = useState({
    fromdate: getFirstDateOfCurrentMonth(),
    todate: getLastDateOfCurrentMonth(),
    customer_centerwiseid: ''
  });

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [CenterMasterData, setCenterMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)

      .then((response) => {
        if(CenterMasterData.length <= 0) {
          setCenterMasterData(response.data.CenterMasterData);
          setFormData({
            ...formData,
            centerno: UserMasterDataGlobal?.[0]?.centerid,
            fromdate: getCurrentDate(),
          })
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  async function temporaryvalues_table(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(formData.fromdate !== '') {
      }else{
        validationerrors.fromdate = "Please select date from datepicker.";
      } 

      if(formData.todate !== '') {
      }else{
        validationerrors.todate = "Please select date from datepicker.";
      } 

      if (formData.customer_centerwiseid == '') {
        validationerrors.customer_centerwiseid = "Please type Customer No.";
      }

      seterrors(validationerrors);
      
      if(Object.keys(validationerrors).length == 0) {
        
        var data = {
          // centerno: defaultcenter || (UserMasterDataGlobal?.[0]?.centerid || ""),
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          fromdate: formData.fromdate,
          todate: formData.todate,
          customer_centerwiseid: formData.customer_centerwiseid,
          center: defaultcenter,
          routename: defaultroutename
        };

        try {
          var response  = "";
          if(toshowcustomermasterdata !== "notshow") {
            response = await axios.post(apireq05042024+'Reports/LedgerReport', data);

            setCustomerMasterData([]);  

            if (response.status === 200) {    
              // console.log(response.data);                
              // return;
              if(response.data !== "Customer not found") {                
                setCustomerMasterData(response.data);  
              }   
              else {
                return "Customer not found";
              }                          
            } 

          }
        } catch {
        
        } 
      } 
      else {
        return "validation errors.";
      } 
  }
  
  const getformatteddate = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
  };

  //remove above code if new below code works neatly.
  useEffect(() => {
    if (dataLoaded) {
      printContent();
      setDataLoaded(false); // Reset the dataLoaded state
    }
  }, [dataLoaded]);

  async function redirecttoprintContent(sub_voucher_type, event) {
    event.preventDefault();
    const res = await temporaryvalues_table();
    
    if(res == "Customer not found"){
      MySwal.fire({
        //   title: <strong>Record Saved Sucessfully!</strong>,
        html: <b>Customer not found</b>,
        icon: 'error'
      })
      return;
    }

    if(res == "validation errors."){
      return;
    }

    setDataLoaded(true); // Set dataLoaded to true after fetching data  
  }

async function printContent() {
  const printWindow = window.open('', '_blank');

  let content = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Ledger Report</title>
        <!-- Include Bootstrap CSS -->
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        <style>
            /* Custom styles */
            .report-container {
                max-width: 800px;
                margin: 0 auto;
                padding: 20px;
                font-family: Arial, sans-serif;
            }
            .report-header {
                text-align: center;
                margin-bottom: 20px;
            }
            .transaction {
                margin-bottom: 10px;
            }
        </style>
      </head>
      <body>
  `;

  const c = CustomerMasterData;
  content += `
    <div class="container report-container">
        <div class="report-header">
            <h1 style="font-size: x-large;">Ledger for ${c.customer_name}</h1>
            <p class="lead">Date Range: ${getformatteddate(formData.fromdate)} to ${getformatteddate(formData.todate)}</p>
        </div>
        <span>Date: ${getformatteddate(getCurrentDate())}</span>
        <table style="float: right;">
            <tbody>
                <tr>
                    <td><span style="margin-right: 2px;">Center: </span></td>
                    <td><span>${getCenterNameById(defaultcenter)}</span></td>
                </tr>
                <tr>
                    <td><span style="margin-right: 2px;">Line No: </span></td>
                    <td><span>&nbsp;${defaultroutename}</span></td>
                </tr>
            </tbody>
        </table>
        <div class="report-content">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Particulars</th>
                        <th style="text-align: right;">Vr No</th>
                        <th style="text-align: right;">Debit Amount</th>
                        <th style="text-align: right;">Credit Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td>Opening balance</td>
                        <td></td>
                        <td style="text-align: right;">${Number(c.opbal).toFixed(2)}</td>
                        <td></td>
                    </tr>
      `;

      // Loop through transactions
      c.transactions.forEach((transaction) => {
        content += `
          <tr>
              <td>${getformatteddate(transaction.tran_date)}</td>
              <td>${transaction.payment_by !== "Bill No:" ? "BY " + transaction.payment_by : "Bill No:"}</td>
              <td style="text-align: right;">${transaction.receiptno}</td>
              <td style="text-align: right;">${Number(transaction.dramt)? Number(transaction.dramt).toFixed(2) : ""}</td>
              <td style="text-align: right;">${Number(transaction.cramt)? Number(transaction.cramt).toFixed(2) : ""}</td>
          </tr>
        `;
    });

    
  const totalDramt = c.transactions.reduce((sum, transaction) => sum + (Number(transaction.dramt) || 0), 0);
  const totalCramt = c.transactions.reduce((sum, transaction) => sum + (Number(transaction.cramt) || 0), 0);

  // Calculate closing balance
  const closingBalance = Number(c.opbal) + Number(totalDramt) - Number(totalCramt);
  const debitBalance = closingBalance > 0 ? closingBalance : 0;
  const creditBalance = closingBalance < 0 ? -closingBalance : 0;

  // Add total and closing balance rows
  content += `
                    <tr>
                        <td></td>
                        <td></td>
                        <td><span style="font-weight: bold;">Total:</span></td>
                        <td style="text-align: right;"><span style="font-weight: bold;">${Number(totalDramt).toFixed(2)}</span></td> 
                        <td style="text-align: right;"><span style="font-weight: bold;">${Number(totalCramt).toFixed(2)}</span></td> 
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td><span style="font-weight: bold;">Closing Balance:</span></td>
                        <td style="text-align: right;"><span style="font-weight: bold;">${Number(debitBalance).toFixed(2)}</span></td>
                        <td style="text-align: right;"><span style="font-weight: bold;">${Number(creditBalance).toFixed(2)}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  `;

  content += `
      </body>
    </html>
  `;

  printWindow.document.write(content);
  printWindow.document.close();
  // printWindow.print();
}

const[AllCustomerMasterData, setAllCustomerMasterData] = useState([]);
//2) using axios
useEffect(() => {
// Fetch data from the specified URL
  if (UserMasterDataGlobal.length > 0) {
    axios.get(apireq05042024+`Reports/GetAllDataCustomerMasterTable/${UserMasterDataGlobal[0]?.CompanyId}`)

    .then((response) => {
      // Access the response data directly using response.data
      if(AllCustomerMasterData.length <= 0){
        setAllCustomerMasterData(response.data);
      }                    
    })
    .catch((error) => console.error('Error fetching data:', error));
  }
}, [UserMasterDataGlobal]);

const [showPopup, setShowPopup] = useState(false);
const [searchTerm, setSearchTerm] = useState('');
const [filteredData, setFilteredData] = useState(AllCustomerMasterData);

const handleFindClick = (event) => {
  event.preventDefault();
  setShowPopup(true);
  setFilteredData(AllCustomerMasterData); // Reset filtered data when opening the popup
};

const handleInputChangePopUpTable = (event) => {
  setSearchTerm(event.target.value.toLowerCase());
  const filtered = AllCustomerMasterData.filter((item) => {
    // Improved search across all columns using `some`
    return item.customer_name.toLowerCase().includes(searchTerm);
  });
  setFilteredData(filtered);
};

const handlePopupClose = () => {
  setShowPopup(false);
};

const handleRowClick = (customer) => { 

  setdefaultcenter(customer.centerid); 
  getroutemasterdata(customer.centerid, customer.routename); 
  setFormData({
    ...formData,
    customer_centerwiseid: customer.customer_centerwiseid,
  })
  
  setShowPopup(false); 
};

    return(
            
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default', fontSize: 20 }} className="text-center">Ledger Report</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

            <div className="row mb-2">

              <div className='col-md-2'>
                <input type="hidden" className="form-control" name="centerno" value={defaultcenter} onChange={handleInputChange} />
                <label htmlFor="centerid" className="form-label">Center Name</label>
                <select
                  className="form-select"
                  name="centerid"
                  onChange={handleInputChange}
                  value={defaultcenter}
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                  {defaultcenter && (
                    <option key={defaultcenter} value={defaultcenter}>
                      {getCenterNameById(defaultcenter)}
                    </option>
                  )}

                  {CenterMasterData.map((cp) => (
                    defaultcenter !== cp.centerid ?
                      <option key={cp.centerid} value={cp.centerid} >
                        {cp.centername}
                      </option>
                      : null
                  ))}
                </select>
                {errors.centerno && <span style={{ color: 'red', fontSize: '10px' }}>{errors.centerno}</span>}
              </div>

              <input type="hidden" className="form-control" name="routecode" value={defaultroutename} onChange={handleInputChange} required />

              <div className="col-md-2">
                <label htmlFor="id-routename" className="form-label">Select Line</label>
                <select
                  className="form-select"
                  id="id-routename"
                  name="routename"
                  onChange={handleInputChange}
                  value={defaultroutename}
                >
                  {defaultroutename && (
                    <option value={defaultroutename}>
                      {defaultroutename}
                    </option>
                  )}

                  {RouteMasterData.map((r) => (
                    defaultroutename !== r.routename ?
                      <option key={r.routecode} value={`${r.routecode}-${r.routename}`} >
                        {r.routename}
                      </option>
                      : null
                  ))}
                </select>
                {errors.routecode && <span style={{ color: 'red', fontSize: '10px' }}>{errors.routecode}</span>}
              </div>
              
              <div className="col-md-2">
                <label htmlFor="id-fromdate" className="form-label">From Date</label>
                <input type="date" className="form-control" name="fromdate" id="id-fromdate" value={formData.fromdate} onChange={handleInputChange} required/>
                {errors.fromdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.fromdate}</span>}
              </div>

              <div className="col-md-2">
                <label htmlFor="id-todate" className="form-label">To Date</label>
                <input type="date" className="form-control" name="todate" id="id-todate" value={formData.todate} onChange={handleInputChange} required/>
                {errors.todate && <span style={{color: 'red', fontSize: '10px'}}>{errors.todate}</span>}
              </div>

              <div className="col-md-2">
                <label htmlFor="id-customer_centerwiseid" className="form-label">Customer No</label>
                <input type="number" className="form-control" name="customer_centerwiseid" id="id-customer_centerwiseid" value={formData.customer_centerwiseid} onChange={handleInputChange} required/>
                {errors.customer_centerwiseid && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_centerwiseid}</span>}
              </div>

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={(event) => redirecttoprintContent(formData.sub_voucher_type, event)}>Print</button>
              </div>
              
              <div className="col-md-1">
              
                <label htmlFor="customer_name" className="form-label" style={{color: 'white'}}>Find</label>
                <button type="submit" className="btn btn-primary w-100" onClick={handleFindClick}>Find</button>
                    
              </div>

            </div>            
            
            <Modal show={showPopup} onHide={handlePopupClose} backdrop="static" keyboard={false} > {/* Disable background click and keyboard close */}
              <ModalHeader style={{backgroundColor: 'green', color: 'white'}}closeButton>
                <h3>Search Results</h3>
              </ModalHeader>
              <ModalBody>
                
                {/* <Table striped bordered hover >  */}
                <div className="table-container" style={{height: '350px', overflow: 'auto', borderWidth:'0px' }}>
                <input type="text" className='form-control' placeholder="Search..." style={{marginBottom: 8, border: '2px solid black'}} value={searchTerm} onChange={handleInputChangePopUpTable} />
                  <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{backgroundColor:'#009879', color:"white", verticalAlign: 'top'}}>Customer Name</th>
                      <th style={{backgroundColor:'#009879', color:"white", verticalAlign: 'top'}}>Center Name</th>
                      <th style={{backgroundColor:'#009879', color:"white", verticalAlign: 'top', textAlign: 'right'}}>Line No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item) => (
                      <tr key={item.customer_mp_id} onClick={() => handleRowClick(item)}>
                        <td style={{cursor: 'pointer'}}>{item.customer_name}</td>
                        <td style={{cursor: 'pointer'}}>{getCenterNameById(item.centerid)}</td>
                        <td style={{cursor: 'pointer', textAlign: 'right'}}>{item.routename}</td>
                      </tr>
                    ))}
                  </tbody>
                {/* </Table> */}
                </table>
                </div>
              </ModalBody>
              
              </Modal>

          </form>
      </div>

    </div>

    );

}

export default LedgerReport;