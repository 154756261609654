import React, { useEffect, useState, useRef  } from  'react';
import {useNavigate, json} from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//above code is to import libs
function Test2() {
    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
   
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }
  
      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds
  
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
        if (usernameglobal === null) {
        navigate('/LoginPage');
        } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
            setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('touchstart', handleUserActivity);
        };
        }
    }, [usernameglobal, navigate]);

    useEffect(() => {
        const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;

        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
            console.log('Logging out due to inactivity...');
            navigatetologin();
        } else {
            // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
            sessionTimeout.refresh();
        }
        }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

        return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
        const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
        localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
        navigate('/loginpage');
    } 

    //setting default center value for first option in selectbox
    const [defaultcenter, setdefaultcenter] = useState();

    //setting centermaster array and putting values from centermaster table in it.
    const [CenterMasterData, setCenterMasterData] = useState([]);
    useEffect(() => {
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
            //setting the data we got in centermaster data
            setCenterMasterData(response.data.CenterMasterData);
            setdefaultcenter(response.data.CenterMasterData[0].centerid);
            setFormData({
                ...formData,
                centerno: response.data.CenterMasterData[0].centerid,
                transdate: getCurrentDate(),
            })
            getroutemasterdatabycenterid(response.data.CenterMasterData[0].centerid, "centername");
                
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const [formData, setFormData] = useState({
        //for centerno inputbox
        centerno: '',
        //for centerid(centername) selectbox
        centername: '',
        //date input box initial value should be today's date
        transdate : '',
        //routename
        routename : '',
        customer_centerwiseid: '',        
        customer_mp_id: '',
        customer_place: '',
        customer_mobile: '',
        opbal: '',
        customer_name: '',
        //below code is to show the value of prevbalrs in its label
        prevbalrs: '',
        bankname: '',
        //if not needed below 'amt' declaration remove it.
        qty: '',
        amt: '',
        // below variable is to show balance value at above label of save button
        balance: '',
        //for cash_recd inputbox
        cash_recd: '',
        //for bank_recd inputbox
        bank_recd: '',
        // the amount of totalbill + previous balance
        totpay: '',
    });

    //this function is to handle the properties in formdata array if values changes in inputbox, selectbox
    const handleInputChange = (e) => {
        
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value,
        };

        //below code if for centername selectbox
        if (name == 'centername') {
            updatedFormData.customer_centerwiseid = ''; 
            updatedFormData.centerno = value;
            setdefaultcenter(value);
            //setting the routename data by calling following  function with parameter as selected centerid
            getroutemasterdatabycenterid(value, "centername");
        }

        if (name == "routename") {
            updatedFormData.customer_centerwiseid = ''; 
            setdefaultroutename(value);
            fetchcustomer_name_data(defaultcenter, value);
        }

        //below code is for customer_name selectbox
        // if (name == "customer_name") {
        //     const [customer_centerwiseid, customer_name] = value.split('-');

        //     //we passed second parameter here because we have to update formdata array property
        //     //according from which element we are calling below function. 
        //     getrecordsbycustomer_centerwiseid(customer_centerwiseid, "setcustomer_centerwiseid");
            
        //     //I put return below because if we update formdata again then it is keeping old
        //     //value of formdata.customer_centerwiseid as it is (old value).
        //     return;
        // }

        if(name == "bankname") {
            setdefaultbank(value);
        }

        setFormData(updatedFormData);
    }

    //below function is to get current date in yyyy-mm-dd format.
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    //to get the data from 'temporaryvalues_table' this table
    useEffect(() => {
        if (UserMasterDataGlobal.length > 0) {
            
            const userIdapireq = UserMasterDataGlobal[0]?.userid;
            const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;
            const apirequest = apireq05042024+`DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;
            axios.get(apirequest)
            .then((response) => {
                try {
                    setdefaultcenter(response.data[0].text1);
                    setdefaultroutename(response.data[0].text3 !== '' ? response.data[0].text3 : '');
                    
                    //below code is to set the RouteMasterData array
                    axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${response.data[0].text1}/${companyIdapireq}`)
                    .then((response1) => {
                        setRouteMasterData(response1.data);
                    })
                    setFormData({
                        ...formData,
                        //if there is no value in text5 then currentdate function will be called
                        transdate: response.data[0].text5 !== '' ? response.data[0].text5 : getCurrentDate(),
                        centerno: response.data[0].text1 !== '' ? response.data[0].text1 : '',
                    });
                    fetchcustomer_name_data(response.data[0].text1, response.data[0].text3);
                }
                catch {}          
            })
            .catch((error) => console.error('Error fetching route data:', error));
        }
    }, [UserMasterDataGlobal]);

    //below function is to get centername value from centerid
    const getCenterNameById = (centerid) => {
        const center = CenterMasterData.find(center => center.centerid === centerid);
        return center ? center.centername : '';
    };

    //logout button code
    function navigatetologin() {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
        navigate('/loginpage');
    }

    //to get the data from centerid/centerno we use this function
    async function getroutemasterdatabycenterid(centerid, rr=null) {
        var data1 = {
            centerid : centerid,
            CompanyId : UserMasterDataGlobal[0]?.CompanyId,
        }
        var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
        .then((response1) => {
            setdefaultroutename(response1.data[0].routename);
            setRouteMasterData(response1.data);
            if(rr=="centername") {
                //populating customer_name list using customer_name_data array by calling below function.
                //i put below function here because when page first time renders and we try to change
                //centername from selectbox. it is trying to take routename of previous center.
                fetchcustomer_name_data(centerid,response1.data[0].routename);
            }
        })
    }
    
    const [defaultroutename, setdefaultroutename] = useState();
    const [RouteMasterData, setRouteMasterData] = useState([]);

    const centernoonblur = (e) => {
        e.preventDefault();
        var { name, value } = e.target;
        
        if (value == '') {
            return;
        }

        //below i placed 1==1 because in the place of that condition we have to
        //put the the boolean value to check the user entered centerid exists or not.
        // using 'isCenterExists' this function.

        // we can also use try catch solution here
        if(1==1){
            setdefaultcenter(value);
            //setting the routename data by calling following  function with parameter as selected centerid
            getroutemasterdatabycenterid(value);
            //populating customer_name list using customer_name_data array by calling below function
            fetchcustomer_name_data(value,defaultroutename);
        }
        else {
            alert("This centerno doesn't exists");
            setFormData({
                ...formData,
                centerno: defaultcenter
            })
        }        
    }

    function navigatetodailydelivery() {
        temporaryvalues_table();
        navigate('/DailyDeliverySheet')
    }
    
    //setting errors array
    const [errors,  seterrors] =  useState({});

    //for saving the data like centerno, routecode in the temporaryvalues_table
    //we have following function
    async function temporaryvalues_table(toperformEmptyInputs=null) {

        var validationerrors = {};

        if(formData.centerno !== '') {
        }else{
          validationerrors.centerno = "Please type prefered center no.";
        } 
        
        seterrors(validationerrors);
  
        if(Object.keys(validationerrors).length == 0) {
  
          var data = {
            centerno:formData.centerno,
            routecode: defaultroutename,
            bookdate: formData.transdate || getCurrentDate(),
            CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
            userid: UserMasterDataGlobal?.[0]?.userid || "",
          };
  
          try {
            
            var response  = "";
            response = await axios.post(apireq05042024+'DailyDeliverySheet/updateinsert_temporaryvalues_table', data);
  
            if (response.status === 200) {

            }
            
          } catch {
  
          } 
          if(toperformEmptyInputs == "toperformEmptyInputs"){ 
            emptyinputs();
          }
          
        }  
    }
    
    //ref to get focus on customer_centerwiseid
    const customer_centerwiseidRef = useRef(null);

    //setting array 'customer_name_data' to populate list with customer names in its selectbox
    const [customer_name_data,  setcustomer_name_data] =  useState([]);

    //below function is for putting data in customer_name_data array
    async function  fetchcustomer_name_data(centerid, routecode) {
        
        setcustomer_name_data([]);

        var axiosreqtoapi = apireq05042024+'CustomerMaster/getCustomerDataWithCenterNoAndRouteCode?routecode='+routecode
        +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid;
        console.log(axiosreqtoapi);
        const response = axios.get(axiosreqtoapi)

        .then((response) => {
            if(response.data.customer_name_data.length > 0) {
                setcustomer_name_data(response.data.customer_name_data);
            }
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
    }

    async function getrecordsbycustomer_centerwiseid(customer_centerwiseid=null, setcustomer_centerwiseid=null) {

        if(customer_centerwiseid == ''){
          return;
        }
    
        const axiosreqtoapi = apireq05042024+'CustomerMaster/getrecordbybycustomer_centerwiseid?customer_centerwiseid='+customer_centerwiseid+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId
        +'&centerid='+defaultcenter+'&routename='+defaultroutename;

        const response = await axios.get(axiosreqtoapi)
        
        .then((response) => {
        
          if(response.data.CustomerMasterData.length > 0){
            //below code is to see if the array we got in response is present already in customer_name_data array.
            const index = customer_name_data.findIndex(item =>
                item.customer_mp_id === response.data.CustomerMasterData[0].customer_mp_id
            );
            
            // If response array already exists in customer_name_data, move it to the first index
            if (index !== -1) {                
                const removedArray = customer_name_data.splice(index, 1)[0];        
                // Then insert it at the beginning
                const newData = [removedArray, ...customer_name_data];
                setcustomer_name_data(newData);

                if(setcustomer_centerwiseid=="setcustomer_centerwiseid") {
                     //putting the existing array customer_name to the value of select box
                    setFormData({
                        ...formData,
                        customer_centerwiseid: response.data.CustomerMasterData[0].customer_centerwiseid,
                        // below code is to add prev bal rs value in 
                        //its label in previous balance rs. label
                        prevbalrs: response.data.CustomerMasterData[0].clbal,
                        customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                        customer_place: response.data.CustomerMasterData[0].customer_place,
                        customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                        prevbalrs: response.data.CustomerMasterData[0].opbal,
                    });
                }
                else{
                    setFormData({
                        ...formData,
                        customer_name: response.data.CustomerMasterData[0].customer_name,
                        // below code is to add prev bal rs value in 
                        //its label in previous balance rs. label
                        prevbalrs: response.data.CustomerMasterData[0].clbal,
                        customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                        customer_place: response.data.CustomerMasterData[0].customer_place,
                        customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                        prevbalrs: response.data.CustomerMasterData[0].opbal,
                    });
                }               

            } else {
                // If it doesn't exist, push it normally
                setcustomer_name_data(response.data.CustomerMasterData);

                if(setcustomer_centerwiseid=="setcustomer_centerwiseid") {
                    //putting the existing array customer_name to the value of select box
                   setFormData({
                       ...formData,
                       customer_centerwiseid: response.data.CustomerMasterData[0].customer_centerwiseid,
                       // below code is to add prev bal rs value in 
                       //its label in previous balance rs. label
                       prevbalrs: response.data.CustomerMasterData[0].clbal,
                       customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                       customer_place: response.data.CustomerMasterData[0].customer_place,
                       customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                       prevbalrs: response.data.CustomerMasterData[0].opbal,
                   });
                }
                else{
                    setFormData({
                        ...formData,
                        customer_name: response.data.CustomerMasterData[0].customer_name,
                        // below code is to add prev bal rs value in 
                        //its label in previous balance rs. label
                        prevbalrs: response.data.CustomerMasterData[0].clbal,
                        customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                        customer_place: response.data.CustomerMasterData[0].customer_place,
                        customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                        prevbalrs: response.data.CustomerMasterData[0].opbal,
                    });
                }
            }

          } else {
            //If no data from API then clear customer_centerwiseid field and apologies message
            if(formData.customer_centerwiseid !== '') {
                alert("Apologies, no customer found; they may be located at a different center...");
                setFormData({
                    ...formData,
                    customer_centerwiseid: '',
                });                
            }        
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
        
    }

    //below function is to check whether following centerid exists for following companyid 
    async function isCenterExists(centerid=null) {
        var data1 = {
            centerid : centerid,
            CompanyId : UserMasterDataGlobal[0]?.CompanyId,
        }
        var response = await axios.get(apireq05042024+`CenterMaster/isCenterExists/${data1.centerid}/${data1.CompanyId}`)
        .then((response) => {
            if(response.data == "found") {
                return true;
            }
            else{
                return false;
            }
        })
    }

    //mounting ProductMasterData array
    const [ProductMasterData, setProductMasterData] = useState([]);
    //fetching the data from product_master_table
    useEffect(() => {
        // Fetch data from the specified URL
        if (UserMasterDataGlobal.length > 0 && ProductMasterData.length == 0 ) {
        axios.get(apireq05042024+`ProductMaster?orderby=ASC&CompanyId=`+UserMasterDataGlobal?.[0]?.CompanyId)
        .then((response) => {
            // Access the response data directly using response.data
            setProductMasterData(response.data.ProductMasterData);
            
            const initialProductData = response.data.ProductMasterData.map((p) => ({
                ...p,
                qty: 0,
                amt: 0,
            }));
    
            setProductData(initialProductData);
        })
    
        .catch((error) => console.error('Error fetching data:', error));
        }
    }, [UserMasterDataGlobal, ProductMasterData]);
    
    //i don't understand this code now at 21 02 2024 at 12 : 20 PM
    //when you get for what below code is  used? then write it...
    const [productData, setProductData] = useState([]);

    //below ref declaration is required for qty inputboxes in table
    const qtyInputRefs = useRef([]);

    //below totalAmount variable mounting code is to set billamt label's value
    const [totalAmount, setTotalAmount] = useState(0);

    //if user changes values in qty it is handled by below function
    const handleQtyChange = (index, event) => {
    
        setProductData((prevProductData) => {
            const updatedProductData = [...prevProductData];
            const qty = parseInt(event.target.value, 10) || 0;
            updatedProductData[index].qty = qty;
            updatedProductData[index].amt = qty * updatedProductData[index].mrp;
        
            const newTotalAmount = updatedProductData.reduce((sum, product) => sum + product.amt, 0);
            setTotalAmount(newTotalAmount);
      
            return updatedProductData;
        });            
    };

    //mounting BankAccountData array for populating bank name selectbox
    const [BankAccountData, setBankAccountData] = useState([]);
    //below code is to set first value of bank in the selectbox
    const [defaultbank, setdefaultbank] = useState();
    //fetching bankaccountdata from 'bank_account_table'
    useEffect(() => {
      if(UserMasterDataGlobal.length>0){
        axios.get(apireq05042024+'OtherController?CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)
        .then((response) => {
            if(response.data.bank_account_data.length >0) {
                setdefaultbank(response.data.bank_account_data[0].bankname);
                setBankAccountData(response.data.bank_account_data);
            }            
          })
          .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    function calculate_balance() {
        var calc1 =
            Number(totalAmount) +
            Number(formData.prevbalrs);

        var calc2 = Number(formData.cash_recd) +
                    Number(formData.bank_recd);

        var calc3 = calc1 - calc2;

        setFormData({
            ...formData,
            balance: calc3,
        });
    }

    //for saving the sales entry we use this function
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        const [routecode, routename] = formData.get("routename").split('-');
        var cramtsummation;
        var transaction_id;
        var validationerrors = {};

        var cowqty, cowrate, cowamt, buffqty, buffrate, buffamt = 0;
        ProductMasterData.forEach((p, index) => {

            if(formData.get(`product_category[${index}]`) == "Cow Milk") {
            cowqty = formData.get(`qty[${index}]`);
            cowrate = formData.get(`rate[${index}]`);
            cowamt = formData.get(`amt[${index}]`);
            }
            
            if(formData.get(`product_category[${index}]`) == "Buff Milk") {
            buffqty = formData.get(`qty[${index}]`);
            buffrate = formData.get(`rate[${index}]`);
            buffamt = formData.get(`amt[${index}]`);        
            }
        });

        cramtsummation = Number(formData.get("cash_recd")) + Number(formData.get("bank_recd"))

        if(formData.get('customer_centerwiseid').trim() !== '') {
        }
        else{
            validationerrors.customer_centerwiseid = "Please enter a valid value for customer id.";
        }

        seterrors(validationerrors);

        var calc1 =
            Number(totalAmount) +
            Number(formData.get('prevbalrs'));

        var calc2 = Number(formData.get('cash_recd')) +
                    Number(formData.get('bank_recd'));
        var calc3 = calc1 - calc2; 
        
        const yourString = formData.get('routename');
        const parts = yourString.split('-');
        const getroutename = parts.slice(1).join('-');
        const getroutecode = parts[0];

        var data = {
            CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
            centerid: formData.get('centerno'),
            userid: UserMasterDataGlobal?.[0]?.userid,
            trantype: 'SALES',
            transdate: formData.get('transdate'),
            customer_mp_id: formData.get('customer_mp_id'),
            customer_centerwiseid: formData.get("customer_centerwiseid"),
            customer_name: formData.get("customer_name"),
            customer_place: formData.get('customer_place'),
            customer_mobile: formData.get('customer_mobile'),
            routename: getroutecode,
            routecode: getroutecode,
            cowqty: cowqty,
            cowrate: cowrate,
            cowamt: cowamt,
            buffqty: buffqty,
            buffrate: buffrate,
            buffamt: buffamt,
            productsale_amt: Number(formData.get('billamt')) - Number(cowamt) - Number(buffamt),
            bankname: formData.get('bankname'),
            dramt: formData.get('billamt'),
            opbal: formData.get('opbal'),
            cramt: cramtsummation,
            balance: calc3,
            cash_recd: formData.get('cash_recd'), 
            bank_recd: formData.get('bank_recd'),
            transid: formData.get('transid'),
            operationtype: formData.get('operationtype'),
        };

        var transaction_id = '';
        if(Object.keys(validationerrors).length == 0) {

            const response = await axios.post(apireq05042024+'OtherController/savesales', data);

            if (response.status === 200) {
                // console.log(response.data);
                transaction_id = response.data;
            }
 
            const saveProductData = async (productData) => {
                try {
                    const response = await axios.post(apireq05042024+'OtherController/salesdetails', productData);
                
                    if (response.status === 200) {
                        //console.log(response.data);
                    }
                } catch (error) {
                    console.error('Error saving product data:', error);
                }
            };
            
            ProductMasterData.forEach(async (product, index) => {
                const productData = {
                    transaction_id: transaction_id,
                    transdate: formData.get('transdate'),
                    CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
                    productid: formData.get(`productid[${index}]`),
                    product_name: formData.get(`product_name[${index}]`),
                    rate: formData.get(`rate[${index}]`),
                    qty: formData.get(`qty[${index}]`),
                    amt: formData.get(`amt[${index}]`),
                };
            
                if(productData.amt != 0){
                    await saveProductData(productData);
                }
            });

            MySwal.fire({
                //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>Record Saved Sucessfully!</b>,
                icon: 'success'
            })

            temporaryvalues_table("temporaryvalues_table");
        }
        else{
            console.log("validation errors found.")
        }
    } 

    //declaring the sweet alert constant
    const MySwal = withReactContent(Swal)

    //below code is to emptying the input after insertion of record
    function emptyinputs(){

        setFormData({
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
          customer_mp_id: '',
          customer_centerwiseid: '',
          customer_name: '',
          customer_place: '',
          customer_mobile: '',
          prevbalrs: '',
          totpay: '',
          cash_recd: '',
          bank_recd: '',
          balance: '',
          opbal: '',
        });
    
        qtyInputRefs.current.forEach((inputRef) => {
          inputRef.value = ''; // or set it to 0 if you prefer
        });
    
        setTotalAmount(0);
    
        setProductData((prevProductData) => {
          return prevProductData.map((product) => ({
            ...product,
            qty: 0,
            amt: 0,
          }));
        });
        customer_centerwiseidRef.current.focus();
    }

      // Define a state variable to keep track of whether customer_centerwiseid input has been blurred
  const [customerCenterwiseIdBlurred, setCustomerCenterwiseIdBlurred] = useState(false);

  // Blur event handler for customer_centerwiseid
  const handleCustomerCenterwiseIdBlur = () => {
    setCustomerCenterwiseIdBlurred(true); // Set the flag to true when blurred
    getrecordsbycustomer_centerwiseid(formData.customer_centerwiseid);
  };

  //below code is used to place customer_centerwiseid of selected customer from datalist in its inputbox.
  // Select event handler for customer_name
  const handleDatalistSelect = (e) => {
    const selectedOption = e.target.value;
    const selectedCustomer = customer_name_data.find((option) => option.customer_name === selectedOption);
    
    if (selectedCustomer) {
      setFormData((prevData) => ({
        ...prevData,
        customer_centerwiseid: selectedCustomer.customer_centerwiseid,
      }));
      // Check if customer_centerwiseid has been blurred recently before calling the function
      if (!customerCenterwiseIdBlurred) {
        getrecordsbycustomer_centerwiseid(selectedCustomer.customer_centerwiseid, "setcustomer_centerwiseid");
      }
    }
    // Reset the flag after handling the select event
    setCustomerCenterwiseIdBlurred(false);
  };


    return (
        <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
            <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', fontSize: 20, cursor: 'default' }} className="text-center"> Sales </h1>
            <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
                <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
                    </li>
                </ul>
                <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
                </div>
            </nav>
        
            <form onSubmit={handleSubmit} method="POST" id="form1" >
                <div className="row" styles="background-color:lightgreen;">
                    <div className="row mb-3">
                        <div className="col-md-2">
                        <label htmlFor="id-transdate" className="form-label">Date</label>
                        <input type="date" className="form-control" name="transdate" id="id-transdate"  value={formData.transdate}  onChange={handleInputChange} required/>
                        </div>
                        <div className="col-md-1">
                            <label htmlFor="centerno" className="form-label">Center No</label>
                            <input type="number" className="form-control" name="centerno" value={formData.centerno}  onChange={handleInputChange} onBlur={centernoonblur} required/>
                            {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
                        </div>

                        <div className="col-md-2">
                        <label htmlFor="centername" className="form-label">Center</label>
                            <select
                                className="form-select"
                                name="centername"
                                onChange={handleInputChange}
                                disabled={
                                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                                }
                                >
                                {/* uom option from selected record when available */}                                    
                                {defaultcenter && (
                                <option key={defaultcenter} value={defaultcenter}>
                                    {getCenterNameById(defaultcenter)}
                                </option>
                                )}

                                {CenterMasterData.map((cp) => (
                                    defaultcenter !== cp.centerid ?
                                        <option key={cp.centerid} value={cp.centerid} >
                                        {getCenterNameById(cp.centerid)}
                                        </option>
                                    : null
                                ))}
                            </select>  
                        </div>
                        <input type="hidden" className="form-control" name="routecode" value={defaultroutename}  onChange={handleInputChange} placeholder='Type here' required />
                        <div className="col-md-1">
                            <label htmlFor="id-routename" className="form-label">Line No</label>                                
                            <select
                                name="routename"
                                className="form-select"
                                onChange={handleInputChange}
                                >
                                {defaultroutename && (
                                    <option key={defaultroutename} value={defaultroutename}>
                                    {defaultroutename}
                                    </option>
                                )}

                                {RouteMasterData.map((r) => (
                                    defaultroutename !== r.routename ?
                                        <option key={r.routename} value={r.routename} >
                                        {r.routename}
                                        </option>
                                    : null
                                ))}
                            </select>
                        </div>

                        <div className="col-md-2">
                            <label htmlFor="customer_centerwiseid" className="form-label">Customer No</label>
                            <input type="number" className="form-control" name="customer_centerwiseid" ref={customer_centerwiseidRef} value={formData.customer_centerwiseid} onChange={handleInputChange} 
                            onBlur={() => handleCustomerCenterwiseIdBlur()} placeholder='Type here' required />
                            <input type="hidden" className="form-control" name="customer_mp_id" value={formData.customer_mp_id} onChange={handleInputChange} />              
                            <input type="hidden" className="form-control" name="customer_place" value={formData.customer_place} onChange={handleInputChange} />              
                            <input type="hidden" className="form-control" name="customer_mobile" value={formData.customer_mobile} onChange={handleInputChange} />              
                            <input type="hidden" className="form-control" name="opbal" value={formData.opbal} onChange={handleInputChange} />                 
                        </div>   

                        <div className="col-md-4">
                            <label htmlFor="customer_name" className="form-label">Customer Name</label>

                            <input
                                type='text'
                                name='customer_name'
                                list='customer_name_list'
                                className='form-control'
                                value={formData.customer_name}
                                onChange={handleInputChange}
                                onSelect={handleDatalistSelect}
                                autoComplete='off' //to not show cache data in inputbox
                            />
                 
                            <datalist id="customer_name_list" >
                                {Array.isArray(customer_name_data) && customer_name_data.map((option) => ( 
                                <option key={option.customer_centerwiseid} value={option.customer_name} />
                                ))}
                            </datalist>

                        </div>
                    </div>                                     
                    
                    <div className="row mb-3">
                        <div className="col-md-8">
                            <div className="card" style={{borderColor:"rgb(66, 41, 203)", borderWidth:"2px", boxShadow: "10px 6px 9px rgb(62, 62, 62)"}}>
                            <h5 className="card-title" style={{textAlign: 'center',background:"rgb(66, 41, 203)",color:"white", padding: 5}}>Product Sale Details</h5>
                                <div className="card-body">                                    

                                    <div className="row mt-4">
                                        <div className="table-container" style={{ marginTop: -35, height: '400px', overflow: 'auto', borderWidth:'0px' }}>
                                            <table className="table table-striped" style={{borderCollapse:'separate', borderSpacing:'0 1em'}}>
                                            <tbody>
                                                
                                                {ProductMasterData.map((p, index) => (

                                                    <tr style={{marginTop:'50px', borderRadius:"30px", borderBottom:'20px',borderBottomRightRadius:"50px", borderLeftColor:"white", borderRightColor:"white", borderBlockColor:"white", borderWidth:'3px',boxShadow: "rgb(62, 62, 62) 20px 12px 13px 5px"}} key={p.productid}>
                                                    <td style={{backgroundColor: '#ffe11d',border: '15px',color: 'rgb(66, 41, 203)',borderTopLeftRadius: '15px',borderBottomLeftRadius: '15px'}}>
                                                        {p.productid}
                                                        <input type='hidden' name={`productid[${index}]`} value={p.productid} onChange={handleInputChange}></input>
                                                    </td>
                                                    {/* <td>{p.product_name}</td> */}
                                                    <td style={{backgroundColor: 'white',border: '15px',color: 'rgb(66, 41, 203)'}}>
                                                        <label htmlFor="product_name">{p.product_name}</label>
                                                        <input type='hidden' name={`product_name[${index}]`} value={p.product_name} onChange={handleInputChange}></input>
                                                        <input type='hidden' name={`product_category[${index}]`} value={p.product_category} onChange={handleInputChange}></input>
                                                        <br/>
                                                        {p.product_img && (
                                                        <img
                                                            src={p.product_img}
                                                            alt={`Sample Image for ${p.product_name}`}
                                                            style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: '10px' }}
                                                        />
                                                        )}              
                                                    </td>
                                                    
                                                    {/* <td style={{borderLeft:'0px',borderRight:'0px'}} name ="rate" > */}
                                                    <td style={{backgroundColor: 'white',border: '15px',color: 'rgb(66, 41, 203)'}} name ="rate">
                                                    <label htmlFor="rate">Rate Rs. {p.mrp}</label>
                                                    <input type='hidden' name={`rate[${index}]`} value={p.mrp}></input>
                                                    </td>

                                                    {/* <td><span style={{ float: 'right' }}> */}
                                                    <td style={{backgroundColor: 'white',border: '15px',color: 'rgb(66, 41, 203)',borderTopRightRadius: '15px',borderBottomRightRadius: '15px'}}> <span style={{backgroundColor: 'white',border: '15px',color: 'rgb(66, 41, 203)',borderTopRightRadius: '15px',borderBottomRightRadius: '15px'}}>
                                                        Qty&nbsp;
                                                        <input
                                                        type="number"
                                                        className="form-control"
                                                        name={`qty[${index}]`}
                                                        style={{ width: '125px', float: 'right', textAlign: 'right' }}
                                                        ref={(input) => (qtyInputRefs.current[index] = input)}
                                                        onChange={(event) => handleQtyChange(index, event)}
                                                        />
                                                    </span>
                                                    <br/><br/><a style={{float: 'right'}}>Unit : {p.uom}</a>
                                                    <br/><br/><a style={{float: 'right'}}>Amt: {productData[index].amt}<input type="hidden" style={{fontSize : '20px'}} name={`amt[${index}]`} className="form-control" value={productData[index].amt} onChange={handleInputChange} readOnly></input></a></td>
                                                    
                                                    </tr>
                                                    
                                                ))}
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* bill amt, prev bal, total pay, cash payment, bank name list, bank payment, balance remained, save */}
                        <div className="col-md-4">
                            <div className="card" style={{borderColor:"rgb(66, 41, 203)", borderWidth:"2px", boxShadow: "10px 6px 9px rgb(62, 62, 62)"}}>
                                <h5 className="card-title" style={{textAlign: 'center', background:"rgb(66, 41, 203)",color:"white", padding: 5}}>Payment Details</h5>
                                <div className="card-body" style={{ display: 'flex', flexDirection: 'column', height: '428px', marginTop: -10}}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15 }}>
                                        <span>Bill Amount:</span>
                                        {/* <span>{totalAmount}</span> */}
                                        <span style={{fontSize:"30px"}}>{totalAmount}</span>
                                        <input type='hidden' name="billamt" className="form-control" value={(Number(totalAmount)).toFixed(2)} readOnly></input>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop:15 }}>
                                        <span>Previous Balance Rs:</span>
                                        <span style={{fontSize:"20px", color:"red"}}>{formData.prevbalrs}</span>
                                        <input type='hidden' name="prevbalrs" className="form-control" value={(Number(formData.prevbalrs).toFixed(2))} onChange={handleInputChange} readOnly></input>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop:15 }}>
                                        <span>Total Amount: </span>
                                        <span>{((Number(formData.prevbalrs) + Number(totalAmount)).toFixed(2))} </span>
                                        <input type='hidden' name="totpay" className="form-control" value={((Number(formData.prevbalrs) + Number(totalAmount)).toFixed(2))} onChange={handleInputChange} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop:15 }}>
                                        <span>Cash Payment:</span>
                                        <input className="form-control" name="cash_recd" style={{width: '40%'}} onBlur={calculate_balance} onChange={handleInputChange} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop:15 }}>
                                    
                                        <select className='form-select' name='bankname' value={formData.bankname} onChange={handleInputChange} style={{width: '50%'}}>
                                            {defaultbank && (
                                                <option key={defaultbank} value={defaultbank}>
                                                {defaultbank}
                                                </option>
                                            )}

                                            {BankAccountData.map((option) => (
                                                defaultbank !== option.bankname ?
                                                <option key={option.bankname} value={option.bankname} >
                                                    {option.bankname}
                                                </option>
                                                : null
                                            ))}
                                        </select>
                                        <input className="form-control" style={{width: '40%'}} name="bank_recd" onBlur={calculate_balance} onChange={handleInputChange}/>

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop:15 }}>
                                        <span>Balance Remained:</span>
                                        <span>{Number(formData.balance).toFixed(2)}</span>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop:15 }}>
                                        <span></span>
                                        <button type="submit" className='btn btn-primary' style={{float: 'right'}}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    
                    <div className="row mb-3">
                        <button className="btn btn-primary" style={{marginRight: '5px'}} onClick={navigatetodailydelivery}>Back To Daily Order Sheet</button>
                    </div>
                </div>           
            </form>
        </div>
    );
}

export default Test2;