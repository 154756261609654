import React, { useEffect, useState } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';
import FormRange from 'react-bootstrap/esm/FormRange';

function ProductMaster() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  } 

  const MySwal = withReactContent(Swal)

  const [defaultUom, setDefaultUom] = useState();
  const [errors, seterrors] = useState({});

  const [formData, setFormData] = useState({
    CompanyId: '',
    productid: '',
    product_name: '',
    product_category: '',
    uom: '',
    purchase_rate: '',
    sales_rate: '',
    mrp: '',
    opqty: '',
    status: '',
    product_id_hidden: '',
    operationtype: 'Add Product'
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [UnitMasterData, setUnitMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
  if (UserMasterDataGlobal.length > 0) {
    axios.get(apireq05042024+`UnitMaster?CompanyId=${UserMasterDataGlobal?.[0]?.CompanyId}`)

      .then((response) => {
          // Access the response data directly using response.data
          if(UnitMasterData.length <= 0){
            setUnitMasterData(response.data.UnitMasterData);
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
  } 
  }, [UserMasterDataGlobal]);

  const [ProductMasterData, setProductMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(apireq05042024+'ProductMaster?orderby=DESC&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)

      .then((response) => {
        // Access the response data directly using response.data
        if(ProductMasterData.length <= 0){
          setProductMasterData(response.data.ProductMasterData);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    var validationerrors = {};

    if(formData.get('product_name').trim() !== '') {
    }else{
      validationerrors.product_name = "Please enter a valid Product Name.";
    } 

    if(formData.get('purchase_rate').trim() !== '') {
      if(!isNaN(formData.get('purchase_rate')) && parseFloat(formData.get('purchase_rate')) >= 0) {  
      }
      else{
        validationerrors.purchase_rate = "Please enter a valid positive numeric value for Purchase rate.";
      }
    }
    else{
    }

    if(formData.get('sales_rate').trim() !== '') {
      if(!isNaN(formData.get('sales_rate')) && parseFloat(formData.get('sales_rate')) >= 0) {  
      }
      else{
        validationerrors.sales_rate = "Please enter a valid positive numeric value for Sales rate.";
      }
    }
    else{
    }

    if(formData.get('mrp').trim() !== '') {
      if(!isNaN(formData.get('mrp')) && parseFloat(formData.get('mrp')) >= 0) {  
      }
      else{
        validationerrors.mrp = "Please enter a valid positive numeric value for mrp.";
      }
    }
    else{
    }

    if(formData.get('opqty').trim() !== '') {
      if(!isNaN(formData.get('opqty')) && parseFloat(formData.get('opqty')) >= 0) {  
      }
      else{
        validationerrors.opqty = "Please enter a valid positive numeric value for Opening qty.";
      }
    }
    else{
    }

    seterrors(validationerrors);

    if(Object.keys(validationerrors).length == 0) {
        
        var data = {
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
          userid: UserMasterDataGlobal?.[0]?.userid,
          product_name: formData.get('product_name').trim(),
          product_category: formData.get('product_category'),
          uom: formData.get('uom'),
          purchase_rate: formData.get('purchase_rate').trim(),
          sales_rate: formData.get('sales_rate').trim(),
          mrp: formData.get('mrp').trim(),
          opqty: formData.get('opqty').trim(),
          status: formData.get('status'),
          operationtype: formData.get('operationtype'),
          productid: formData.get('productid')
        };

      if(data.operationtype == 'Add Product'){

        const response = await axios.post(apireq05042024+'ProductMaster/insert', data);

        if (response.status === 200) {
          if(!isNaN(+response.data)){
              MySwal.fire({
              html: <b>Record Saved Sucessfully!</b>,
              icon: 'success'
              })
              data.clqty = data.opqty;
              const newDataItem = {
                ...data,
                productid: response.data,
              };
      
              setProductMasterData((prevData) => {
                return [newDataItem, ...prevData];
              });

              emptyinputs();
          } 

        }
      }
      else
      {
        const response = await axios.post(apireq05042024+'ProductMaster/update', data);
        // Handle the response
        if (response.status === 200) {
              if(response.data.message==="Success"){
                  MySwal.fire({
                  //   title: <strong>Record Saved Sucessfully!</strong>,
                  html: <b>Record updated Sucessfully!</b>,
                  icon: 'success'
                  })

                  data.clqty = response.data.clqty;
                  setProductMasterData((prevData) => {
                    const updatedData = [...prevData];
                    const index = updatedData.findIndex((record) => record.productid == data.productid);

                    if (index !== -1) {
                      updatedData[index] = { ...updatedData[index], ...data };
                    }
                    return updatedData;
                  });
                  emptyinputs();
              }
              else if (response.data==="failed"){
                MySwal.fire({
                //   title: <strong>Record Saved Sucessfully!</strong>,
                  html: <b>Update failed or no matching records found!</b>,
                  icon: 'error'
                })                
              }    
              
              // console.log(response.data);
          } else {

          }
      }
    }
    else{
      console.log("Validation error exists");
    }
  } 

  function confirmDelete(event, id) {
      event.preventDefault();

    MySwal.fire({
        title: 'Are you sure you want to delete the record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            if (result.value) {
            // window.location.href = $(this).attr('href');
            deleteData(id);
            }
        }
    })
  }

  async function deleteData(id) {
  
    const response = await axios.delete(apireq05042024+`ProductMaster/delete/${id}/${UserMasterDataGlobal?.[0]?.CompanyId}`);
  
    if (response.status === 200) {
      // The record was deleted successfully.
    //   console.log('Record deleted successfully!');
        console.log(response.data);
        if(response.data==="Sucessfully deleted"){
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record deleted Sucessfully!</b>,
            icon: 'success'
            })
            setProductMasterData((prevData) => prevData.filter((record) => record.productid !== id));
            emptyinputs();
        }
        else if(response.data==="found"){
          MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>This product has transactions; so can't delete this account</b>,
                icon: 'warning'
            })
        }
    } else {
      // An error occurred while deleting the record.
    //   console.error('Error deleting record:', response.error);
        MySwal.fire({
        //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record not deleted! Please try once again or call the developer</b>,
            icon: 'error'
        })
    }
  }

  function getdatabyrecordarray(record) {
    // console.log(record.customer_mp_id);
    setDefaultUom(record.uom);

    setFormData({
      CompanyId: record.CompanyId,
      productid: record.productid,
      product_name: record.product_name,
      product_category: record.product_category,
      uom: record.uom,
      purchase_rate: record.purchase_rate,
      sales_rate: record.sales_rate,
      mrp: record.mrp,
      opqty: record.opqty,
      status: record.status,
      operationtype: 'Edit Customer'
    });
     // following code is to go to top of the page after clicking on edit button.
     window.scrollTo(0, 0)
  }
  
  const product_category_array = ['Cow Milk', 'Buff Milk', 'Milk Products'];

  // Check your condition here, e.g., if the selected option is 'Wholesaler'
  const product_category_condition = formData.product_category ;

  // Create a copy of the options array and reorder it based on the condition
  const product_category_options = [...product_category_array];
  if (product_category_condition) {
    // If the condition is true, reorder the options (put 'Inactive' first)
    const inactiveIndex2 = product_category_options.indexOf(formData.product_category);
    product_category_options.splice(inactiveIndex2, 1); // Remove 'Inactive'
    product_category_options.unshift(formData.product_category); // Add 'Inactive' at the beginning
  }

  const status_array = ['ACTIVE', 'DEACTIVE'];

  // Check your condition here, e.g., if the selected option is 'DAIRY-OWNER'
  const status_condition = formData.status;

  // Create a copy of the options array and reorder it based on the condition
  const status_options = [...status_array];
  if (status_condition) {
    // If the condition is true, reorder the options (put 'Inactive' first)
    const inactiveIndex3 = status_options.indexOf(formData.status);
    status_options.splice(inactiveIndex3, 1); // Remove 'Inactive'
    status_options.unshift(formData.status); // Add 'Inactive' at the beginning
  }

  function emptyinputs(){
    setFormData({
      productid: '',
      product_name: '',
      product_category: '',
      uom: '',
      purchase_rate: '',
      sales_rate: '',
      mrp: '',
      opqty: '',
      status: '',
      product_id_hidden: '',
      operationtype: 'Add Product'
    });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 50;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = ProductMasterData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(ProductMasterData.length / recordsPerPage);
  //const numbers = [...Array(npage + 1).keys()].slice(1);

  const getVisiblePageNumbers = (currentPage, totalPages) => {
    // Handle cases with 50 or fewer pages
    if (totalPages <= 50) {
      return [...Array(totalPages + 1).keys()].slice(1);
    }
  
    // Handle cases with more than 5 pages
    const visiblePages = [1, 2, currentPage, totalPages - 1, totalPages];
    let gap = false;
    const finalNumbers = [];
  
    for (let i = 1; i <= totalPages; i++) {
      if (visiblePages.includes(i)) {
        finalNumbers.push(i);
        gap = false;
      } else if (!gap) {
        finalNumbers.push('...');
        gap = true;
      }
    }
  
    return finalNumbers;
  };
  
  const numbers = getVisiblePageNumbers(currentPage, npage);

  function navigatetologin() {
    localStorage.removeItem('usertimestamp');
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  }

  return (

    <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default',fontSize: 20 }} className="text-center"> Product Master</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>

    <div className="row" styles="background-color:lightgreen;">
      <div className="col-md-6 offset-md-3">
        <form onSubmit={handleSubmit} method="POST" id="form1">
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="id-product_name" className="form-label">Product Name</label>
              <input type="text" className="form-control" name="product_name" id="id-product_name"  value={formData.product_name}  onChange={handleInputChange} placeholder='Type here' />
              {errors.product_name && <span style={{color: 'red', fontSize: '10px'}}>{errors.product_name}</span>}
            </div>
            <div className="col-md-4">
              <label htmlFor="id-product_category" className="form-label">Product Category</label>
              <select
              name="product_category"
              className="form-select"
              id="id-product_category"
              value={formData.product_category}
              onChange={handleInputChange} >
              {product_category_options.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="id-uom" className="form-label">UOM</label>
              
                <select
                  className="form-select"
                  id="id-uom"
                  name="uom" // <-- should match `formData.uom` property
                  value={formData.uom}
                  onChange={handleInputChange}
                >
                {/* uom option from selected record when available */}
                {defaultUom && (
                  <option value={defaultUom}>
                    {defaultUom}
                  </option>
                )}

                {/* dynamically fetched options */}
                {UnitMasterData.map((umd) => (
                  defaultUom !== umd.unitname ?
                    <option key={umd.unitname} value={umd.unitname}>
                      {umd.unitname}
                    </option>
                  : null
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="id-purchase_rate" className="form-label">Purchase Rate</label>
              <input type="text" className="form-control" name="purchase_rate" id="id-purchase_rate" value={formData.purchase_rate} onChange={handleInputChange} placeholder='0.00' />
              {errors.purchase_rate && <span style={{color: 'red', fontSize: '10px'}}>{errors.purchase_rate}</span>}
            </div>
            <div className="col-md-4">
              <label htmlFor="id-sales_rate" className="form-label">Sales Rate</label>              
              <input type="text" className="form-control" name="sales_rate" id="id-sales_rate" value={formData.sales_rate} onChange={handleInputChange} placeholder='0.00' />
              {errors.sales_rate && <span style={{color: 'red', fontSize: '10px'}}>{errors.sales_rate}</span>}
            </div>
            <div className="col-md-4">
              <label htmlFor="id-mrp" className="form-label">MRP Rs.</label>
              <input type="text" className="form-control" name="mrp" id="id-mrp" value={formData.mrp} onChange={handleInputChange} placeholder='0.00' />
              {errors.mrp && <span style={{color: 'red', fontSize: '10px'}}>{errors.mrp}</span>}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="id-opqty" className="form-label">Op. Qty</label>
              <input type="text" className="form-control" name="opqty" id="id-opqty" value={formData.opqty} onChange={handleInputChange} placeholder='Type here' />
              {errors.opqty && <span style={{color: 'red', fontSize: '10px'}}>{errors.opqty}</span>}
            </div>
            <div className="col-md-4">
              <label htmlFor="id-status" className="form-label">Status</label>
              <select
              name="status"
              className="form-select"
              id="id-status"
              value={formData.status}
              onChange={handleInputChange} >
              {status_options.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
              </select>
            </div>
          </div>
          
          <input type="hidden" className="form-control" name="productid" id="id-productid" value={formData.productid} required />
          <input type="hidden" className="form-control" name="operationtype" id="id-operationtype" value={formData.operationtype} required />

          {/* <button type="submit" className="btn btn-primary">{formData.operationtype == 'Add Product' ? 'Add Product' : 'Edit Product'}</button> */}
          {(formData.product_name !== "Cow Milk" && formData.product_name !== "Buff Milk") && (
            <button type="submit" className="btn btn-primary">
              {formData.operationtype === 'Add Product' ? 'Add Product' : 'Edit Product'}
            </button>
          )}
        </form>
      </div>
    </div>

    <div className="row mt-4">
      <div className="col-md-12 table-responsive-xl">
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{backgroundColor:'#009879', color:"white", width: '10%'}}>Prod. Code</th>
              <th style={{backgroundColor:'#009879', color:"white"}}>Production Name</th>
              <th style={{backgroundColor:'#009879', color:"white"}}>Category</th>
              <th style={{backgroundColor:'#009879', color:"white"}}>UOM</th>
              <th style={{backgroundColor:'#009879', color:"white", textAlign: 'right'}}>Op Qty</th>
              <th style={{backgroundColor:'#009879', color:"white", textAlign: 'right'}}>Cl Qty</th>
              <th style={{backgroundColor:'#009879', color:"white"}}>Action</th>
            </tr>
          </thead>
          <tbody>
          {records.map((p, index) => (
            
          <tr key={p.productid}>
            <td>{p.productid}</td>
            <td>{p.product_name}</td>
            <td>{p.product_category}</td>
            <td>{p.uom}</td>
            <td style={{textAlign: 'right'}}>{p.opqty}</td>
            <td style={{textAlign: 'right'}}>{p.clqty}</td>
            <td>{(p.product_name !== "Cow Milk" && p.product_name !== "Buff Milk") && (
              <>
              <button onClick={() => getdatabyrecordarray(p)} className="btn btn-info btn-sm">Edit</button>
              {/* <Link to={generatePath(routes.customerDetails, { customerid1: c.customer_mp_id })}  className="btn btn-success btn-sm">edit</Link> */}
              
              &nbsp;<button onClick={(event) => confirmDelete(event, p.productid)} className="btn btn-danger btn-sm ml-1">
              Delete
              </button>
              </>
              )}
            </td>
          </tr>
          ))}
          </tbody>
        </table>

        <nav style={{float: 'right' }}>
          <ul className='pagination'>
              <li className='page-item'>
                <a href='#' className='page-link'
                onClick={prePage}>Prev</a>
              </li>

              {/* {
                numbers.map((n, i) => (
                  <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                    <a href='#' className='page-link'
                    onClick={() => ChangeCpage(n)}>{n}</a>
                  </li>
                ))
              } */}

              {
                numbers.map((n, i) => (
                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                  {n === '...' ? (
                    <span className="page-link disabled">...</span>
                  ) : (
                    <a href="#" className="page-link" onClick={() => ChangeCpage(n)}>
                      {n}
                    </a>
                  )}
                </li>
              ))}

              <li className='page-item'>
                <a href='#' className='page-link'
                onClick={nextPage}>Next</a>
              </li>
          </ul>
        </nav>

      </div>
    </div>
  </div>
  );
  
  function prePage() {
    if(currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function ChangeCpage(id) {
    setCurrentPage(id)
  }

  function nextPage() {
    if(currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }
  }


}

export default ProductMaster;
