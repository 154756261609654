import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function CustomerWiseSalesEntryCard() {
  
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  } 
    
    //This handlesubmit code is for form submission but it is never used because for every button
    //in this page we use "onclick" attribute to call javascript function.
    const handleSubmit = async (event) => {
      event.preventDefault();
      
      const formData = new FormData(event.target);
      var validationerrors = {};

      if(defaultroutename.trim() !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 
      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {
        // alert('no validation message');
        // return;
        var data = {
            centerno: formData.get('centerno'),
            routecode: formData.get('routecode'),
            bookdate: formData.get('bookdate'),
            CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
        };       

        var response  = "";
        response = await axios.post(apireq05042024+'DailyDeliverySheet', data);
        // console.log(response.data);
        // return;
        // setCustomerData([]);
        if (response.status === 200) {
          // setCustomerData([]);  
          setCustomerData(response.data.customer_data);
          // console.log(CustomerData);          
        }        
      }  
    }

    // We declare the 'errors' state to store validation conditions met, associating them with respective field names as keys to display below input boxes and select boxes.
    const [errors, seterrors] = useState({});
    const [CustomerData, setCustomerData] = useState([]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };
    
      if(name == "centerid") {
        const [centerid, centername] = value.split('-');
        updatedFormData.centerno = centerid;
        setdefaultcenter(centerid);
        // getroutemasterdata(centerid, 'd6');
        getroutemasterdatabycenterid(centerid, "centername");
      }

      if(name == "routename") {
        const [routecode, routename] = value.split('-');
        // updatedFormData.routecode = routecode;
        setdefaultroutename(routecode);
        fetchcustomer_name_data(formData.centerno, routecode);
      }

      if(name == "bookdate") {

        const foundArray = CustomerData.find(item => item.tran_date === value);

        if(foundArray != undefined){ 
          updatedFormData.cowqty_edit = foundArray.cowqty;
          updatedFormData.cowrate_edit = foundArray.cowrate;
          updatedFormData.buffqty_edit = foundArray.buffqty;
          updatedFormData.buffrate_edit = foundArray.buffrate;
          updatedFormData.transactionid_edit = foundArray.transaction_id;
        }
        else {
          updatedFormData.cowqty_edit = '';
          updatedFormData.cowrate_edit = '';
          updatedFormData.buffqty_edit = '';
          updatedFormData.buffrate_edit = '';
          updatedFormData.transactionid_edit = '';
        }

      }

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      centerno: '',
      routecode: '',
      bookdate: '',
      fromdate: '',
      todate: '',
      customer_centerwiseid: '',
      centername: '',
      routename: '',
      centerid: '',
      customer_name: '',
      cowqty_edit: '',
      cowrate_edit: '',
      cowqty_edit2: '',
      buffqty_edit: '',
      buffrate_edit: '',
      buffqty_edit2: '',
      transactionid_edit: '',
      customer_mp_id: '',
    });

    const [defaultcenter, setdefaultcenter] = useState();
    const [CenterMasterData, setCenterMasterData] = useState([]);
    useEffect(() => {
      if (UserMasterDataGlobal.length > 0) {
        axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)  
        .then((response) => {
        if(!defaultcenter){
          setCenterMasterData(response.data.CenterMasterData);
          setdefaultcenter(response.data.CenterMasterData[0].centerid);
          setFormData({
            ...formData,
            centerno: response.data.CenterMasterData[0].centerid,
            bookdate: getCurrentDate(),
          })
          getroutemasterdata(response.data.CenterMasterData[0].centerid, '2d');
        }

        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const getCenterNameById = (centerid) => {
      const center = CenterMasterData.find(center => center.centerid === centerid);
      return center ? center.centername : '';
    };

    const [defaultroutename, setdefaultroutename] = useState();
    const [RouteMasterData, setRouteMasterData] = useState([]);

    const routecodeonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;
  
      if (value.includes('-')) {
        // Split the value based on '-'
        const [routecode, routename] = value.split('-');
        value = routecode;
      }
  
      if(value == ''){
        return;
      }
  
      const response = await axios.get(apireq05042024+'RouteMaster/getroutenamebyroutecode?routecode='+value+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)
   
      .then((response) => {
        // Access the response data directly using response.data       
        try {
          // formData.routename = response.data.customer_name_data[0].routename;
          setdefaultroutename(response.data.routenamedata[0].routename);
        }
        catch{

        }
        
      });   
    };
  
    const centernoonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;

      if(value == ''){
        return;
      }
      setdefaultcenter(value);
      formData.centerid = value;
      // getroutemasterdata(value, 'd1');
      getroutemasterdatabycenterid(value);
      fetchcustomer_name_data(value,defaultroutename); 
    };

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        bookdate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    function confirmDelete(event, id) {
      event.preventDefault();

      MySwal.fire({
          title: 'Are you sure you want to delete the transaction record?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
          if (result.isConfirmed) {
              if (result.value) {
              // window.location.href = $(this).attr('href');
              deleteData(id);
              }
          }
      })
  }

  async function deleteData(id) {
    
    const response = await axios.delete(apireq05042024+`OtherController/delete/${id}/${UserMasterDataGlobal?.[0]?.CompanyId}`);
    
    if (response.status === 200) {
        if(response.data==="Sucessfully deleted") {
            MySwal.fire({
            html: <b>Record deleted Sucessfully!</b>,
            icon: 'success'
            })

            setCustomerData((prevData) => prevData.filter((record) => record.transaction_id !== id));
          
          } else if(response.data == "found") {
          MySwal.fire({
              html: <b>This cusomer has transactions; so can't delete this account</b>,
              icon: 'warning'
          })        
        } 
  } else {
        MySwal.fire({
            html: <b>Record not deleted! Please try once again or call the developer</b>,
            icon: 'error'
        })
    }
  }

  async function viewonclick (event) {
      event.preventDefault();     
      
      ShowCustomerData();
      
  }

  async function ShowCustomerData(toshowcustomermasterdata = null) {
    
      var validationerrors = {};

      if(defaultroutename !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 

      if(formData.centerno !== '') {
      }else{
        validationerrors.centerno = "Please select prefered center from list or type its number here";
      } 

      if(formData.customer_centerwiseid !== '') {
      }else{
        validationerrors.customer_centerwiseid = "Please select prefered customer from list or type its number here";
      } 

      if(formData.fromdate !== '') {
      }else{
        validationerrors.fromdate = "Please select prefered from date from datepicker";
      }

      if(formData.todate !== '') {
      }else{
        validationerrors.todate = "Please select prefered to date from datepicker";
      }

      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {

        var data = {
          customer_centerwiseid: formData.customer_centerwiseid,
          centerno:formData.centerno,
          routecode: defaultroutename,
          fromdate: formData.fromdate || getCurrentDate(),
          todate: formData.todate || getCurrentDate(),
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          userid: UserMasterDataGlobal?.[0]?.userid || "",
          customer_centerwiseid: formData.customer_centerwiseid,
        };

        setFormData({
          ...formData,
          centerno: data.centerno,
          routecode: data.routecode,
          fromdate: data.fromdate,
          todate: data.todate,
          customer_centerwiseid: data.customer_centerwiseid
        });

        setdefaultcenter(data.centerno);
        setdefaultroutename(data.routecode);

        try {          
                   
          if(toshowcustomermasterdata !== "notshow") {
            var response  = "";
      
            response = await axios.post(apireq05042024+'CustomerWiseSalesEntryCard/ShowCustomerData', data);
            setCustomerData([]);     
            if (response.status === 200) {   
                if(response.data.customerData.length >0) {
                  setCustomerData(response.data.customerData);          
                }       
            } 
            
          }

        } catch {

        } 

      }  
  }

  async function addbulkentries() {

    const centerno = formData.centerno;
    const routecode = formData.routecode;
    const fromdate = formData.fromdate;
    const todate = formData.todate;
    const customer_centerwiseid = formData.customer_centerwiseid;

    const data = {
      centerno: centerno,
      routecode: routecode,
      fromdate: fromdate,
      todate: todate,
      customer_centerwiseid: customer_centerwiseid,
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
      cowqty: formData.cowqty_edit,
      cowrate: formData.cowrate_edit,
      cowqty2: formData.cowqty_edit2,
      buffqty: formData.buffqty_edit,
      buffrate: formData.buffrate_edit,
      buffqty2: formData.buffqty_edit2,
      userid: UserMasterDataGlobal?.[0]?.userid,
    };

    var response  = "";
    response = await axios.post(apireq05042024+'CustomerWiseSalesEntryCard/BulkCreation', data);

    // setCustomerData([]);
    if (response.status === 200) {
      if(response.data.message == "Records saved succesfully") {
        MySwal.fire({
          icon: 'success',
          title: 'Successful!',
          text: `${response.data.message}`
        });
        ShowCustomerData();
      }else if (response.data.message == "No results found.") {
        MySwal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: `${response.data.message}`
        });
      }else if(response.data.message == "The user has no cow or buff quantity."){
        MySwal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: `${response.data.message}`
        });
      }    
    }
  }

  function deletealltransactionrecords (event) {
    event.preventDefault();
    
    var validationerrors = {};

    if(defaultroutename !== '') {
    }else{
      validationerrors.routecode = "Please select prefered line no from list.";
    } 

    if(formData.centerno !== '') {
    }else{
      validationerrors.centerno = "Please select prefered center from list or type its number here";
    } 

    if(formData.customer_centerwiseid !== '') {
    }else{
      validationerrors.customer_centerwiseid = "Please select prefered customer from list or type its number here";
    } 

    if(formData.fromdate !== '') {
    }else{
      validationerrors.fromdate = "Please select prefered from date from datepicker";
    }

    if(formData.todate !== '') {
    }else{
      validationerrors.todate = "Please select prefered to date from datepicker";
    }

    seterrors(validationerrors);

    if(Object.keys(validationerrors).length !== 0) {
      return;
    }


    MySwal.fire({
      title: 'This action will affect data for a particular duration of transactions for above customer. Confirm?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, continue!'
    }).then((result) => {
        if (result.isConfirmed) {
            if (result.value) {
            // window.location.href = $(this).attr('href');
              deleteData1()
            }
            else{
              return;
            }
        }
    })
  }

  async function deleteData1() {

    const centerno = formData.centerno;
    const routecode = formData.routecode;
    const fromdate = formData.fromdate;
    const todate = formData.todate;
    const customer_centerwiseid = formData.customer_centerwiseid;

    const data = {
      centerno: centerno,
      routecode: routecode,
      fromdate: fromdate,
      todate: todate,
      customer_centerwiseid: customer_centerwiseid,
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
      customer_mp_id: formData.customer_mp_id,
    };

    var response  = "";
    response = await axios.post(apireq05042024+'CustomerWiseSalesEntryCard/deleteall1', data);

    if (response.status === 200) {
      if(response.data=="Successfully deleted") {
        MySwal.fire({
          html: <b>Record deleted Sucessfully!</b>,
          icon: 'success'
        })
        setCustomerData([]);       
      }
    }
  }
    
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {      
      const userIdapireq = UserMasterDataGlobal[0]?.userid;
      const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;      
      const apirequest = apireq05042024+`DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;

      axios.get(apirequest)
        .then((response) => {
          try {
            if(!defaultcenter){
              setdefaultroutename(response.data[0].text3);
              setdefaultcenter(response.data[0].text1);
              setFormData({
                ...formData,
                routecode: response.data[0].text3,
                centerno: response.data[0].text1,
                bookdate: response.data[0].text5,
              });
              fetchcustomer_name_data(response.data[0].text1, response.data[0].text3);
            }
          }
          catch {}     
        })
        .catch((error) => console.error('Error fetching route data:', error));
    }
  }, [UserMasterDataGlobal]);

  async function getroutemasterdata(centerid, rr) {
    // console.log(rr);
    var data1 = {
      centerid : centerid,
      CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    // console.log(data1);
    var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
      setdefaultroutename(response1.data[0].routename);
      setRouteMasterData(response1.data);
    })
  }

  //below code is for GenerateAndDeleteAgain checkbox
  const [isCheckedGenerateAndDeleteAgain, setisCheckedGenerateAndDeleteAgain] = useState(false);
  const handleCheckboxChange = () => {
    setisCheckedGenerateAndDeleteAgain(!isCheckedGenerateAndDeleteAgain);
  };

  //this is the array mounted for populating customer_name list in its selectbox
  const [customer_name_data,  setcustomer_name_data] =  useState([]);

  //This function is for customer_centerwiseid onblur and customer_name selectbox value change
  async function getrecordsbycustomer_centerwiseid_edit(customer_centerwiseid=null, setcustomer_centerwiseid_edit=null) {

    if(customer_centerwiseid == ''){
      return;
    }
    
    const axiosreqtoapi = apireq05042024+'CustomerWiseSalesEntryCard/getrecordbybycustomer_centerwiseid?customer_centerwiseid='+customer_centerwiseid+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId
    +'&centerid='+formData.centerno+'&routename='+defaultroutename;
   
    await axios.get(axiosreqtoapi)
    
    .then((response) => {
    
      if(response.data.CustomerMasterData.length > 0){
        //below code is to see if the array we got in response is present already in customer_name_data array.

        const index = customer_name_data.findIndex(item =>
            item.customer_mp_id === response.data.CustomerMasterData[0].customer_mp_id
        );

        if(response.data.CustomerMasterData[0].customer_type !== "Alternate Subscription"){
          setAlternateSubscriptionVisible(true); // Change to false when value is not "Alternate Subscription"
         
        } else {
          setAlternateSubscriptionVisible(false);
          
        }

        // If response array already exists in customer_name_data, move it to the first index
        if (index !== -1) {                
            const removedArray = customer_name_data.splice(index, 1)[0];        
            // Then insert it at the beginning
            const newData = [removedArray, ...customer_name_data];
            setcustomer_name_data(newData);

            if(setcustomer_centerwiseid_edit=="setcustomer_centerwiseid_edit") {
                 //putting the existing array customer_name to the value of select box
                setFormData({
                    ...formData,
                    customer_centerwiseid: response.data.CustomerMasterData[0].customer_centerwiseid,
                    customer_name: response.data.CustomerMasterData[0].customer_name,
                    cowqty_edit: response.data.CustomerMasterData[0].cowratib_qty,
                    cowrate_edit: response.data.CustomerMasterData[0].cowratib_rate,
                    cowqty_edit2: response.data.CustomerMasterData[0].cowratib_qty2,
                    buffqty_edit: response.data.CustomerMasterData[0].buffratib_qty,
                    buffrate_edit: response.data.CustomerMasterData[0].buffratib_rate,
                    buffqty_edit2: response.data.CustomerMasterData[0].buffratib_qty2,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                  });
                  
            }
            else{
                setFormData({
                    ...formData,
                    customer_name: response.data.CustomerMasterData[0].customer_name,
                    cowqty_edit: response.data.CustomerMasterData[0].cowratib_qty,
                    cowrate_edit: response.data.CustomerMasterData[0].cowratib_rate,
                    cowqty_edit2: response.data.CustomerMasterData[0].cowratib_qty2,
                    buffqty_edit: response.data.CustomerMasterData[0].buffratib_qty,
                    buffrate_edit: response.data.CustomerMasterData[0].buffratib_rate,
                    buffqty_edit2: response.data.CustomerMasterData[0].buffratib_qty2,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                });
            }               

        } else {
            // If it doesn't exist, push it normally
            setcustomer_name_data(response.data.CustomerMasterData);

            if(setcustomer_centerwiseid_edit=="setcustomer_centerwiseid_edit") {
                //putting the existing array customer_name to the value of select box
               setFormData({
                   ...formData,
                    customer_centerwiseid: response.data.CustomerMasterData[0].customer_centerwiseid,
                    customer_name: response.data.CustomerMasterData[0].customer_name,
                    cowqty_edit: response.data.CustomerMasterData[0].cowratib_qty,
                    cowrate_edit: response.data.CustomerMasterData[0].cowratib_rate,
                    cowqty_edit2: response.data.CustomerMasterData[0].cowratib_qty2,
                    buffqty_edit: response.data.CustomerMasterData[0].buffratib_qty,
                    buffrate_edit: response.data.CustomerMasterData[0].buffratib_rate,
                    buffqty_edit2: response.data.CustomerMasterData[0].buffratib_qty2,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
               });
            }
            else{
              setFormData({
                ...formData,
                customer_name: response.data.CustomerMasterData[0].customer_name,
                cowqty_edit: response.data.CustomerMasterData[0].cowratib_qty,
                cowrate_edit: response.data.CustomerMasterData[0].cowratib_rate,
                cowqty_edit2: response.data.CustomerMasterData[0].cowratib_qty2,
                buffqty_edit: response.data.CustomerMasterData[0].buffratib_qty,
                buffrate_edit: response.data.CustomerMasterData[0].buffratib_rate,
                buffqty_edit2: response.data.CustomerMasterData[0].buffratib_qty2,
                customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
              });
            }
        }
      } else {
        //If no data from API then clear customer_centerwiseid field and apologies message
        if(formData.customer_centerwiseid !== '') {
            alert("No transactions found for this Customer CenterWise ID...");
            setFormData({
                ...formData,
                customer_centerwiseid: '',
                cowqty_edit: '',
                cowrate_edit: '',
                cowqty_edit2: '',
                buffqty_edit: '',
                buffrate_edit: '',
                buffqty_edit2: '',
                customer_mp_id: '',
            });                
        }        
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });    
  }

    //below function is for putting data in customer_name_data array
    async function  fetchcustomer_name_data(centerid, routecode) {
      
      setcustomer_name_data([]);

      var axiosreqtoapi = apireq05042024+'CustomerMaster/getCustomerDataWithCenterNoAndRouteCode?routecode='+routecode
      +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid;
      
      const response = axios.get(axiosreqtoapi)

      .then((response) => {
          if(response.data.customer_name_data.length > 0) {
              setcustomer_name_data(response.data.customer_name_data);
          }
      })
      .catch((error) => {
          console.error('Error fetching data:', error);
      });
    }

    //to get the data from centerid/centerno we use this function
    async function getroutemasterdatabycenterid(centerid, rr=null) {
      var data1 = {
          centerid : centerid,
          CompanyId : UserMasterDataGlobal[0]?.CompanyId,
      }
      var response1 = axios.get(apireq05042024+`RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
      .then((response1) => {
          setdefaultroutename(response1.data[0].routename);
          setRouteMasterData(response1.data);
          if(rr=="centername") {
              //populating customer_name list using customer_name_data array by calling below function.
              //i put below function here because when page first time renders and we try to change
              //centername from selectbox. it is trying to take routename of previous center.
              fetchcustomer_name_data(centerid,response1.data[0].routename);
          }
      })
  }

    async function ShortEdit() {
      
      var data = {
        CompanyId: UserMasterDataGlobal?.[0].CompanyId,
        userid: UserMasterDataGlobal?.[0].userid,
        tran_date: formData.bookdate,
        cowqty: formData.cowqty_edit,
        cowrate: formData.cowrate_edit,
        cowamt: formData.cowqty_edit * formData.cowrate_edit,
        buffqty: formData.buffqty_edit,
        buffrate: formData.buffrate_edit,
        buffamt: formData.buffqty_edit * formData.buffrate_edit,
        transactionid: formData.transactionid_edit,
        customer_mp_id: formData.customer_mp_id,
      };

      if(data.transactionid == '') {
        alert('Please choose the record you wish to edit from the table, or select a date for which you want to edit a record.');
        return;
      }

      const response = await axios.post(apireq05042024+'CustomerWiseSalesEntryCard/ShortEdit', data);
      if (response.status === 200) {
        if(response.data=="Successfully Updated") {
          MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record Updated Sucessfully!</b>,
            icon: 'success'
          })
          
          setCustomerData((prevData) => {
            const updatedData = [...prevData];
            const index = updatedData.findIndex((record) => record.transaction_id == data.transactionid);

            if (index !== -1) {
              updatedData[index] = { ...updatedData[index], ...data };
            }           
              
            return updatedData;
          });

          EmptyInputsAfterEdit();

        }
      }
    }

    function EmptyInputsAfterEdit() {

      setFormData((prevData) => ({
        ...prevData,
        cowqty_edit: '',
        cowrate_edit: '',
        cowqty_edit2: '',
        buffqty_edit: '',
        buffrate_edit: '',
        buffqty_edit2: '',
      }));
    }

  // Function to get the start date of the current month
  const getStartOfMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
    const startDate = year + '-' + (month < 10 ? '0' : '') + month + '-01';
    return startDate;
  };

  // Function to get the end date of the current month
  const getEndOfMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
    const lastDay = new Date(year, month, 0).getDate(); // Get the last day of the current month
    const endDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + lastDay;
    return endDate;
  };

  // Check if fromdate is empty, if yes then set it to current month's start date
  if (formData.fromdate === '') {
    setFormData({
      ...formData,
      fromdate: getStartOfMonth(),
      todate: getEndOfMonth()
    });
  }

  function getdatabyrecordarray(record) {
    
    // if(formData.subscription_type == "Alternate Subscription") {
    //   const tranDate = new Date(record.tran_date);
    //   const day = tranDate.getDate(); // Extract day from the date
    //   console.log(day % 2);
    //   if (day % 2 == 0) {
        
    //     setFormData((prevData) => ({  
    //       ...prevData,
    //       bookdate: record.tran_date,
    //       cowqty_edit2 : record.cowqty,
    //       cowrate_edit : record.cowrate,
    //       buffqty_edit2 : record.buffqty,
    //       buffrate_edit : record.buffrate,
    //       transactionid_edit : record.transaction_id,
    //     }));
    //   }
    //   else{
    //     setFormData((prevData) => ({  
    //       ...prevData,
    //       bookdate: record.tran_date,
    //       cowqty_edit : record.cowqty,
    //       cowrate_edit : record.cowrate,
    //       buffqty_edit : record.buffqty,
    //       buffrate_edit : record.buffrate,
    //       transactionid_edit : record.transaction_id,
    //     }));
    //   }

    // }
    // else {
    //   setFormData((prevData) => ({  
    //     ...prevData,
    //     bookdate: record.tran_date,
    //     cowqty_edit : record.cowqty,
    //     cowrate_edit : record.cowrate,
    //     buffqty_edit : record.buffqty,
    //     buffrate_edit : record.buffrate,
    //     transactionid_edit : record.transaction_id,
    //   }));
    // }

    setFormData((prevData) => ({  
      ...prevData,
      bookdate: record.tran_date,
      cowqty_edit : record.cowqty,
      cowrate_edit : record.cowrate,
      buffqty_edit : record.buffqty,
      buffrate_edit : record.buffrate,
      transactionid_edit : record.transaction_id,
    }));
  }

  //below function when user clicks on Bulk Creation button.
  async function BulkCreation(event) {
    event.preventDefault();

    var validationerrors = {};

    if(defaultroutename !== '') {
    }else{
      validationerrors.routecode = "Please select prefered line no from list.";
    } 

    if(formData.centerno !== '') {
    }else{
      validationerrors.centerno = "Please select prefered center from list or type its number here";
    } 

    if(formData.customer_centerwiseid !== '') {
    }else{
      validationerrors.customer_centerwiseid = "Please select prefered customer from list or type its number here";
    } 

    if(formData.fromdate !== '') {
    }else{
      validationerrors.fromdate = "Please select prefered from date from datepicker";
    }

    if(formData.todate !== '') {
    }else{
      validationerrors.todate = "Please select prefered to date from datepicker";
    }

    seterrors(validationerrors);

    if(Object.keys(validationerrors).length !== 0) {
      return;
    }

    MySwal.fire({
      title: 'Proceeding will delete previous transaction data within above selected date range. Confirm?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, continue!'
    }).then((result) => {
        if (result.isConfirmed) {
            if (result.value) {
            // window.location.href = $(this).attr('href');
              addbulkentries();
            }
            else{
              return;
            }
        }
    })  

  }

  async function printContent (event) {
    event.preventDefault();

    var validationerrors = {};

    if(defaultroutename !== '') {
    }else{
      validationerrors.routecode = "Please select prefered line no from list.";
    } 

    if(formData.centerno !== '') {
    }else{
      validationerrors.centerno = "Please select prefered center from list or type its number here";
    } 

    if(formData.customer_centerwiseid !== '') {
    }else{
      validationerrors.customer_centerwiseid = "Please select prefered customer from list or type its number here";
    } 

    if(formData.fromdate !== '') {
    }else{
      validationerrors.fromdate = "Please select prefered from date from datepicker";
    }

    if(formData.todate !== '') {
    }else{
      validationerrors.todate = "Please select prefered to date from datepicker";
    }

    seterrors(validationerrors);

    if(Object.keys(validationerrors).length !== 0) {
      return;
    }

    var response  = "";
        
    var data = {
      CompanyId : UserMasterDataGlobal?.[0]?.CompanyId,
      customer_centerwiseid : formData.customer_centerwiseid,
      centerid : formData.centerno,
      routename : defaultroutename,
      month : getMonthNumerical(formData.fromdate),
    };

    const centername = getCenterNameById(data.centerid);
    // const [customer_centerwiseid, customer_name] = formData.customer_name.split('-');

    response = await axios.post(apireq05042024+'CustomerWiseSalesEntryCard/getCustomerMonthlyBillDate', data);
    if (response.status == 200) {
      console.log(response.data);
    }

    const printWindow = window.open('', '_blank');

    printWindow.document.write(`
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">

      <h1 style="color: red; font-size: 40px; font-weight: bold; margin-top: 5px; text-align: center;">Bill</h1>
      
      <div class="row" style="float: left;">
          <span style="font-size:medium ; font-weight: 500;">Customer Name : ${formData.customer_name} </span>
      </div>
      <div class="row" style="text-align: right;">
          <span style="font-size:medium ; font-weight: 500; ">Center Name : ${centername}</span>
          <span style="font-size:medium ; font-weight: 500; ">Line No : ${defaultroutename}</span>
      </div>
          
      <div class="table-container col-md-12 mt-3">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th style="color: red;">Month</th>
            <td style="text-align: right">${getDateMonth(data.month)} : 2024</td>
            <th style="color: red;">Rate / Liter Rs.</th>
            <td style="text-align: right">Rs: ${response.data.rate}</td>
          </tr>
          <tr>            
            <th style="color: red;">Quantity</th>
            <td style="text-align: right">${response.data.qty}</td>
            <th style="color: red;">Delevery Charges Rs.</th>
            <td style="text-align: right">0.00</td>
          </tr>
          <tr>
            <th style="color: red;">Amount</th>
            <td style="text-align: right">${response.data.amt}</td>
            <th style="color: red;">Bill Amount</th>
            <td style="text-align: right">${response.data.amt}</td>
          </tr>
          <tr>            
            <th style="color: red;">Add ARREARS / ADVANCE Rs.</th>
            <td style="text-align: right">0.00</td>
            <th style="color: red;">Balance</th>
            <td style="text-align: right">0.00</td>
          </tr>
          <tr>           
            <th style="color: red;">New Rate / Ltr Rs.</th>
            <td style="text-align: right">75.50 (Next Month Rate)</td>
            <th></th>
            <td></td>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>

    `);
    printWindow.document.close();
    printWindow.print();
  };

  const getDateMonth = (month) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months[month - 1]; // Subtract 1 to match array indexing
  };
  
  const getMonthNumerical = (dateString) => {
    const date = new Date(dateString);
    return date.getMonth() + 1; // Adding 1 because getMonth returns zero-based index
  };

  const [AlternateSubscriptionVisible, setAlternateSubscriptionVisible] = useState(true);

  // Define a state variable to keep track of whether customer_centerwiseid input has been blurred
  const [customerCenterwiseIdBlurred, setCustomerCenterwiseIdBlurred] = useState(false);

  // Blur event handler for customer_centerwiseid
  const handleCustomerCenterwiseIdBlur = () => {
    setCustomerCenterwiseIdBlurred(true); // Set the flag to true when blurred
    getrecordsbycustomer_centerwiseid_edit(formData.customer_centerwiseid);
  };

  //below code is used to place customer_centerwiseid of selected customer from datalist in its inputbox.
  // Select event handler for customer_name
  const handleDatalistSelect = (e) => {
    const selectedOption = e.target.value;
    const selectedCustomer = customer_name_data.find((option) => option.customer_name === selectedOption);
    if (selectedCustomer) {
      setFormData((prevData) => ({
        ...prevData,
        customer_centerwiseid: selectedCustomer.customer_centerwiseid,
      }));
      // Check if customer_centerwiseid has been blurred recently before calling the function
      if (!customerCenterwiseIdBlurred) {
        getrecordsbycustomer_centerwiseid_edit(selectedCustomer.customer_centerwiseid, "setcustomer_centerwiseid_edit");
      }
    }
    // Reset the flag after handling the select event
    setCustomerCenterwiseIdBlurred(false);
  };

    return(     
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', fontSize: 20, cursor: 'default' }} className="text-center"> Customer Card Alteration</h1>
        <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
            <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
                </li>
            </ul>
            <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
            </div>
        </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form onSubmit={handleSubmit} method='get'> 

            <div className="row mb-3">
              <div className="col-md-1">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="text" className="form-control" name="centerno" id="id-centerno" value={formData.centerno} onChange={handleInputChange}
                onBlur={centernoonblur} required 
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                }/>

                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
              </div> 

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="centerid" className="form-label">Select Center</label>

                <select
                  className="form-select"
                  name="centerid" // <-- should match `formData.uom1` property
                  // value={formData.centerid}
                  onChange={handleInputChange}
                  
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>
              </div>

              {/* <div className="col-md-2"> */}
                {/* <label htmlFor="id-routecode" className="form-label">Line No </label> */}
                <input type="hidden" className="form-control" name="routecode" value={defaultroutename} onChange={handleInputChange} 
                onBlur={routecodeonblur} required />
                
              {/* </div> */}

              <div className="col-md-1">
                <label htmlFor="id-routename" className="form-label">Select Line</label>
                <select
                  className="form-select"
                  id="id-routename" 
                  name="routename"
                  // value={formData.routename}
                  onChange={handleInputChange}
                >

                {defaultroutename && (
                  <option value={defaultroutename}>
                    {defaultroutename}
                  </option>
                )}

                {RouteMasterData.map((r) => (
                  defaultroutename !== r.routename ?
                  <option key={r.routecode} value={`${r.routecode}-${r.routename}`} >
                    {r.routename}
                  </option>
                    : null
                ))}
                </select>
                {errors.routecode && <span style={{color: 'red', fontSize: '10px'}}>{errors.routecode}</span>}
              </div>
              
              <div className='col-md-2' style={{ width: '10%'}}>
                <label style={{marginBottom: 4}}>Customer No</label>
                <input type="number" name="customer_centerwiseid" className='form-control' placeholder='Type here' 
                onBlur={() => handleCustomerCenterwiseIdBlur()} value={formData.customer_centerwiseid} onChange={handleInputChange}></input>              
                {errors.customer_centerwiseid && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_centerwiseid}</span>}
              </div>
              
              <div className='col-md-2'>
                <label style={{marginBottom: 4}}>Customer Name</label>

                  <input
                    type='text'
                    name='customer_name'
                    list='customer_name_list'
                    className='form-control'
                    value={formData.customer_name}
                    onChange={handleInputChange}
                    onSelect={handleDatalistSelect}
                    autoComplete='off' //to not show cache data in inputbox
                  />
                  <datalist id="customer_name_list" >
                    {Array.isArray(customer_name_data) && customer_name_data.map((option) => ( 
                      <option key={option.customer_centerwiseid} value={option.customer_name} />
                    ))}
                  </datalist>
                  
              </div>

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="fromdate" className="form-label">From Date</label>
                <input type="date" className="form-control" name="fromdate" value={formData.fromdate} onChange={handleInputChange} required/>
                {errors.fromdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.fromdate}</span>}
              </div>

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="todate" className="form-label">To Date</label>
                <input type="date" className="form-control" name="todate" value={formData.todate} onChange={handleInputChange} required/>
                {errors.todate && <span style={{color: 'red', fontSize: '10px'}}>{errors.todate}</span>}
              </div>

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
              </div>

              <div className="col-md-2">
                <button type="submit" className="btn btn-primary w-80" style={{marginTop: '32px'}} onClick={(event) => BulkCreation(event)}>Bulk Creation</button>
                {/* <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} >View</button> */}
              </div>
                  
              <div className="col-md-2">
                <button type="submit" className="btn btn-danger w-80" style={{ marginTop: '32px', marginLeft: -95}} onClick={deletealltransactionrecords}>Delete All</button>
              </div>              
              
              <div className='col-md-2'>
                <button className="btn btn-info w-90" style={{ marginTop: '32px', marginLeft: -195, backgroundColor: 'green'}} onClick={printContent}>Print</button>
              </div>
            </div> 
          </form>
      </div>

      <div className="row mb-3">
        <div className="col-md-8">
            <div className="card" style={{borderColor:"rgb(66, 41, 203)", borderWidth:"2px", boxShadow: '14px 12px 9px 1px rgb(62, 62, 62)'}}>
              <h5 className="card-title" style={{textAlign: 'center',background:"rgb(66, 41, 203)",color:"white", padding: 5}}>Monthly Details</h5>
                <div className="card-body">                                
                    <div className="row mt-4">
                      <div className="col-md-12 table-responsive-xl">
                        <div className="table-container" style={{ marginTop: -35, height: '400px', overflow: 'auto', borderWidth:'0px' }}>
                            <table className="table table-striped" style={{cellpadding: '15px'}}>
                            <thead>
                                <tr>
                                  <th style={{backgroundColor:'#009879', color:"white", width: '7%', textAlign: 'right'}}> Date </th>
                                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}> Buff Qty </th>
                                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}> Buff Rate </th>
                                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}> Buff Amt </th>
                                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}> Cow Qty </th>
                                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}> Cow Rate </th>
                                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}> Cow Amt </th>
                                  <th style={{backgroundColor:'#009879', color:"white", width: '8%', textAlign: 'center'}}> Action </th>
                                </tr>
                              </thead>
                              <tbody> 
                                {CustomerData.map((c, index) => {
                                  const parts = c.tran_date.split('-');
                                   // Rearrange the parts to form 'DD-MM-YYYY' format
                                  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                                  return (
                                  <tr>
                                  <td style={{textAlign: 'right'}}>{formattedDate}</td>
                                  <td style={{textAlign: 'right'}}>{c.buffqty}</td>
                                  <td style={{textAlign: 'right'}}>{c.buffrate}</td>
                                  <td style={{textAlign: 'right'}}>{c.buffamt}</td>
                                  <td style={{textAlign: 'right'}}>{c.cowqty}</td>
                                  <td style={{textAlign: 'right'}}>{c.cowrate}</td>
                                  <td style={{textAlign: 'right'}}>{c.cowamt}</td>

                                  <td style={{textAlign: 'center'}}>
                                  <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-sm">Edit</button>
                                    &nbsp;<button onClick={(event) => confirmDelete(event, c.transaction_id)} className="btn btn-danger btn-sm ml-1">
                                    Delete
                                    </button>
                                  </td>

                                  </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-3" style={{marginLeft:'30px', marginRight:'5px'}}>
          <div className="card" style={{borderColor:"rgb(66, 41, 203)", borderWidth:"2px", boxShadow: "14px 12px 9px 1px rgb(62, 62, 62)"}}>
              <h5 className="card-title" style={{textAlign: 'center', background:"rgb(66, 41, 203)",color:"white", padding: 5}}>Alter Entry</h5>
              {/* <div className="card-body" style={{ display: 'flex', flexDirection: 'column', height: '428px', marginTop: -10}}> */}
                  <div className='card-body'>                    

                    <div className='col-md-12'>
                      <label style={{marginBottom: 4}}>Date</label>
                      <input className='form-control' type='date' name="bookdate"
                      value={formData.bookdate} onChange={handleInputChange}></input>              
                    </div>
                    
                    {AlternateSubscriptionVisible ? (
                    <>

                      <div className='col-md-12'>
                        <label style={{marginBottom: 4}}>Buff Qty</label>
                        <input className='form-control' placeholder='0.00' name="buffqty_edit"
                        value={formData.buffqty_edit} onChange={handleInputChange}></input>
                        <input type='hidden' className='form-control' placeholder='0.00' name="buffqty_edit2"
                        value={formData.buffqty_edit2} onChange={handleInputChange}></input>
                      </div>
                      <div className='col-md-12'>
                        <label style={{marginBottom: 4}}>Buff Rate</label>
                        <input className='form-control' placeholder='0.00' name="buffrate_edit"
                        value={formData.buffrate_edit} onChange={handleInputChange}></input>
                      </div>

                      <div className='col-md-12'>
                        <label style={{marginBottom: 4}}>Cow Qty</label>
                        <input className='form-control' placeholder='0.00' name="cowqty_edit"
                        value={formData.cowqty_edit} onChange={handleInputChange}></input>
                        <input type="hidden" className='form-control' placeholder='0.00' name="cowqty_edit2"
                        value={formData.cowqty_edit2} onChange={handleInputChange}></input>
                      </div>
                      <div className='col-md-12'>
                        <label style={{marginBottom: 4}}>Cow rate</label>
                        <input className='form-control' placeholder='0.00' name="cowrate_edit"
                        value={formData.cowrate_edit} onChange={handleInputChange}></input>
                      </div>
                       
                    </>
                    ) : (
                      <>
                        <div className='col-md-12'>
                          <label style={{marginBottom: 4}}>Odd-Even day Buff Qty</label>
                          <div className='row'>
                            <div className='col-md-6'>
                              <input className='form-control' placeholder='0.00' name="buffqty_edit"
                              value={formData.buffqty_edit} onChange={handleInputChange}></input>
                            </div>
                            <div className='col-md-6'>
                              <input className='form-control' placeholder='0.00' name="buffqty_edit2"
                              value={formData.buffqty_edit2} onChange={handleInputChange}></input>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-12'>
                          <label style={{marginBottom: 4}}>Buff rate</label>
                          <input className='form-control' placeholder='0.00' name="buffrate_edit"
                          value={formData.buffrate_edit} onChange={handleInputChange}></input>
                        </div>
                        
                        <div className='col-md-12'>
                          <label style={{marginBottom: 4}}>Odd-Even day Cow Qty</label>
                          <div className='row'>
                            <div className='col-md-6'>
                              <input className='form-control' placeholder='0.00' name="cowqty_edit"
                              value={formData.cowqty_edit} onChange={handleInputChange}></input>
                            </div>
                            <div className='col-md-6'>
                              <input className='form-control' placeholder='0.00' name="cowqty_edit2"
                              value={formData.cowqty_edit2} onChange={handleInputChange}></input>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-12'>
                          <label style={{marginBottom: 4}}>Cow rate</label>
                          
                          <input className='form-control' placeholder='0.00' name="cowrate_edit"
                          value={formData.cowrate_edit} onChange={handleInputChange}></input>

                        </div>                        

                      </>
                    )}

                    <div className='col-md-12' style={{marginTop:  '25px'}}>
                      <button className='btn btn-primary w-100' name="ShortEdit" onClick={ShortEdit}
                      style={{border: '2px solid white'}}>Update</button>
                    </div>        
                    <input type="hidden" className='form-control' name="transactionid_edit" value={formData.transactionid_edit} onChange={handleInputChange}></input> 
                  </div>  
          
          </div>
        </div>       

      </div>
    </div>
    );

}

export default CustomerWiseSalesEntryCard;